// config.js
const config = {
  reactServer: process.env.REACT_APP_SERVER,
  // reactServer: process.env.REACT_APP_SERVER || "http://localhost:5001/",
  // reactServer: process.env.REACT_Server || 'https://OTAP-WellcomAdmin.eu.pythonanywhere.com/',
  // reactServer: process.env.REACT_Server || 'https://LIVE-WellcomAdmin.eu.pythonanywhere.com/',
  // reactServer: process.env.REACT_Server || 'https://thijsrood.pythonanywhere.com/',
  // googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY || 'your_google_api_key',
};

export default config;

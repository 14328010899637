import React, { useState } from 'react';

import './contract.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

const ContractPage: React.FC = () => {
  const [contractFile, setContractFile] = useState<File | null>(null);
  const [isContractSigned, setIsContractSigned] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleContractFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setContractFile(files[0]);
    }
  };

  const handleUploadContract = () => {
    if (contractFile) {
      // Perform contract upload logic here...

      // Simulate successful upload for demonstration
      setIsContractSigned(true);
      setError('');
    } else {
      setError('Please upload a signed contract.');
    }
  };

  const handleDownloadContract = () => {
    // Perform contract download logic here...

    // For demonstration purposes, simulate creating a dummy contract content
    const dummyContractContent = 'This is your contract content.\nPlease sign and upload it.';
    const blob = new Blob([dummyContractContent], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Contract.txt';
    link.click();
  };

  return (
    <div className="contract-page-container">
      <h1 className="title">Contract Tekenen</h1>
      <p className="instructions">
        Download hier het contract, teken deze en upload deze vervolgens voor validatie.
      </p>
      <button className="download-contract-button" onClick={handleDownloadContract}>
        Download Contract
      </button>
      {isContractSigned ? (
        <p className="success-message">Contract succesvol getekend en geupload!</p>
      ) : (
        <>
          <input
            type="file"
            name="contractFile"
            accept=".txt"
            onChange={handleContractFileChange}
          />
          <button className="upload-contract-button" onClick={handleUploadContract}>
            Upload Getekend Contract
          </button>
          {error && <p className="error-message">{error}</p>}
        </>
      )}
    </div>
  );
};

export default ContractPage;

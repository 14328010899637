import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import './eindegesprek.css';
import Succes from "../notifications/Succes";

// Import necessary functions and configurations
import config from '../config';
import { getJwtToken } from '../auth';

const reactServer = config.reactServer;

interface EindeGesprekProps {
  conversationId: string;
  transcript: any[];
  transcript_dutch: any[];
  transcript_foreign: any[];
}

// FeedbackSection Component
const FeedbackSection = ({
  statements,
  currentQuestion,
  ratings,
  ratingsSubmitted,
  feedbackDropdownVisible,
  feedbackText,
  feedbackSent,
  handleRatingChange,
  handleSubmitRatings,
  handleFeedbackButtonClick,
  handleFeedbackTextChange,
  handleFeedbackSubmit,
  setCurrentQuestion,
}) => {
  const ratingOptions = [
    { value: 1, label: 'Helemaal niet mee eens' },
    { value: 2, label: 'Niet mee eens' },
    { value: 3, label: 'Neutraal' },
    { value: 4, label: 'Mee eens' },
    { value: 5, label: 'Helemaal mee eens' },
  ];

  return (
    <div className="feedback-section eindegesprek-page">
      <h2>Feedback</h2>

      {!ratingsSubmitted ? (
        <div className="rating-container">
          <strong>{statements[currentQuestion][0]}</strong>
          <p>{statements[currentQuestion][1]}</p>
          <div className="rating-options">
            {ratingOptions.map((option) => (
              <button
                key={option.value}
                className={`rating-button ${
                  ratings[currentQuestion] === option.value ? 'selected' : ''
                }`}
                onClick={() => handleRatingChange(option.value)}
              >
                {option.label}
              </button>
            ))}
          </div>
          <div className="navigation-buttons">
            <button
              className="nav-button"
              onClick={() => setCurrentQuestion(currentQuestion - 1)}
              disabled={currentQuestion === 0}
            >
              Vorige
            </button>
            {currentQuestion < statements.length - 1 ? (
              <button
                className="nav-button"
                onClick={() => setCurrentQuestion(currentQuestion + 1)}
                disabled={ratings[currentQuestion] === 0}
              >
                Volgende
              </button>
            ) : (
              <button
                className="nav-button"
                onClick={handleSubmitRatings}
                disabled={ratings[currentQuestion] === 0}
              >
                Versturen
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="thank-you-message">
          <p>Bedankt voor uw feedback!</p>
        </div>
      )}

      <button className="feedback-button" onClick={handleFeedbackButtonClick}>
        Feedback Schrijven
      </button>
      {feedbackDropdownVisible && (
        <div className="feedback-dropdown">
          <textarea
            className="feedback-textarea"
            value={feedbackText}
            onChange={handleFeedbackTextChange}
            placeholder="Schrijf uw feedback hier..."
          />
          <button className="feedback-submit-button" onClick={handleFeedbackSubmit}>
            Versturen
          </button>
          {feedbackSent && (
            <p className="feedback-confirmation">Uw feedback is verzonden. Dank u zeer.</p>
          )}
        </div>
      )}
    </div>
  );
};

const EindeGesprekPage: React.FC<EindeGesprekProps> = ({
  conversationId,
  transcript,
  transcript_dutch,
  transcript_foreign,
}) => {
  // State for user profile
  const [profile, setProfile] = useState<any>(null);

  // Summary type: SOEP, GGZ Intakegesprek, Farmaceutisch Consult, Specialist, Proctologisch Intakeconsult, ...
  const [summaryType, setSummaryType] = useState('SOEP');
  // Export type: PDF or Text
  const [exportType, setExportType] = useState('PDF');
  // Feedback states
  const [feedbackDropdownVisible, setFeedbackDropdownVisible] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [feedbackSent, setFeedbackSent] = useState(false);

  const [loadingSamenvatting, setLoadingSamenvatting] = useState(false);
  const [samenvattingText, setSamenvattingText] = useState('');
  const [succesMessage, setSuccesMessage] = useState("");

  const handleCloseSucces = () => {
    setSuccesMessage("");
  };

  // ----------------------------------------------------------------------
  // SOEP parts state
  const [soepParts, setSoepParts] = useState<{ S: string; O: string; E: string; P: string }>({
    S: '',
    O: '',
    E: '',
    P: '',
  });

  // GGZ Intakegesprek parts state
  const [intakeSections, setIntakeSections] = useState({
    aanmeldreden: '',
    psychosociaal: '',
    voorgeschiedenis: '',
    hulpvraag: '',
    risico: '',
    achtergrond: '',
    beleid: '',
  });

  // Farmaceutisch Consult parts state
  const [farmSections, setFarmSections] = useState({
    diagnose: '',
    behandelopties: '',
    bijwerkingen: '',
    waarschuwingen: '',
    followup: '',
  });

  const [proctoSections, setProctoSections] = useState({
    anamnese: '',
    defecatie: '',
    mictie: '',
    algemeneTractus: '',
    probleemlijst: '',
    behandelplan: '',
    followup: '',
  });

  // ----------------------------------------------------------------------
  // Feedback statements (example placeholders)
  const statements = [
    [
      'Patiëntervaring:',
      'TolkChat stelt de patiënt in staat om duidelijk en in de eigen taal te communiceren.',
    ],
    [
      'Kwaliteit van zorg:',
      'TolkChat zorgt voor betrouwbare overdracht van medische informatie, waardoor het risico op miscommunicatie wordt verlaagd.',
    ],
    [
      'Kostenbesparing en efficiëntie:',
      'TolkChat bespaart aanzienlijk tijd bij taalbarrières en vermindert de administratieve lasten.',
    ],
    [
      'Zorgverleningservaring:',
      'TolkChat is gebruiksvriendelijk en intuïtief, waardoor er meer aandacht is voor patiënten met taalbarrières.',
    ],
    [
      'Vergelijking met bestaande oplossingen:',
      'TolkChat is effectiever en betrouwbaarder dan bestaande vertaaloplossingen.',
    ],
    ['Net Promotor Score (NPS):', 'Ik zou TolkChat aanbevelen aan andere zorgverleners.'],
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [ratings, setRatings] = useState<number[]>(Array(statements.length).fill(0));
  const [ratingsSubmitted, setRatingsSubmitted] = useState(false);

  // ----------------------------------------------------------------------
  // Parse the SOEP summary text when it changes and type is SOEP
  useEffect(() => {
    if (summaryType === 'SOEP' && samenvattingText) {
      const lines = samenvattingText.split('\n');
      const parts = { S: '', O: '', E: '', P: '' };
      let currentSection: 'S' | 'O' | 'E' | 'P' | '' = '';

      lines.forEach((line) => {
        const trimmedLine = line.trim();
        if (trimmedLine.startsWith('S/')) {
          currentSection = 'S';
          parts.S = trimmedLine.substring(2).trim();
        } else if (trimmedLine.startsWith('O/')) {
          currentSection = 'O';
          parts.O = trimmedLine.substring(2).trim();
        } else if (trimmedLine.startsWith('E/')) {
          currentSection = 'E';
          parts.E = trimmedLine.substring(2).trim();
        } else if (trimmedLine.startsWith('P/')) {
          currentSection = 'P';
          parts.P = trimmedLine.substring(2).trim();
        } else {
          // Append additional lines to the current section if any
          if (currentSection && parts[currentSection] !== undefined && trimmedLine !== '') {
            parts[currentSection] += '\n' + trimmedLine;
          }
        }
      });

      setSoepParts(parts);
    }
  }, [summaryType, samenvattingText]);

  // ----------------------------------------------------------------------
  // Fetch user profile to get name and email
  useEffect(() => {
    const token = getJwtToken();

    if (token) {
      fetch(reactServer + 'account/profile', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setProfile(data);
        })
        .catch((error) => {
          console.error('Error fetching profile:', error);
        });
    }
  }, []);

  // ----------------------------------------------------------------------
  // Toggle new conversation modal
  const [showNewConversationModal, setShowNewConversationModal] = useState(false);

  // Called whenever someone presses 'Nieuw gesprek'
  const handleNieuwGesprek = async () => {
    setShowNewConversationModal(true);
  };

  // ----------------------------------------------------------------------
  // Export Conversation
  const handleExportGesprek = async () => {
    const token = getJwtToken();

    if (token) {
      try {
        const response = await fetch(reactServer + 'conversation/export_transcript', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: exportType,
            id: conversationId,
            conversation_original: transcript,
            conversation_dutch: transcript_dutch,
            conversation_foreign: transcript_foreign,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to export transcript');
        }

        // Get the filename from response headers
        const filename = response.headers.get('filename');

        // Convert the response to a blob
        const blob = await response.blob();

        // Save the Blob as a file
        saveAs(blob, `WellComm_Transcript_${exportType}_${conversationId}_${filename}`);
      } catch (error) {
        console.error('Error exporting transcript:', error);
      }
    }
  };

  // ----------------------------------------------------------------------
  // Summarize conversation
  const handleSamenvatten = async () => {
    setLoadingSamenvatting(true);
    const token = getJwtToken();

    if (token) {
      try {
        const response = await fetch(reactServer + 'conversation/generate_summary', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: summaryType,
            id: conversationId,
            conversation_dutch: transcript_dutch,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to generate summary');
        }

        const data = await response.json();

        // Assuming the response contains the summary text in data.summary_text
        setSamenvattingText(data.summary_text);
        console.log('Summary generated successfully:', data.summary_text);

        setLoadingSamenvatting(false);
      } catch (error) {
        console.error('Error generating summary:', error);
        setLoadingSamenvatting(false);
      }
    }
  };

  // ----------------------------------------------------------------------
  // Feedback rating handlers
  const handleRatingChange = (newRating: number) => {
    const updatedRatings = [...ratings];
    updatedRatings[currentQuestion] = newRating;
    setRatings(updatedRatings);

    // Automatically move to the next question if not the last one
    if (currentQuestion < statements.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handleSubmitRatings = async () => {
    try {
      const response = await fetch(`${reactServer}feedback/update_counts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ratings: ratings }),
      });

      if (!response.ok) {
        throw new Error('Failed to update rating counts');
      }

      console.log('Rating counts updated successfully');
      setRatingsSubmitted(true);
    } catch (error) {
      console.error('Error updating rating counts:', error);
    }
  };

  const handleFeedbackButtonClick = () => {
    setFeedbackDropdownVisible(!feedbackDropdownVisible);
    setFeedbackSent(false); // Reset feedback sent status when toggling
  };

  const handleFeedbackTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedbackText(event.target.value);
  };

  const handleFeedbackSubmit = async () => {
    const token = getJwtToken();
    if (!profile) {
      console.error('User profile not available.');
      return;
    }

    try {
      const response = await fetch(`${reactServer}mail/send`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: profile.naam,
          email: profile.email,
          title: 'Feedback van gebruiker via TolkChat',
          message: feedbackText,
          message_type: 'feedback',
          details: '',
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send feedback');
      }

      console.log('Feedback sent successfully');
      setFeedbackSent(true);
      setFeedbackText('');
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };

  // ----------------------------------------------------------------------
  // Export Summary
  const handleExportSamenvatting = async () => {
    const token = getJwtToken();

    if (token) {
      try {
        const textToExport =
          summaryType === 'SOEP'
            ? `S/ ${soepParts.S}\nO/ ${soepParts.O}\nE/ ${soepParts.E}\nP/ ${soepParts.P}`
            : samenvattingText;

        const response = await fetch(reactServer + 'conversation/export_summary_text', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: exportType,
            id: conversationId,
            summary_text: textToExport,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to export summary');
        }

        // Get the filename from response headers
        const filename = response.headers.get('filename');

        // Convert the response to a blob
        const blob = await response.blob();

        // Save the Blob as a file
        saveAs(blob, `WellComm_Summary_${exportType}_${conversationId}_${filename}`);
      } catch (error) {
        console.error('Error exporting summary:', error);
      }
    }
  };

  // ----------------------------------------------------------------------
  // Copy entire summary to clipboard
  const handleCopySamenvatting = () => {
    const textToCopy =
      summaryType === 'SOEP'
        ? `S/ ${soepParts.S}\nO/ ${soepParts.O}\nE/ ${soepParts.E}\nP/ ${soepParts.P}`
        : samenvattingText;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setSuccesMessage("Samenvatting is gekopieerd naar het klembord.");
      })
      .catch((err) => {
        console.error('Error copying text: ', err);
      });
  };

  // ----------------------------------------------------------------------
  // Edit states for entire summary (non-SOEP)
  const [isEditSamenvatting, setIsEditSamenvatting] = useState(false);
  const handleSaveSamenvatting = () => {
    setIsEditSamenvatting(false);
  };

  // ----------------------------------------------------------------------
  // States to track edit modes for each SOEP section
  const [isEditS, setIsEditS] = useState(false);
  const [isEditO, setIsEditO] = useState(false);
  const [isEditE, setIsEditE] = useState(false);
  const [isEditP, setIsEditP] = useState(false);

  // Copy text of a specific SOEP section
  const handleCopySection = (sectionKey: keyof typeof soepParts) => {
    navigator.clipboard
      .writeText(soepParts[sectionKey])
      .then(() => {
        setSuccesMessage(`${sectionKey} tekst is gekopieerd naar het klembord.`);
      })
      .catch((err) => {
        console.error('Error copying text: ', err);
      });
  };

  // Save handlers for each SOEP section
  const handleSaveS = () => setIsEditS(false);
  const handleSaveO = () => setIsEditO(false);
  const handleSaveE = () => setIsEditE(false);
  const handleSaveP = () => setIsEditP(false);

  // ----------------------------------------------------------------------
  // GGZ - Edit states
  const [editAanmeldreden, setEditAanmeldreden] = useState(false);
  const [editPsychosociaal, setEditPsychosociaal] = useState(false);
  const [editVoorgeschiedenis, setEditVoorgeschiedenis] = useState(false);
  const [editHulpvraag, setEditHulpvraag] = useState(false);
  const [editRisico, setEditRisico] = useState(false);
  const [editAchtergrond, setEditAchtergrond] = useState(false);
  const [editBeleid, setEditBeleid] = useState(false);

  const handleCopyGGZSection = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setSuccesMessage('Tekst is gekopieerd naar het klembord.');
      })
      .catch((err) => console.error('Error copying text:', err));
  };

  // Save handlers for GGZ
  const handleSaveAanmeldreden = () => setEditAanmeldreden(false);
  const handleSavePsychosociaal = () => setEditPsychosociaal(false);
  const handleSaveVoorgeschiedenis = () => setEditVoorgeschiedenis(false);
  const handleSaveHulpvraag = () => setEditHulpvraag(false);
  const handleSaveRisico = () => setEditRisico(false);
  const handleSaveAchtergrond = () => setEditAchtergrond(false);
  const handleSaveBeleid = () => setEditBeleid(false);

  // ----------------------------------------------------------------------
  // Extraction functions for summary text sections

  // GGZ
  function extractGGZSections(text: string) {
    function getSectionContent(label: string) {
      const regex = new RegExp(
        `${label}([\\s\\S]*?)(?=Aanmeldreden en klachten:|Psychosociale omstandigheden:|Medische en psychische voorgeschiedenis:|Hulpvraag en motivatie:|Risico-inschatting:|Persoonlijke achtergrond:|Beleid/Behandelplan:|$)`,
        'i'
      );
      const match = text.match(regex);
      if (match && match[1].trim() !== '') return match[1].trim();
      return '-';
    }

    return {
      aanmeldreden: getSectionContent('Aanmeldreden en klachten:'),
      psychosociaal: getSectionContent('Psychosociale omstandigheden:'),
      voorgeschiedenis: getSectionContent('Medische en psychische voorgeschiedenis:'),
      hulpvraag: getSectionContent('Hulpvraag en motivatie:'),
      risico: getSectionContent('Risico-inschatting:'),
      achtergrond: getSectionContent('Persoonlijke achtergrond:'),
      beleid: getSectionContent('Beleid/Behandelplan:'),
    };
  }

  // Farmaceutisch Consult
  function extractFarmSections(text: string) {
    function getSectionContent(label: string) {
      // The lookahead will match any next heading or end of string:
      const regex = new RegExp(
        `${label}\\s*([\\s\\S]*?)(?=Diagnose:|Behandelopties:|Bijwerkingen:|Waarschuwingen:|Follow-up:|$)`,
        'i'
      );
      const match = text.match(regex);
      if (match && match[1].trim() !== '') {
        return match[1].trim();
      }
      return '';
    }

    return {
      diagnose: getSectionContent('Diagnose:'),
      behandelopties: getSectionContent('Behandelopties:'),
      bijwerkingen: getSectionContent('Bijwerkingen:'),
      waarschuwingen: getSectionContent('Waarschuwingen:'),
      followup: getSectionContent('Follow-up:'),
    };
  }

  // >>> NEW: Proctologisch Intakeconsult <<<
  function extractProctoSections(text: string) {
    function getSectionContent(label: string) {
      // The lookahead includes all recognized headings or end of text.
      // For Algemene tractus (voorgeschiedenis), we match that exact string carefully:
      const regex = new RegExp(
        `${label}\\s*([\\s\\S]*?)(?=Anamnese:|Defecatie:|Mictie:|Algemene tractus\\s*\\(voorgeschiedenis\\):|Probleemlijst:|Behandelplan:|Follow-up:|$)`,
        'i'
      );
      const match = text.match(regex);
      if (match && match[1].trim() !== '') {
        return match[1].trim();
      }
      return '';
    }

    return {
      anamnese: getSectionContent('Anamnese:'),
      defecatie: getSectionContent('Defecatie:'),
      mictie: getSectionContent('Mictie:'),
      algemeneTractus: getSectionContent('Algemene tractus\\s*\\(voorgeschiedenis\\):'),
      probleemlijst: getSectionContent('Probleemlijst:'),
      behandelplan: getSectionContent('Behandelplan:'),
      followup: getSectionContent('Follow-up:'),
    };
  }

  // ----------------------------------------------------------------------
  // Once samenvattingText is set, parse into the correct sections if needed
  useEffect(() => {
    if (summaryType === 'GGZ Intakegesprek' && samenvattingText) {
      const sections = extractGGZSections(samenvattingText);
      setIntakeSections(sections);
    }
    if (summaryType === 'Farmaceutisch Consult' && samenvattingText) {
      const sections = extractFarmSections(samenvattingText);
      setFarmSections(sections);
    }
    if (summaryType === 'Proctologisch Intakeconsult' && samenvattingText) {
      const sections = extractProctoSections(samenvattingText);
      setProctoSections(sections);
    }
  }, [summaryType, samenvattingText]);

  // ----------------------------------------------------------------------
  // Edit states & handlers for Farmaceutisch Consult
  const [editDiagnose, setEditDiagnose] = useState(false);
  const [editBehandelopties, setEditBehandelopties] = useState(false);
  const [editBijwerkingen, setEditBijwerkingen] = useState(false);
  const [editWaarschuwingen, setEditWaarschuwingen] = useState(false);
  const [editFollowup, setEditFollowup] = useState(false);

  const handleCopyFarmSection = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setSuccesMessage('Tekst is gekopieerd naar het klembord.');
      })
      .catch((err) => console.error('Error copying text:', err));
  };

  const handleSaveDiagnose = () => setEditDiagnose(false);
  const handleSaveBehandelopties = () => setEditBehandelopties(false);
  const handleSaveBijwerkingen = () => setEditBijwerkingen(false);
  const handleSaveWaarschuwingen = () => setEditWaarschuwingen(false);
  const handleSaveFollowup = () => setEditFollowup(false);

  // ----------------------------------------------------------------------
  // Edit states & handlers for Proctologisch Intakeconsult
  const [editAnamnese, setEditAnamnese] = useState(false);
  const [editDefecatie, setEditDefecatie] = useState(false);
  const [editMictie, setEditMictie] = useState(false);
  const [editAlgTractus, setEditAlgTractus] = useState(false);
  const [editProbleemlijst, setEditProbleemlijst] = useState(false);
  const [editBehandelplan, setEditBehandelplan] = useState(false);
  const [editFollowupProcto, setEditFollowupProcto] = useState(false);

  const handleCopyProctoSection = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setSuccesMessage('Tekst is gekopieerd naar het klembord.');
      })
      .catch((err) => console.error('Error copying text:', err));
  };

  const handleSaveAnamnese = () => setEditAnamnese(false);
  const handleSaveDefecatie = () => setEditDefecatie(false);
  const handleSaveMictie = () => setEditMictie(false);
  const handleSaveAlgTractus = () => setEditAlgTractus(false);
  const handleSaveProbleemlijst = () => setEditProbleemlijst(false);
  const handleSaveBehandelplan = () => setEditBehandelplan(false);
  const handleSaveFollowupProcto = () => setEditFollowupProcto(false);

  // ----------------------------------------------------------------------
  // Reload page after user confirms
  const handleReloadPage = () => {
    window.location.reload();
  };

  // ----------------------------------------------------------------------
  return (
    <div className="eindegesprek-container">
      <div className="eindegesprek-left">
        {/* Reload Page Section */}
        <div className="reload-container">
          <button className="action-button" onClick={handleNieuwGesprek}>
            Start Nieuw Gesprek
          </button>
        </div>

        <div className="eindegesprek-page">
          <h2>Exporteer Gesprek</h2>

          {/* Export Conversation Section */}
          <div className="export-container">
            <div className="dropdown-container">
              <label className="dropdown-label">Export Type:</label>
              <select
                className="dropdown"
                value={exportType}
                onChange={(e) => setExportType(e.target.value)}
              >
                <option value="PDF">PDF</option>
                <option value="Text">Text</option>
              </select>
              <button className="action-button export" onClick={handleExportGesprek}>
                Exporteer Gesprek
              </button>
            </div>
          </div>

          {/* Summary Generation Section */}
          <div className="export-container">
            <div className="dropdown-container">
              <label className="dropdown-label">Samenvatting Type:</label>
              <select
                className="dropdown"
                value={summaryType}
                onChange={(e) => setSummaryType(e.target.value)}
              >
                <option value="SOEP">SOEP</option>
                <option value="GGZ Intakegesprek">GGZ Intakegesprek</option>
                <option value="Specialist">Specialist</option>
                <option value="Farmaceutisch Consult">Farmaceutisch Consult</option>
                <option value="Proctologisch Intakeconsult">Proctologisch Intakeconsult</option>
              </select>
              <button className="action-button" onClick={handleSamenvatten}>
                Samenvatten
              </button>
              {loadingSamenvatting && (
                <div className="loader">Bezig met het maken van een samenvatting...</div>
              )}
            </div>
          </div>
        </div>

        {/* Feedback Section for Desktop */}
        <div className="desktop-only">
          <FeedbackSection
            statements={statements}
            currentQuestion={currentQuestion}
            ratings={ratings}
            ratingsSubmitted={ratingsSubmitted}
            feedbackDropdownVisible={feedbackDropdownVisible}
            feedbackText={feedbackText}
            feedbackSent={feedbackSent}
            handleRatingChange={handleRatingChange}
            handleSubmitRatings={handleSubmitRatings}
            handleFeedbackButtonClick={handleFeedbackButtonClick}
            handleFeedbackTextChange={handleFeedbackTextChange}
            handleFeedbackSubmit={handleFeedbackSubmit}
            setCurrentQuestion={setCurrentQuestion}
          />
        </div>

        <div className="void" />
      </div>

      {/* Right Side - Summary Display */}
      {samenvattingText && (
        <div className="eindegesprek-right">
          <div className="header-container">
            <h1>Samenvatting</h1>
          </div>

          <div className="export-container summary">
            <div className="dropdown-container">
              <label className="dropdown-label">Export Type:</label>
              <select
                className="dropdown"
                value={exportType}
                onChange={(e) => setExportType(e.target.value)}
              >
                <option value="PDF">PDF</option>
                <option value="Text">Text</option>
              </select>
              <button className="action-button" onClick={handleExportSamenvatting}>
                Exporteer Samenvatting
              </button>
              <button className="copy-button" onClick={handleCopySamenvatting}>
                Kopieer Naar Klembord
              </button>
            </div>
          </div>

          {/* GGZ Intakegesprek Display */}
          {summaryType === 'GGZ Intakegesprek' && (
            <div className="ggz-sections">
              {/* Aanmeldreden en klachten */}
              <div className={`soep-section-wrapper ${editAanmeldreden ? 'editing' : ''}`}>
                <h3 className="soep-title">Aanmeldreden en klachten</h3>
                {editAanmeldreden ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={intakeSections.aanmeldreden}
                      onChange={(e) =>
                        setIntakeSections({ ...intakeSections, aanmeldreden: e.target.value })
                      }
                    />
                    <button className="action-button edit" onClick={handleSaveAanmeldreden}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{intakeSections.aanmeldreden}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setEditAanmeldreden(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() => handleCopyGGZSection(intakeSections.aanmeldreden)}
                    />
                  </div>
                )}
              </div>

              {/* Psychosociale omstandigheden */}
              <div className={`soep-section-wrapper ${editPsychosociaal ? 'editing' : ''}`}>
                <h3 className="soep-title">Psychosociale omstandigheden</h3>
                {editPsychosociaal ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={intakeSections.psychosociaal}
                      onChange={(e) =>
                        setIntakeSections({ ...intakeSections, psychosociaal: e.target.value })
                      }
                    />
                    <button className="action-button edit" onClick={handleSavePsychosociaal}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{intakeSections.psychosociaal}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setEditPsychosociaal(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() => handleCopyGGZSection(intakeSections.psychosociaal)}
                    />
                  </div>
                )}
              </div>

              {/* Medische en psychische voorgeschiedenis */}
              <div className={`soep-section-wrapper ${editVoorgeschiedenis ? 'editing' : ''}`}>
                <h3 className="soep-title">Medische en psychische voorgeschiedenis</h3>
                {editVoorgeschiedenis ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={intakeSections.voorgeschiedenis}
                      onChange={(e) =>
                        setIntakeSections({ ...intakeSections, voorgeschiedenis: e.target.value })
                      }
                    />
                    <button className="action-button edit" onClick={handleSaveVoorgeschiedenis}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{intakeSections.voorgeschiedenis}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setEditVoorgeschiedenis(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() => handleCopyGGZSection(intakeSections.voorgeschiedenis)}
                    />
                  </div>
                )}
              </div>

              {/* Hulpvraag en motivatie */}
              <div className={`soep-section-wrapper ${editHulpvraag ? 'editing' : ''}`}>
                <h3 className="soep-title">Hulpvraag en motivatie</h3>
                {editHulpvraag ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={intakeSections.hulpvraag}
                      onChange={(e) =>
                        setIntakeSections({ ...intakeSections, hulpvraag: e.target.value })
                      }
                    />
                    <button className="action-button edit" onClick={handleSaveHulpvraag}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{intakeSections.hulpvraag}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setEditHulpvraag(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() => handleCopyGGZSection(intakeSections.hulpvraag)}
                    />
                  </div>
                )}
              </div>

              {/* Risico-inschatting */}
              <div className={`soep-section-wrapper ${editRisico ? 'editing' : ''}`}>
                <h3 className="soep-title">Risico-inschatting</h3>
                {editRisico ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={intakeSections.risico}
                      onChange={(e) =>
                        setIntakeSections({ ...intakeSections, risico: e.target.value })
                      }
                    />
                    <button className="action-button edit" onClick={handleSaveRisico}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{intakeSections.risico}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setEditRisico(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() => handleCopyGGZSection(intakeSections.risico)}
                    />
                  </div>
                )}
              </div>

              {/* Persoonlijke achtergrond */}
              <div className={`soep-section-wrapper ${editAchtergrond ? 'editing' : ''}`}>
                <h3 className="soep-title">Persoonlijke achtergrond</h3>
                {editAchtergrond ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={intakeSections.achtergrond}
                      onChange={(e) =>
                        setIntakeSections({ ...intakeSections, achtergrond: e.target.value })
                      }
                    />
                    <button className="action-button edit" onClick={handleSaveAchtergrond}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{intakeSections.achtergrond}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setEditAchtergrond(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() => handleCopyGGZSection(intakeSections.achtergrond)}
                    />
                  </div>
                )}
              </div>

              {/* Beleid/Behandelplan */}
              <div className={`soep-section-wrapper ${editBeleid ? 'editing' : ''}`}>
                <h3 className="soep-title">Beleid/Behandelplan</h3>
                {editBeleid ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={intakeSections.beleid}
                      onChange={(e) =>
                        setIntakeSections({ ...intakeSections, beleid: e.target.value })
                      }
                    />
                    <button className="action-button edit" onClick={handleSaveBeleid}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{intakeSections.beleid}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setEditBeleid(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() => handleCopyGGZSection(intakeSections.beleid)}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {/* SOEP Display */}
          {summaryType === 'SOEP' && (
            <div className="soep-sections">
              <div className={`soep-section-wrapper ${isEditS ? 'editing' : ''}`}>
                <h3 className="soep-title">S</h3>
                {isEditS ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={soepParts.S}
                      onChange={(e) => setSoepParts({ ...soepParts, S: e.target.value })}
                    />
                    <button className="action-button edit" onClick={handleSaveS}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{soepParts.S}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setIsEditS(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() => handleCopySection('S')}
                    />
                  </div>
                )}
              </div>

              <div className={`soep-section-wrapper ${isEditO ? 'editing' : ''}`}>
                <h3 className="soep-title">O</h3>
                {isEditO ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={soepParts.O}
                      onChange={(e) => setSoepParts({ ...soepParts, O: e.target.value })}
                    />
                    <button className="action-button edit" onClick={handleSaveO}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{soepParts.O}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setIsEditO(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() => handleCopySection('O')}
                    />
                  </div>
                )}
              </div>

              <div className={`soep-section-wrapper ${isEditE ? 'editing' : ''}`}>
                <h3 className="soep-title">E</h3>
                {isEditE ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={soepParts.E}
                      onChange={(e) => setSoepParts({ ...soepParts, E: e.target.value })}
                    />
                    <button className="action-button edit" onClick={handleSaveE}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{soepParts.E}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setIsEditE(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() => handleCopySection('E')}
                    />
                  </div>
                )}
              </div>

              <div className={`soep-section-wrapper ${isEditP ? 'editing' : ''}`}>
                <h3 className="soep-title">P</h3>
                {isEditP ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={soepParts.P}
                      onChange={(e) => setSoepParts({ ...soepParts, P: e.target.value })}
                    />
                    <button className="action-button edit" onClick={handleSaveP}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{soepParts.P}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setIsEditP(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() => handleCopySection('P')}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Farmaceutisch Consult Display */}
          {summaryType === 'Farmaceutisch Consult' && (
            <div className="farm-sections">
              {/* Diagnose */}
              <div className={`soep-section-wrapper ${editDiagnose ? 'editing' : ''}`}>
                <h3 className="soep-title">Diagnose</h3>
                {editDiagnose ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={farmSections.diagnose}
                      onChange={(e) =>
                        setFarmSections({ ...farmSections, diagnose: e.target.value })
                      }
                    />
                    <button className="action-button edit" onClick={handleSaveDiagnose}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{farmSections.diagnose}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setEditDiagnose(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() => handleCopyFarmSection(farmSections.diagnose)}
                    />
                  </div>
                )}
              </div>

              {/* Behandelopties */}
              <div className={`soep-section-wrapper ${editBehandelopties ? 'editing' : ''}`}>
                <h3 className="soep-title">Behandelopties</h3>
                {editBehandelopties ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={farmSections.behandelopties}
                      onChange={(e) =>
                        setFarmSections({ ...farmSections, behandelopties: e.target.value })
                      }
                    />
                    <button className="action-button edit" onClick={handleSaveBehandelopties}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{farmSections.behandelopties}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setEditBehandelopties(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() => handleCopyFarmSection(farmSections.behandelopties)}
                    />
                  </div>
                )}
              </div>

              {/* Bijwerkingen */}
              <div className={`soep-section-wrapper ${editBijwerkingen ? 'editing' : ''}`}>
                <h3 className="soep-title">Bijwerkingen</h3>
                {editBijwerkingen ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={farmSections.bijwerkingen}
                      onChange={(e) =>
                        setFarmSections({ ...farmSections, bijwerkingen: e.target.value })
                      }
                    />
                    <button className="action-button edit" onClick={handleSaveBijwerkingen}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{farmSections.bijwerkingen}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setEditBijwerkingen(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() => handleCopyFarmSection(farmSections.bijwerkingen)}
                    />
                  </div>
                )}
              </div>

              {/* Waarschuwingen */}
              <div className={`soep-section-wrapper ${editWaarschuwingen ? 'editing' : ''}`}>
                <h3 className="soep-title">Waarschuwingen</h3>
                {editWaarschuwingen ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={farmSections.waarschuwingen}
                      onChange={(e) =>
                        setFarmSections({ ...farmSections, waarschuwingen: e.target.value })
                      }
                    />
                    <button className="action-button edit" onClick={handleSaveWaarschuwingen}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{farmSections.waarschuwingen}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setEditWaarschuwingen(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() => handleCopyFarmSection(farmSections.waarschuwingen)}
                    />
                  </div>
                )}
              </div>

              {/* Follow-up */}
              <div className={`soep-section-wrapper ${editFollowup ? 'editing' : ''}`}>
                <h3 className="soep-title">Follow-up</h3>
                {editFollowup ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={farmSections.followup}
                      onChange={(e) =>
                        setFarmSections({ ...farmSections, followup: e.target.value })
                      }
                    />
                    <button className="action-button edit" onClick={handleSaveFollowup}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{farmSections.followup}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setEditFollowup(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() => handleCopyFarmSection(farmSections.followup)}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {/* >>> Proctologisch Intakeconsult Display <<< */}
          {summaryType === 'Proctologisch Intakeconsult' && (
            <div className="procto-sections">
              {/* Anamnese */}
              <div className={`soep-section-wrapper ${editAnamnese ? 'editing' : ''}`}>
                <h3 className="soep-title">Anamnese</h3>
                {editAnamnese ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={proctoSections.anamnese}
                      onChange={(e) =>
                        setProctoSections({ ...proctoSections, anamnese: e.target.value })
                      }
                    />
                    <button className="action-button edit" onClick={handleSaveAnamnese}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{proctoSections.anamnese}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setEditAnamnese(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() => handleCopyProctoSection(proctoSections.anamnese)}
                    />
                  </div>
                )}
              </div>

              {/* Defecatie */}
              <div className={`soep-section-wrapper ${editDefecatie ? 'editing' : ''}`}>
                <h3 className="soep-title">Defecatie</h3>
                {editDefecatie ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={proctoSections.defecatie}
                      onChange={(e) =>
                        setProctoSections({ ...proctoSections, defecatie: e.target.value })
                      }
                    />
                    <button className="action-button edit" onClick={handleSaveDefecatie}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{proctoSections.defecatie}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setEditDefecatie(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() => handleCopyProctoSection(proctoSections.defecatie)}
                    />
                  </div>
                )}
              </div>

              {/* Mictie */}
              <div className={`soep-section-wrapper ${editMictie ? 'editing' : ''}`}>
                <h3 className="soep-title">Mictie</h3>
                {editMictie ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={proctoSections.mictie}
                      onChange={(e) =>
                        setProctoSections({ ...proctoSections, mictie: e.target.value })
                      }
                    />
                    <button className="action-button edit" onClick={handleSaveMictie}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{proctoSections.mictie}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setEditMictie(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() => handleCopyProctoSection(proctoSections.mictie)}
                    />
                  </div>
                )}
              </div>

              {/* Algemene tractus (voorgeschiedenis) */}
              <div className={`soep-section-wrapper ${editAlgTractus ? 'editing' : ''}`}>
                <h3 className="soep-title">Algemene tractus (voorgeschiedenis)</h3>
                {editAlgTractus ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={proctoSections.algemeneTractus}
                      onChange={(e) =>
                        setProctoSections({ ...proctoSections, algemeneTractus: e.target.value })
                      }
                    />
                    <button className="action-button edit" onClick={handleSaveAlgTractus}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{proctoSections.algemeneTractus}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setEditAlgTractus(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() =>
                        handleCopyProctoSection(proctoSections.algemeneTractus)
                      }
                    />
                  </div>
                )}
              </div>

              {/* Probleemlijst */}
              <div className={`soep-section-wrapper ${editProbleemlijst ? 'editing' : ''}`}>
                <h3 className="soep-title">Probleemlijst</h3>
                {editProbleemlijst ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={proctoSections.probleemlijst}
                      onChange={(e) =>
                        setProctoSections({ ...proctoSections, probleemlijst: e.target.value })
                      }
                    />
                    <button className="action-button edit" onClick={handleSaveProbleemlijst}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{proctoSections.probleemlijst}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setEditProbleemlijst(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() =>
                        handleCopyProctoSection(proctoSections.probleemlijst)
                      }
                    />
                  </div>
                )}
              </div>

              {/* Behandelplan */}
              <div className={`soep-section-wrapper ${editBehandelplan ? 'editing' : ''}`}>
                <h3 className="soep-title">Behandelplan</h3>
                {editBehandelplan ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={proctoSections.behandelplan}
                      onChange={(e) =>
                        setProctoSections({ ...proctoSections, behandelplan: e.target.value })
                      }
                    />
                    <button className="action-button edit" onClick={handleSaveBehandelplan}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{proctoSections.behandelplan}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setEditBehandelplan(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() =>
                        handleCopyProctoSection(proctoSections.behandelplan)
                      }
                    />
                  </div>
                )}
              </div>

              {/* Follow-up */}
              <div className={`soep-section-wrapper ${editFollowupProcto ? 'editing' : ''}`}>
                <h3 className="soep-title">Follow-up</h3>
                {editFollowupProcto ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea"
                      value={proctoSections.followup}
                      onChange={(e) =>
                        setProctoSections({ ...proctoSections, followup: e.target.value })
                      }
                    />
                    <button className="action-button edit" onClick={handleSaveFollowupProcto}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{proctoSections.followup}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setEditFollowupProcto(true)}
                    />
                    <img
                      src="./copy-icon.png"
                      alt="Copy"
                      className="soep-icon"
                      onClick={() =>
                        handleCopyProctoSection(proctoSections.followup)
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {/* If it's not GGZ, SOEP, Farmaceutisch, or Proctologisch, we assume "Specialist" or other text */}
          {summaryType !== 'GGZ Intakegesprek' &&
            summaryType !== 'SOEP' &&
            summaryType !== 'Farmaceutisch Consult' &&
            summaryType !== 'Proctologisch Intakeconsult' && (
              <div className={`soep-section-wrapper ${isEditSamenvatting ? 'editing' : ''}`}>
                {isEditSamenvatting ? (
                  <div className="soep-edit-container">
                    <textarea
                      className="soep-textarea specialist"
                      value={samenvattingText}
                      onChange={(e) => setSamenvattingText(e.target.value)}
                    />
                    <button className="action-button edit" onClick={handleSaveSamenvatting}>
                      Opslaan
                    </button>
                  </div>
                ) : (
                  <div className="soep-text-container">
                    <p className="soep-text">{samenvattingText}</p>
                    <img
                      src="./edit-icon.png"
                      alt="Edit"
                      className="soep-icon"
                      onClick={() => setIsEditSamenvatting(true)}
                    />
                  </div>
                )}
              </div>
            )}
        </div>
      )}

      {/* Feedback Section for Mobile */}
      <div className="mobile-only">
        <FeedbackSection
          statements={statements}
          currentQuestion={currentQuestion}
          ratings={ratings}
          ratingsSubmitted={ratingsSubmitted}
          feedbackDropdownVisible={feedbackDropdownVisible}
          feedbackText={feedbackText}
          feedbackSent={feedbackSent}
          handleRatingChange={handleRatingChange}
          handleSubmitRatings={handleSubmitRatings}
          handleFeedbackButtonClick={handleFeedbackButtonClick}
          handleFeedbackTextChange={handleFeedbackTextChange}
          handleFeedbackSubmit={handleFeedbackSubmit}
          setCurrentQuestion={setCurrentQuestion}
        />
      </div>

      {/* Confirmation modal for "Nieuw Gesprek" */}
      {showNewConversationModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Start Nieuw Gesprek</h2>
            <p>
              Uw huidige gespreksdata zal verloren gaan. Weet u zeker dat u een nieuw gesprek
              wilt starten?
            </p>
            <div className="modal-buttons">
              <button className="cancel-button" onClick={() => setShowNewConversationModal(false)}>
                Annuleren
              </button>
              <button
                className="send-button"
                onClick={() => {
                  setShowNewConversationModal(false);
                  handleReloadPage();
                }}
              >
                Nieuw Gesprek
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Success notification */}
      {succesMessage && <Succes message={succesMessage} onClose={handleCloseSucces} />}
    </div>
  );
};

export default EindeGesprekPage;
// // languages.js or languages.ts
export const allLanguages = [
    'Afrikaans (South Africa)',
    'Albanian (Albania)',
    'Amharic (Ethiopia)',
    'Arabic (United Arab Emirates)',
    'Arabic (Bahrain)',
    'Arabic (Algeria)',
    'Arabic (Egypt)',
    'Arabic (Israel)',
    'Arabic (Iraq)',
    'Arabic (Jordan)',
    'Arabic (Kuwait)',
    'Arabic (Lebanon)',
    'Arabic (Libya)',
    'Arabic (Morocco)',
    'Arabic (Oman)',
    'Arabic (Palestinian Authority)',
    'Arabic (Qatar)',
    'Arabic (Saudi Arabia)',
    'Arabic (Syria)',
    'Arabic (Tunisia)',
    'Arabic (Yemen)',
    'Armenian (Armenia)',
    'Azerbaijani (Latin, Azerbaijan)',
    'Bengali (India)',
    'Bashkir',
    'Basque',
    'Bhojpuri',
    'Bodo',
    'Bosnian (Bosnia and Herzegovina)',
    'Bulgarian (Bulgaria)',
    'Cantonese (Traditional)',
    'Catalan',
    'Chinese (Literary)',
    'Chinese (Wu, Simplified)',
    'Chinese (Cantonese, Simplified)',
    'Chinese (Mandarin, Simplified)',
    'Chinese (Jilu Mandarin, Simplified)',
    'Chinese (Southwestern Mandarin, Simplified)',
    'Chinese (Cantonese, Traditional)',
    'Chinese (Taiwanese Mandarin, Traditional)',
    'chiShona',
    'Croatian (Croatia)',
    'Czech (Czechia)',
    'Danish (Denmark)',
    'Dari',
    'Dutch (Netherlands)',
    'Dutch (Belgium)',
    'English (Australia)',
    'English (Canada)',
    'English (United Kingdom)',
    'English (Ghana)',
    'English (Hong Kong SAR)',
    'English (Ireland)',
    'English (India)',
    'English (Kenya)',
    'English (Nigeria)',
    'English (New Zealand)',
    'English (Philippines)',
    'English (Singapore)',
    'English (Tanzania)',
    'English (United States)',
    'English (South Africa)',
    'Estonian (Estonia)',
    'Faroese',
    'Fijian',
    'Filipino (Philippines)',
    'Finnish',
    'French (Belgium)',
    'French (Canada)',
    'French (Switzerland)',
    'French (France)',
    'Galician',
    'Georgian (Georgia)',
    'German (Germany)',
    'German (Austria)',
    'German (Switzerland)',
    'Greek (Greece)',
    'Haitian Creole',
    'Hausa',
    'Hebrew (Israel)',
    'Hindi (India)',
    'Hungarian (Hungary)',
    'Icelandic (Iceland)',
    'Igbo',
    'Indonesian (Indonesia)',
    'Inuinnaqtun',
    'Irish (Ireland)',
    'Italian (Switzerland)',
    'Italian (Italy)',
    'Japanese (Japan)',
    'Kannada (India)',
    'Kashmiri',
    'Kazakh (Kazakhstan)',
    'Khmer (Cambodia)',
    'Kinyarwanda',
    'Korean (Korea)',
    'Kurdish (Central)',
    'Kurdish (Northern)',
    'Kyrgyz (Cyrillic)',
    'Lao (Laos)',
    'Latvian (Latvia)',
    'Lithuanian (Lithuania)',
    'Lingala',
    'Luganda',
    'Macedonian (North Macedonia)',
    'Malay (Malaysia)',
    'Maltese (Malta)',
    'Maori',
    'Mongolian (Cyrillic)',
    'Mongolian (Traditional)',
    'Burmese (Myanmar)',
    'Nepali (Nepal)',
    'Norwegian Bokmål (Norway)',
    'Nyanja',
    'Odia',
    'Pashto (Afghanistan)',
    'Persian',
    'Polish (Poland)',
    'Portuguese (Brazil)',
    'Portuguese (Portugal)',
    'Punjabi (India)',
    'Romanian (Romania)',
    'Rundi',
    'Russian (Russia)',
    'Samoan (Latin)',
    'Serbian (Cyrillic, Serbia)',
    'Serbian (Latin, Serbia)',
    'Sesotho',
    'Sindhi',
    'Sinhala (Sri Lanka)',
    'Slovak (Slovakia)',
    'Slovenian (Slovenia)',
    'Somali (Somalia)',
    'Spanish (Argentina)',
    'Spanish (Bolivia)',
    'Spanish (Chile)',
    'Spanish (Colombia)',
    'Spanish (Costa Rica)',
    'Spanish (Cuba)',
    'Spanish (Dominican Republic)',
    'Spanish (Ecuador)',
    'Spanish (Spain)',
    'Spanish (Equatorial Guinea)',
    'Spanish (Guatemala)',
    'Spanish (Honduras)',
    'Spanish (Mexico)',
    'Spanish (Nicaragua)',
    'Spanish (Panama)',
    'Spanish (Peru)',
    'Spanish (Puerto Rico)',
    'Spanish (Paraguay)',
    'Spanish (El Salvador)',
    'Spanish (United States)',
    'Spanish (Uruguay)',
    'Spanish (Venezuela)',
    'Swahili (Kenya)',
    'Swahili (Tanzania)',
    'Swedish (Sweden)',
    'Tahitian',
    'Tamil (India)',
    'Tatar (Latin)',
    'Telugu (India)',
    'Thai (Thailand)',
    'Tibetan',
    'Tigrinya',
    'Turkish (Türkiye)',
    'Turkmen (Latin)',
    'Ukrainian (Ukraine)',
    'Urdu (India)',
    'Uyghur (Arabic)',
    'Uzbek (Latin, Uzbekistan)',
    'Vietnamese (Vietnam)',
    'Welsh (United Kingdom)',
    'Yoruba',
    'Zulu (South Africa)'
];

const bankspelers = [
    'Assamese',
    'Divehi',
    'Dogri',
    'Klingon',
    'Klingon (plqaD)',
    'Konkani',
    'Inuktitut',
    'Inuktitut (Latin)',
    'Gujarati (India)',
    'Hmong Daw (Latin)',
    'Lower Sorbian',
    'Maithili',
    'Malagasy',
    'Marathi (India)',
    'Queretaro Otomi',
    'Malayalam (India)',
    'Sesotho sa Leboa',
    'Upper Sorbian',
    'Tongan',
    'Yucatec Maya',
    'Xhosa',
    'Setswana',
]


export const TranslateMapping = {
    'Afrikaans (South Africa)': "af",
    'Albanian (Albania)': "sq",
    'Amharic (Ethiopia)': "am",
    'Arabic (United Arab Emirates)': 'ar',
    'Arabic (Bahrain)': 'ar',
    'Arabic (Algeria)': 'ar',
    'Arabic (Egypt)': 'ar',
    'Arabic (Israel)': 'ar',
    'Arabic (Iraq)': 'ar',
    'Arabic (Jordan)': 'ar',
    'Arabic (Kuwait)': 'ar',
    'Arabic (Lebanon)': 'ar',
    'Arabic (Libya)': 'ar',
    'Arabic (Morocco)': 'ar',
    'Arabic (Oman)': 'ar',
    'Arabic (Palestinian Authority)': 'ar',
    'Arabic (Qatar)': 'ar',
    'Arabic (Saudi Arabia)': 'ar',
    'Arabic (Syria)': 'ar',
    'Arabic (Tunisia)': 'ar',
    'Arabic (Yemen)': 'ar',
    'Armenian (Armenia)': "hy",
    "Assamese": "as",
    'Azerbaijani (Latin, Azerbaijan)': "az",
    'Bengali (India)': "bn",
    "Bashkir": "ba",
    "Basque": "eu",
    "Bhojpuri": "bho",
    "Bodo": "brx",
    'Bosnian (Bosnia and Herzegovina)': "bs",
    'Bulgarian (Bulgaria)': "bg",
    "Cantonese (Traditional)": "yue",
    "Catalan": "ca",
    "Chinese (Literary)": "lzh",
    'Chinese (Wu, Simplified)': 'zh-Hans',
    'Chinese (Cantonese, Simplified)': 'zh-Hans',
    'Chinese (Mandarin, Simplified)': 'zh-Hans',
    'Chinese (Jilu Mandarin, Simplified)': 'zh-Hans',
    'Chinese (Southwestern Mandarin, Simplified)': 'zh-Hans',
    'Chinese (Cantonese, Traditional)': 'zh-Hant',
    'Chinese (Taiwanese Mandarin, Traditional)': 'zh-Hant',
    "chiShona": "sn",
    'Croatian (Croatia)': "hr",
    'Czech (Czechia)': "cs",
    'Danish (Denmark)': "da",
    "Dari": "prs",
    "Divehi": "dv",
    "Dogri": "doi",
    'Dutch (Netherlands)': 'nl',
    'Dutch (Belgium)': "nl",
    'English (Australia)': 'en',
    'English (Canada)': 'en',
    'English (United Kingdom)': 'en',
    'English (Ghana)': 'en',
    'English (Hong Kong SAR)': 'en',
    'English (Ireland)': 'en',
    'English (India)': 'en',
    'English (Kenya)': 'en',
    'English (Nigeria)': 'en',
    'English (New Zealand)': 'en',
    'English (Philippines)': 'en',
    'English (Singapore)': 'en',
    'English (Tanzania)': 'en',
    'English (United States)': 'en',
    'English (South Africa)': 'en',
    'Estonian (Estonia)': "et",
    "Faroese": "fo",
    "Fijian": "fj",
    'Filipino (Philippines)': "fil",
    "Finnish": "fi",
    'French (Belgium)': 'fr',
    'French (Canada)': 'fr',
    'French (Switzerland)': 'fr',
    'French (France)': 'fr',
    "French (Canada)": "fr",
    'Galician': 'gl',
    'Georgian (Georgia)': "ka",
    'German (Germany)': 'de',
    'German (Austria)': "de",
    'German (Switzerland)': "de",
    'Greek (Greece)': "el",
    'Gujarati (India)': "gu",
    "Haitian Creole": "ht",
    "Hausa": "ha",
    'Hebrew (Israel)': "he",
    'Hindi (India)': "hi",
    "Hmong Daw (Latin)": "mww",
    'Hungarian (Hungary)': "hu",
    'Icelandic (Iceland)': "is",
    "Igbo": "ig",
    'Indonesian (Indonesia)': "id",
    "Inuinnaqtun": "ikt",
    "Inuktitut": "iu",
    "Inuktitut (Latin)": "iu-Latn",
    'Irish (Ireland)': 'ga',
    'Italian (Switzerland)': 'it',
    'Italian (Italy)': 'it',
    'Japanese (Japan)': "ja",
    'Kannada (India)': "kn",
    "Kashmiri": "ks",
    'Kazakh (Kazakhstan)': "kk",
    'Khmer (Cambodia)': "km",
    "Kinyarwanda": "rw",
    "Klingon": "tlh-Latn",
    "Klingon (plqaD)": "tlh-Piqd",
    "Konkani": "gom",
    'Korean (Korea)': "ko",
    "Kurdish (Central)": "ku",
    "Kurdish (Northern)": "kmr",
    "Kyrgyz (Cyrillic)": "ky",
    'Lao (Laos)': "lo",
    'Latvian (Latvia)': "lv",
    'Lithuanian (Lithuania)': "lt",
    "Lingala": "ln",
    "Lower Sorbian": "dsb",
    "Luganda": "lug",
    'Macedonian (North Macedonia)': "mk",
    "Maithili": "mai",
    "Malagasy": "mg",
    'Malay (Malaysia)': "ms",
    'Malayalam (India)': "ml",
    'Maltese (Malta)': "mt",
    "Maori": "mi",
    'Marathi (India)': "mr",
    "Mongolian (Cyrillic)": "mn-Cyrl",
    "Mongolian (Traditional)": "mn-Mong",
    'Burmese (Myanmar)': "my",
    'Nepali (Nepal)': "ne",
    'Norwegian Bokmål (Norway)': "nb",
    "Nyanja": "nya",
    "Odia": "or",
    'Pashto (Afghanistan)': "ps",
    "Persian": "fa",
    'Polish (Poland)': "pl",
    "Portuguese (Brazil)": "pt",
    "Portuguese (Portugal)": "pt-pt",
    'Punjabi (India)': "pa",
    "Queretaro Otomi": "otq",
    'Romanian (Romania)': "ro",
    "Rundi": "run",
    'Russian (Russia)': "ru",
    "Samoan (Latin)": "sm",
    "Serbian (Cyrillic, Serbia)": "sr-Cyrl",
    "Serbian (Latin, Serbia)": "sr-Latn",
    "Sesotho": "st",
    "Sesotho sa Leboa": "nso",
    "Setswana": "tn",
    "Sindhi": "sd",
    'Sinhala (Sri Lanka)': "si",
    'Slovak (Slovakia)': "sk",
    'Slovenian (Slovenia)': "sl",
    'Somali (Somalia)': "so",
    'Spanish (Argentina)': 'es',
    'Spanish (Bolivia)': 'es',
    'Spanish (Chile)': 'es',
    'Spanish (Colombia)': 'es',
    'Spanish (Costa Rica)': 'es',
    'Spanish (Cuba)': 'es',
    'Spanish (Dominican Republic)': 'es',
    'Spanish (Ecuador)': 'es',
    'Spanish (Spain)': 'es',
    'Spanish (Equatorial Guinea)': 'es',
    'Spanish (Guatemala)': 'es',
    'Spanish (Honduras)': 'es',
    'Spanish (Mexico)': 'es',
    'Spanish (Nicaragua)': 'es',
    'Spanish (Panama)': 'es',
    'Spanish (Peru)': 'es',
    'Spanish (Puerto Rico)': 'es',
    'Spanish (Paraguay)': 'es',
    'Spanish (El Salvador)': 'es',
    'Spanish (United States)': 'es',
    'Spanish (Uruguay)': 'es',
    'Spanish (Venezuela)': 'es',
    'Swahili (Kenya)': "sw",
    'Swahili (Tanzania)': "sw",
    'Swedish (Sweden)': "sv",
    "Tahitian": "ty",
    'Tamil (India)': "ta",
    "Tatar (Latin)": "tt",
    'Telugu (India)': "te",
    'Thai (Thailand)': "th",
    "Tibetan": "bo",
    "Tigrinya": "ti",
    "Tongan": "to",
    'Turkish (Türkiye)': "tr",
    "Turkmen (Latin)": "tk",
    'Ukrainian (Ukraine)': "uk",
    "Upper Sorbian": "hsb",
    'Urdu (India)': "ur",
    "Uyghur (Arabic)": "ug",
    'Uzbek (Latin, Uzbekistan)': "uz",
    'Vietnamese (Vietnam)': "vi",
    'Welsh (United Kingdom)': "cy",
    "Xhosa": "xh",
    "Yoruba": "yo",
    "Yucatec Maya": "yua",
    'Zulu (South Africa)': "zu"
};


export const SpeechToTextMapping = {
    'Afrikaans (South Africa)': 'af-ZA',
    'Amharic (Ethiopia)': 'am-ET',
    'Arabic (United Arab Emirates)': 'ar-AE',
    'Arabic (Bahrain)': 'ar-BH',
    'Arabic (Algeria)': 'ar-DZ',
    'Arabic (Egypt)': 'ar-EG',
    'Arabic (Israel)': 'ar-IL',
    'Arabic (Iraq)': 'ar-IQ',
    'Arabic (Jordan)': 'ar-JO',
    'Arabic (Kuwait)': 'ar-KW',
    'Arabic (Lebanon)': 'ar-LB',
    'Arabic (Libya)': 'ar-LY',
    'Arabic (Morocco)': 'ar-MA',
    'Arabic (Oman)': 'ar-OM',
    'Arabic (Palestinian Authority)': 'ar-PS',
    'Arabic (Qatar)': 'ar-QA',
    'Arabic (Saudi Arabia)': 'ar-SA',
    'Arabic (Syria)': 'ar-SY',
    'Arabic (Tunisia)': 'ar-TN',
    'Arabic (Yemen)': 'ar-YE',
    'Azerbaijani (Latin, Azerbaijan)': 'az-AZ',
    'Bulgarian (Bulgaria)': 'bg-BG',
    'Bengali (India)': 'bn-IN',
    'Bosnian (Bosnia and Herzegovina)': 'bs-BA',
    'Catalan': 'ca-ES',
    'Czech (Czechia)': 'cs-CZ',
    'Welsh (United Kingdom)': 'cy-GB',
    'Danish (Denmark)': 'da-DK',
    'German (Austria)': 'de-AT',
    'German (Switzerland)': 'de-CH',
    'German (Germany)': 'de-DE',
    'Greek (Greece)': 'el-GR',
    'English (Australia)': 'en-AU',
    'English (Canada)': 'en-CA',
    'English (United Kingdom)': 'en-GB',
    'English (Ghana)': 'en-GH',
    'English (Hong Kong SAR)': 'en-HK',
    'English (Ireland)': 'en-IE',
    'English (India)': 'en-IN',
    'English (Kenya)': 'en-KE',
    'English (Nigeria)': 'en-NG',
    'English (New Zealand)': 'en-NZ',
    'English (Philippines)': 'en-PH',
    'English (Singapore)': 'en-SG',
    'English (Tanzania)': 'en-TZ',
    'English (United States)': 'en-US',
    'English (South Africa)': 'en-ZA',
    'Spanish (Argentina)': 'es-AR',
    'Spanish (Bolivia)': 'es-BO',
    'Spanish (Chile)': 'es-CL',
    'Spanish (Colombia)': 'es-CO',
    'Spanish (Costa Rica)': 'es-CR',
    'Spanish (Cuba)': 'es-CU',
    'Spanish (Dominican Republic)': 'es-DO',
    'Spanish (Ecuador)': 'es-EC',
    'Spanish (Spain)': 'es-ES',
    'Spanish (Equatorial Guinea)': 'es-GQ',
    'Spanish (Guatemala)': 'es-GT',
    'Spanish (Honduras)': 'es-HN',
    'Spanish (Mexico)': 'es-MX',
    'Spanish (Nicaragua)': 'es-NI',
    'Spanish (Panama)': 'es-PA',
    'Spanish (Peru)': 'es-PE',
    'Spanish (Puerto Rico)': 'es-PR',
    'Spanish (Paraguay)': 'es-PY',
    'Spanish (El Salvador)': 'es-SV',
    'Spanish (United States)': 'es-US',
    'Spanish (Uruguay)': 'es-UY',
    'Spanish (Venezuela)': 'es-VE',
    'Estonian (Estonia)': 'et-EE',
    'Filipino (Philippines)': 'fil-PH',
    'French (Belgium)': 'fr-BE',
    'French (Canada)': 'fr-CA',
    'French (Switzerland)': 'fr-CH',
    'French (France)': 'fr-FR',
    'Irish (Ireland)': 'ga-IE',
    'Galician': 'gl-ES',
    'Gujarati (India)': 'gu-IN',
    'Hebrew (Israel)': 'he-IL',
    'Hindi (India)': 'hi-IN',
    'Croatian (Croatia)': 'hr-HR',
    'Hungarian (Hungary)': 'hu-HU',
    'Armenian (Armenia)': 'hy-AM',
    'Indonesian (Indonesia)': 'id-ID',
    'Icelandic (Iceland)': 'is-IS',
    'Italian (Switzerland)': 'it-CH',
    'Italian (Italy)': 'it-IT',
    'Japanese (Japan)': 'ja-JP',
    'Javanese (Latin, Indonesia)': 'jv-ID',
    'Georgian (Georgia)': 'ka-GE',
    'Kazakh (Kazakhstan)': 'kk-KZ',
    'Khmer (Cambodia)': 'km-KH',
    'Kannada (India)': 'kn-IN',
    'Korean (Korea)': 'ko-KR',
    'Lao (Laos)': 'lo-LA',
    'Lithuanian (Lithuania)': 'lt-LT',
    'Latvian (Latvia)': 'lv-LV',
    'Macedonian (North Macedonia)': 'mk-MK',
    'Malayalam (India)': 'ml-IN',
    'Mongolian (Mongolia)': 'mn-MN',
    'Marathi (India)': 'mr-IN',
    'Malay (Malaysia)': 'ms-MY',
    'Maltese (Malta)': 'mt-MT',
    'Burmese (Myanmar)': 'my-MM',
    'Norwegian Bokmål (Norway)': 'nb-NO',
    'Nepali (Nepal)': 'ne-NP',
    'Dutch (Belgium)': 'nl-BE',
    'Dutch (Netherlands)': 'nl-NL',
    'Punjabi (India)': 'pa-IN',
    'Polish (Poland)': 'pl-PL',
    'Pashto (Afghanistan)': 'ps-AF',
    'Portuguese (Brazil)': 'pt-BR',
    'Portuguese (Portugal)': 'pt-PT',
    'Romanian (Romania)': 'ro-RO',
    'Russian (Russia)': 'ru-RU',
    'Sinhala (Sri Lanka)': 'si-LK',
    'Slovak (Slovakia)': 'sk-SK',
    'Slovenian (Slovenia)': 'sl-SI',
    'Somali (Somalia)': 'so-SO',
    'Albanian (Albania)': 'sq-AL',
    'Serbian (Cyrillic, Serbia)': 'sr-RS',
    'Swedish (Sweden)': 'sv-SE',
    'Swahili (Kenya)': 'sw-KE',
    'Swahili (Tanzania)': 'sw-TZ',
    'Tamil (India)': 'ta-IN',
    'Telugu (India)': 'te-IN',
    'Thai (Thailand)': 'th-TH',
    'Turkish (Türkiye)': 'tr-TR',
    'Ukrainian (Ukraine)': 'uk-UA',
    'Urdu (India)': 'ur-IN',
    'Uzbek (Latin, Uzbekistan)': 'uz-UZ',
    'Vietnamese (Vietnam)': 'vi-VN',
    'Chinese (Wu, Simplified)': 'wuu-CN',
    'Chinese (Cantonese, Simplified)': 'yue-CN',
    'Chinese (Mandarin, Simplified)': 'zh-CN',
    'Chinese (Jilu Mandarin, Simplified)': 'zh-CN-shandong',
    'Chinese (Southwestern Mandarin, Simplified)': 'zh-CN-sichuan',
    'Chinese (Cantonese, Traditional)': 'zh-HK',
    'Chinese (Taiwanese Mandarin, Traditional)': 'zh-TW',
    'Zulu (South Africa)': 'zu-ZA'
};


export const TextToSpeechMapping = {
    "Afrikaans (South Africa)": "af-ZA-AdriNeural",
    "Amharic (Ethiopia)": "am-ET-MekdesNeural",
    "Arabic (United Arab Emirates)": "ar-AE-FatimaNeural",
    "Arabic (Bahrain)": "ar-BH-LailaNeural",
    "Arabic (Algeria)": "ar-DZ-AminaNeural",
    "Arabic (Egypt)": "ar-EG-SalmaNeural",
    "Arabic (Iraq)": "ar-IQ-RanaNeural",
    "Arabic (Jordan)": "ar-JO-SanaNeural",
    "Arabic (Kuwait)": "ar-KW-NouraNeural",
    "Arabic (Lebanon)": "ar-LB-LaylaNeural",
    "Arabic (Libya)": "ar-LY-ImanNeural",
    "Arabic (Morocco)": "ar-MA-MounaNeural",
    "Arabic (Oman)": "ar-OM-AyshaNeural",
    "Arabic (Qatar)": "ar-QA-AmalNeural",
    "Arabic (Saudi Arabia)": "ar-SA-ZariyahNeural",
    "Arabic (Syria)": "ar-SY-AmanyNeural",
    "Arabic (Tunisia)": "ar-TN-ReemNeural",
    "Arabic (Yemen)": "ar-YE-MaryamNeural",
    "Azerbaijani (Latin, Azerbaijan)": "az-AZ-BanuNeural",
    "Bulgarian (Bulgaria)": "bg-BG-KalinaNeural",
    "Bangla (Bangladesh)": "bn-BD-NabanitaNeural",
    "Bengali (India)": "bn-IN-TanishaaNeural",
    "Bosnian (Bosnia and Herzegovina)": "bs-BA-VesnaNeural",
    "Catalan": "ca-ES-JoanaNeural",
    "Czech (Czechia)": "cs-CZ-VlastaNeural",
    "Welsh (United Kingdom)": "cy-GB-NiaNeural",
    "Danish (Denmark)": "da-DK-ChristelNeural",
    "German (Austria)": "de-AT-IngridNeural",
    "German (Switzerland)": "de-CH-LeniNeural",
    "German (Germany)": "de-DE-KatjaNeural",
    "Greek (Greece)": "el-GR-AthinaNeural",
    "English (Australia)": "en-AU-NatashaNeural",
    "English (Canada)": "en-CA-ClaraNeural",
    "English (United Kingdom)": "en-GB-SoniaNeural",
    "English (Hong Kong SAR)": "en-HK-YanNeural",
    "English (Ireland)": "en-IE-EmilyNeural",
    "English (India)": "en-IN-NeerjaNeural",
    "English (Kenya)": "en-KE-AsiliaNeural",
    "English (Nigeria)": "en-NG-EzinneNeural",
    "English (New Zealand)": "en-NZ-MollyNeural",
    "English (Philippines)": "en-PH-RosaNeural",
    "English (Singapore)": "en-SG-LunaNeural",
    "English (Tanzania)": "en-TZ-ImaniNeural",
    "English (United States)": "en-US-AvaNeural",
    "English (South Africa)": "en-ZA-LeahNeural",
    "Spanish (Argentina)": "es-AR-ElenaNeural",
    "Spanish (Bolivia)": "es-BO-SofiaNeural",
    "Spanish (Chile)": "es-CL-CatalinaNeural",
    "Spanish (Colombia)": "es-CO-SalomeNeural",
    "Spanish (Costa Rica)": "es-CR-MariaNeural",
    "Spanish (Cuba)": "es-CU-BelkysNeural",
    "Spanish (Dominican Republic)": "es-DO-RamonaNeural",
    "Spanish (Ecuador)": "es-EC-AndreaNeural",
    "Spanish (Spain)": "es-ES-ElviraNeural",
    "Spanish (Equatorial Guinea)": "es-GQ-TeresaNeural",
    "Spanish (Guatemala)": "es-GT-MartaNeural",
    "Spanish (Honduras)": "es-HN-KarlaNeural",
    "Spanish (Mexico)": "es-MX-DaliaNeural",
    "Spanish (Nicaragua)": "es-NI-YolandaNeural",
    "Spanish (Panama)": "es-PA-MargaritaNeural",
    "Spanish (Peru)": "es-PE-CamilaNeural",
    "Spanish (Puerto Rico)": "es-PR-KarinaNeural",
    "Spanish (Paraguay)": "es-PY-TaniaNeural",
    "Spanish (El Salvador)": "es-SV-LorenaNeural",
    "Spanish (United States)": "es-US-PalomaNeural",
    "Spanish (Uruguay)": "es-UY-ValentinaNeural",
    "Spanish (Venezuela)": "es-VE-PaolaNeural",
    "Estonian (Estonia)": "et-EE-AnuNeural",
    "Basque": "eu-ES-AinhoaNeural",
    "Persian (Iran)": "fa-IR-DilaraNeural",
    "Finnish (Finland)": "fi-FI-SelmaNeural",
    "Filipino (Philippines)": "fil-PH-BlessicaNeural",
    "French (Belgium)": "fr-BE-CharlineNeural",
    "French (Canada)": "fr-CA-SylvieNeural",
    "French (Switzerland)": "fr-CH-ArianeNeural",
    "French (France)": "fr-FR-DeniseNeural",
    "Irish (Ireland)": "ga-IE-OrlaNeural",
    "Galician": "gl-ES-SabelaNeural",
    "Gujarati (India)": "gu-IN-DhwaniNeural",
    "Hebrew (Israel)": "he-IL-HilaNeural",
    "Hindi (India)": "hi-IN-SwaraNeural",
    "Croatian (Croatia)": "hr-HR-GabrijelaNeural",
    "Hungarian (Hungary)": "hu-HU-NoemiNeural",
    "Armenian (Armenia)": "hy-AM-AnahitNeural",
    "Indonesian (Indonesia)": "id-ID-GadisNeural",
    "Icelandic (Iceland)": "is-IS-GudrunNeural",
    "Italian (Italy)": "it-IT-ElsaNeural",
    "Japanese (Japan)": "ja-JP-NanamiNeural",
    "Javanese (Latin, Indonesia)": "jv-ID-SitiNeural",
    "Georgian (Georgia)": "ka-GE-EkaNeural",
    "Kazakh (Kazakhstan)": "kk-KZ-AigulNeural",
    "Khmer (Cambodia)": "km-KH-SreymomNeural",
    "Kannada (India)": "kn-IN-SapnaNeural",
    "Korean (Korea)": "ko-KR-SunHiNeural",
    "Lao (Laos)": "lo-LA-KeomanyNeural",
    "Lithuanian (Lithuania)": "lt-LT-OnaNeural",
    "Latvian (Latvia)": "lv-LV-EveritaNeural",
    "Macedonian (North Macedonia)": "mk-MK-MarijaNeural",
    "Malayalam (India)": "ml-IN-SobhanaNeural",
    "Mongolian (Mongolia)": "mn-MN-YesuiNeural",
    "Marathi (India)": "mr-IN-AarohiNeural",
    "Malay (Malaysia)": "ms-MY-YasminNeural",
    "Maltese (Malta)": "mt-MT-GraceNeural",
    "Burmese (Myanmar)": "my-MM-NilarNeural",
    "Norwegian Bokmål (Norway)": "nb-NO-PernilleNeural",
    "Nepali (Nepal)": "ne-NP-HemkalaNeural",
    "Dutch (Belgium)": "nl-BE-DenaNeural",
    "Dutch (Netherlands)": "nl-NL-FennaNeural",
    "Polish (Poland)": "pl-PL-AgnieszkaNeural",
    "Pashto (Afghanistan)": "ps-AF-LatifaNeural",
    "Portuguese (Brazil)": "pt-BR-FranciscaNeural",
    "Portuguese (Portugal)": "pt-PT-RaquelNeural",
    "Romanian (Romania)": "ro-RO-AlinaNeural",
    "Russian (Russia)": "ru-RU-SvetlanaNeural",
    "Sinhala (Sri Lanka)": "si-LK-ThiliniNeural",
    "Slovak (Slovakia)": "sk-SK-ViktoriaNeural",
    "Slovenian (Slovenia)": "sl-SI-PetraNeural",
    "Somali (Somalia)": "so-SO-UbaxNeural",
    "Albanian (Albania)": "sq-AL-AnilaNeural",
    "Serbian (Latin, Serbia)": "sr-Latn-RS-NicholasNeural",
    "Serbian (Cyrillic, Serbia)": "sr-RS-SophieNeural",
    "Sundanese (Indonesia)": "su-ID-TutiNeural",
    "Swedish (Sweden)": "sv-SE-SofieNeural",
    "Swahili (Kenya)": "sw-KE-ZuriNeural",
    "Swahili (Tanzania)": "sw-TZ-RehemaNeural",
    "Tamil (India)": "ta-IN-PallaviNeural",
    "Tamil (Sri Lanka)": "ta-LK-SaranyaNeural",
    "Tamil (Malaysia)": "ta-MY-KaniNeural",
    "Tamil (Singapore)": "ta-SG-VenbaNeural",
    "Telugu (India)": "te-IN-ShrutiNeural",
    "Thai (Thailand)": "th-TH-PremwadeeNeural",
    "Turkish (Türkiye)": "tr-TR-EmelNeural",
    "Ukrainian (Ukraine)": "uk-UA-PolinaNeural",
    "Urdu (India)": "ur-IN-GulNeural",
    "Urdu (Pakistan)": "ur-PK-UzmaNeural",
    "Uzbek (Latin, Uzbekistan)": "uz-UZ-MadinaNeural",
    "Vietnamese (Vietnam)": "vi-VN-HoaiMyNeural",
    "Chinese (Wu, Simplified)": "wuu-CN-XiaotongNeural",
    "Chinese (Cantonese, Simplified)": "yue-CN-XiaoMinNeural",
    "Chinese (Mandarin, Simplified)": "zh-CN-XiaoxiaoNeural",
    "Chinese (Guangxi Accent Mandarin, Simplified)": "zh-CN-liaoning-XiaobeiNeural",
    "Chinese (Zhongyuan Mandarin Henan, Simplified)": "zh-CN-XiaoxiaoNeural",
    "Chinese (Northeastern Mandarin, Simplified)": "zh-CN-XiaoxiaoNeural",
    "Chinese (Zhongyuan Mandarin Shaanxi, Simplified)": "zh-CN-XiaoxiaoNeural",
    "Chinese (Jilu Mandarin, Simplified)": "zh-CN-XiaoxiaoNeural",
    "Chinese (Southwestern Mandarin, Simplified)": "zh-CN-XiaoxiaoNeural",
    "Chinese (Cantonese, Traditional)": "zh-HK-HiuMaanNeural",
    "Chinese (Taiwanese Mandarin, Traditional)": "zh-TW-HsiaoChenNeural",
    "Zulu (South Africa)": "zu-ZA-ThandoNeural"
};



// (!) 'Javanese (Latin, Indonesia)' is een taal die wel speech to text beschikbaar is maar geen normale vertaling

// These languages do tend to have translation in text form but no speech to text
// microphone should be disabled
export const noSpeechToText = {
    "Xhosa": "xh",
    "Yoruba": "yo",
    "Yucatec Maya": "yua",
    "Uyghur (Arabic)": "ug",
    "Upper Sorbian": "hsb",
    "Turkmen (Latin)": "tk",
    "Tibetan": "bo",
    "Tigrinya": "ti",
    "Tongan": "to",
    "Tatar (Latin)": "tt",
    "Tahitian": "ty",
    "Sesotho": "st",
    "Sesotho sa Leboa": "nso",
    "Setswana": "tn",
    "Sindhi": "sd",
    "Samoan (Latin)": "sm",
    "Rundi": "run",
    "Queretaro Otomi": "otq",
    "Persian": "fa",
    "Nyanja": "nya",
    "Odia": "or",
    "Mongolian (Cyrillic)": "mn-Cyrl",
    "Mongolian (Traditional)": "mn-Mong",
    "Maori": "mi",
    "Maithili": "mai",
    "Malagasy": "mg",
    "Lingala": "ln",
    "Lower Sorbian": "dsb",
    "Luganda": "lug",
    "Kurdish (Central)": "ku",
    "Kurdish (Northern)": "kmr",
    "Kyrgyz (Cyrillic)": "ky",
    "Kinyarwanda": "rw",
    "Klingon": "tlh-Latn",
    "Klingon (plqaD)": "tlh-Piqd",
    "Konkani": "gom",
    "Kashmiri": "ks",
    "Inuinnaqtun": "ikt",
    "Inuktitut": "iu",
    "Inuktitut (Latin)": "iu-Latn",
    "Igbo": "ig",
    "Hmong Daw (Latin)": "mww",
    "Haitian Creole": "ht",
    "Hausa": "ha",
    "Finnish": "fi",
    "Faroese": "fo",
    "Fijian": "fj",
    "Dari": "prs",
    "Divehi": "dv",
    "Dogri": "doi",
    "chiShona": "sn",
    "Cantonese (Traditional)": "yue",
    "Chinese (Literary)": "lzh",
    "Bashkir": "ba",
    "Basque": "eu",
    "Bhojpuri": "bho",
    "Bodo": "brx",
    "Assamese": "as",
};

// // languages.js or languages.ts
export const scribeLanguages = [
    'Dutch (Netherlands)',
    'English (United Kingdom)',
    'English (United States)',
    'Arabic (Egypt)',
    'Arabic (Morocco)',
    'Arabic (Saudi Arabia)',
    'Chinese (Cantonese, Simplified)',
    'Chinese (Mandarin, Simplified)',
    'French (France)',
    'German (Germany)',
    'Hebrew (Israel)',
    'Italian (Italy)',
    'Polish (Poland)',
    'Portuguese (Portugal)',
    'Russian (Russia)',
    'Spanish (Spain)',
    'Swedish (Sweden)',
    'Turkish (Türkiye)',
    'Ukrainian (Ukraine)',
];

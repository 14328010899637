import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import "./patientmanagement.css";
import ErrorMessage from "../notifications/Error";
import { getJwtToken } from "../auth";
import { allLanguages } from "../mappings/microsoft";
import config from "../config";

const reactServer = config.reactServer;

const PatientManagement: React.FC = () => {
  const [profile, setProfile] = useState<any>(null);
  const [newPatientName, setNewPatientName] = useState<string>("");
  const [newPatientSurname, setNewPatientSurname] = useState<string>("");
  const [newPatientLanguage, setNewPatientLanguage] = useState<string>("");
  const [searchPatientName, setSearchPatientName] = useState<string>("");
  const [selectedPatient, setSelectedPatient] = useState<any>(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [rownaam, setRowNaam] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState<boolean>(false);

  //For the Delete Confirmation Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [patientToDelete, setPatientToDelete] = useState<any>(null);

  const navigate = useNavigate();

  // Fetch the entire profile (including patients) from the server
  const fetchProfile = async () => {
    const token = getJwtToken();
    if (!token) return;

    try {
      const response = await fetch(reactServer + "account/profile", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setProfile(data);
      setIsTwoFactorEnabled(Boolean(data.twofactor));
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  };

  // Run once, on component mount
  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line
  }, []);

  // Check if patient ID is already in use
  const handleAddPatient = async () => {
    if (profile?.patienten?.some((p: any) => p.patient_tag === newPatientName)) {
      setErrorMessage("Dit PatiëntID bestaat al. Kies een andere ID.");
      return;
    }

    const newPatient = {
      naam: newPatientName,
      surname: newPatientSurname,
      language: newPatientLanguage,
    };

    try {
      const token = getJwtToken();
      const response = await fetch(reactServer + "account/add-patient", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newPatient),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Re-fetch the entire profile to see the updated list
      await fetchProfile();

      // Clear inputs
      setNewPatientName("");
      setNewPatientSurname("");
      setNewPatientLanguage("");
    } catch (error) {
      console.error("Error adding patient:", error);
      setErrorMessage("Er is iets misgegaan bij het toevoegen van de patiënt.");
    }
  };

  const handleDeletePatient = async (patientId: string, patient_tag: string) => {
    try {
      const token = getJwtToken();
      const response = await fetch(
        reactServer + `account/delete-patient/${patientId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Immediately refresh the profile to show the updated list
      await fetchProfile();

      // Optionally track which row was deleted (for UI messages, etc.)
      setRowNaam(patient_tag);

    } catch (error) {
      console.error("Error deleting patient:", error);
      setErrorMessage("Er is iets misgegaan bij het verwijderen van de patiënt.");
    }
  };

  // Searching for a single patient by ID or surname (partial match)
  const handleSearchPatient = () => {
    if (!profile?.patienten) return;

    const foundPatient = profile.patienten.find((patient: any) =>
      patient.patient_tag?.toLowerCase().includes(searchPatientName.toLowerCase()) ||
      patient.surname?.toLowerCase().includes(searchPatientName.toLowerCase())
    );

    if (!foundPatient) {
      setSelectedPatient(null);
      setErrorMessage(`Geen patiënt gevonden die matcht met: "${searchPatientName}"`);
    } else {
      setSelectedPatient(foundPatient);
      setErrorMessage("");
    }
  };

  const handleRowClick = (index: number, naam: string) => {
    setSelectedRowIndex(index === selectedRowIndex ? null : index);
    setRowNaam(naam);
  };

  // Start a conversation with the selected patient
  const handleStartConversation = () => {
    if (!selectedPatient) {
      setErrorMessage("Selecteer eerst een patiënt om het gesprek te starten.");
      return;
    }

    navigate("/tolkchat", { state: { patient: selectedPatient, autostart: true } });
  };

  const handleCloseError = () => {
    setErrorMessage("");
  };

  // Confirmation Modal Logic 
  const handleOpenDeleteModal = (patient: any) => {
    setPatientToDelete(patient);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setPatientToDelete(null);
  };

  const handleConfirmDeletePatient = () => {
    if (!patientToDelete) return;

    // Call the existing function with the patient we stored
    handleDeletePatient(patientToDelete.id, patientToDelete.patient_tag);

    // Close modal + clear the stored patient
    setShowDeleteModal(false);
    setPatientToDelete(null);
  };

  return (
    <div className="patient-management-page-container">
      {profile && (
        <div className="add-patient-block">
          <div>
            <h3 className="voeg-patient-title">Voeg Patiënt Toe</h3>
            <div className="centerme">
              <div className="patient-inputs">
                <input
                  type="text"
                  className="patient-input"
                  placeholder="PatiëntID"
                  value={newPatientName}
                  onChange={(e) => setNewPatientName(e.target.value)}
                />
                <select
                  className="selector"
                  value={newPatientLanguage}
                  onChange={(e) => setNewPatientLanguage(e.target.value)}
                >
                  <option value="">Selecteer taal</option>
                  {allLanguages.map((lang) => (
                    <option key={lang} value={lang}>
                      {lang}
                    </option>
                  ))}
                </select>
              </div>
              <button className="add-patient-button" onClick={handleAddPatient}>
                Toevoegen
              </button>
            </div>
          </div>

          <hr className="organisation-line" />
          <h3 className="zoek-patient-titel">Patiëntenlijst</h3>
          <div className="patient-search-inputs">
            <input
              type="text"
              className="inputfield"
              placeholder="Zoek op ID of achternaam"
              value={searchPatientName}
              onChange={(e) => setSearchPatientName(e.target.value)}
            />
            <button className="add-patient-button" onClick={handleSearchPatient}>
              Zoek
            </button>
          </div>

          {/* If a patient is found by search, show details */}
          {selectedPatient && (
            <div className="selected-patient-details">
              <p>Geselecteerde Patiënt: {selectedPatient.patient_tag}</p>
              <button
                className="add-patient-button"
                onClick={handleStartConversation}
              >
                Start Gesprek
              </button>
            </div>
          )}

          <div className="patient-table-container">
            <table className="patient-table">
              <thead>
                <tr>
                  <th>PatiëntID</th>
                  <th>Taal</th>
                  <th>Verwijderen</th>
                </tr>
              </thead>
              <tbody>
                {profile.patienten &&
                  profile.patienten.map((patient: any, index: number) => (
                    <tr
                      key={patient.id}
                      onClick={() => handleRowClick(index, patient.patient_tag)}
                      className={index === selectedRowIndex ? "selected" : ""}
                    >
                      <td>{patient.patient_tag}</td>
                      <td>{patient.language}</td>
                      <td className="bincontainer">
                        <img
                          alt="bin"
                          className="bin"
                          src={"/bin_black.png"}
                          onMouseOver={(e) =>
                            (e.currentTarget.src = "/bin_red.png")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.src = "/bin_black.png")
                          }
                          onClick={(e) => {
                            e.stopPropagation();

                            // Open the confirmation modal
                            handleOpenDeleteModal(patient);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {errorMessage && (
        <ErrorMessage message={errorMessage} onClose={handleCloseError} />
      )}

      {/* 
        Confirmation Modal: 
        Only shows if `showDeleteModal` is true 
      */}
      {showDeleteModal && (
        <div className="delete-modal-backdrop">
          <div className="delete-modal">
            <p>
              Weet u zeker dat u patiënt{" "}
              <strong>{patientToDelete?.patient_tag}</strong> wilt verwijderen?
            </p>
            <div className="delete-modal-buttons">
              <button onClick={handleCloseDeleteModal}>Nee</button>
              <button onClick={handleConfirmDeletePatient}>Ja</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientManagement;
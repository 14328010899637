import React, { useState } from "react";
import "./PopUpModal.css";

interface ModalProps {
  title: string;
  message: string;
  onCancel: () => void;
  onConfirm: (data: { userName: string; email: string }) => void;
  confirmText?: string;
  cancelText?: string;
}

const Modal: React.FC<ModalProps> = ({
  title,
  message,
  onCancel,
  onConfirm,
  confirmText = "Bevestig",
  cancelText = "Annuleer",
}) => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleSubmit = () => {
    // Disable the button and set a timer for 2 seconds
    setIsButtonDisabled(true);

    // Call the onConfirm prop
    onConfirm({ userName, email });

    // Re-enable the button after 2 seconds
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  // Check if the email is valid using regular expression
  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  return (
    <div className="modal-overlay">
      <div className="modal">
        {/* Close Button */}
        <button className="close-button" onClick={onCancel}>
          &times;
        </button>

        <h2>{title}</h2>
        <p>{message}</p>
        <form>
          <div className="form-group">
            <label htmlFor="userName">Naam:</label>
            <input
              type="text"
              id="userName"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Naam van de gebruiker (optioneel)"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mailadres gebruiker"
              required
              pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" // HTML5 email pattern
            />
          </div>
        </form>
        <div className="modal-buttons">
          <button
            type="button"
            className="send-button"
            onClick={handleSubmit}
            disabled={isButtonDisabled || !userName || !email || !isEmailValid} // Disable if invalid email
            style={{ backgroundColor: isButtonDisabled || !isEmailValid ? "#d3d3d3" : "#4CAF50" }} // Grey when disabled or invalid email
          >
            {confirmText}
          </button>
          <button className="cancel-button" onClick={onCancel}>
            {cancelText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;

// src/views/ontwikkeling.tsx
import React, { useState } from "react";
import './infopages.css';

const patchNotes = [
    {
        version: "1.0.15",
        date: "04-10-2024",
        title: "Wellcom Portaal Patch Notes 1.0.15 (04-10-2024): ▶️",
        description: `
        Wat zit er allemaal in de nieuwe update:
        1. De optie tot automatisch afspelen werkt nu in de Tolkchat, probeer dit vooral eens!
        2. Wachtwoorden kun je nu door op het oogje te klikken bekijken.
        3. Verschillende veranderingen van de frontend look.
        4. Zorgmedewerkers kunnen een gratis testaccount maken voor 2 weken en 5 uses van de tolkchat.
        `
    },
    {
        version: "1.0.14",
        date: "27-09-2024",
        title: "Wellcom Portaal Patch Notes 1.0.14 (27-09-2024): 🧠",
        description: `
        De Real Realtime Update:
        1. Realtime transcriptie is functioneel en werkt goed.
        2. Realtime vertaling is functioneel en werkt goed.
        3. Coole functie toegevoegd waarmee je berichten kunt bewerken en ze ook kunt vertalen.
        
        Mobiele compatibiliteit:
        - Mobiele Tolkchat ziet er beter uit dan ooit.
        
        Account verwijderen:
        - We vinden het jammer als je ons wilt verlaten en willen je graag houden, maar nu is er in ieder geval een optie om die fout te maken.
        
        Bugfixes:
        - Diverse bugfixes en optimalisaties geïmplementeerd.
        `
    },
    {
        version: "1.0.13",
        date: "07-09-2024",
        title: "Wellcom Portaal Patch Notes 1.0.13 (07-09-2024): ✅",
        description: `
        Functies:
        1. Realtime updates toegevoegd aan de transcriptie en vertaling in de Tolkchat.
        2. De server volledig geherconfigureerd, zodat deze volledig in Europa is geplaatst.
        3. Verplichte geïnformeerde toestemming toegevoegd vóór elke Tolkchat.
        4. Een instellingenmenu toegevoegd in de Tolkchat (nog in ontwikkeling).

        Bugfixes:
        - Diverse bugfixes en optimalisaties geïmplementeerd.
        `
    },
    {
        version: "1.0.12",
        date: "31-08-2024",
        title: "Wellcom Portaal Patch Notes 1.0.12 (31-08-2024): 💼",
        description: `
        Algemene functies:
        1. Wachtwoord reset optie toegevoegd.
        2. Twee-factor authenticatie is nu volledig functioneel.
        
        Organisatie functies:
        1. Het is nu mogelijk om een organisatie aan te maken.
        2. Mogelijkheid om met verschillende organisatie- en piloottypen te werken.
        3. Organisatiecodes toegevoegd zodat gebruikers zich kunnen aansluiten bij hun organisatie.
        4. Organisaties hebben een eigen overzicht met inzichten.
        5. Gebruikers kunnen nu al onze voorwaarden en gelicentieerde documenten downloaden.

        Bugfixes:
        - Diverse bugfixes en optimalisaties geïmplementeerd.
        - Een hardnekkige bug in het organisatieoverzicht moet nog worden opgelost.
        `
    },
    {
        version: "1.0.11",
        date: "20-07-2024",
        title: "Wellcom Portaal Patch Notes 1.0.11 (20-07-2024): ⚙️",
        description: `
        Functies:
        1. Alle gebruikersmodellen herschreven om ze voor te bereiden op toekomstige updates.
        2. Optie toegevoegd om alleen een patiënt-ID in te voeren in plaats van een naam voor privacyredenen.

        Bugfixes:
        - Diverse bugfixes en optimalisaties geïmplementeerd.
        `
    },
    {
        version: "1.0.10",
        date: "17-05-2024",
        title: "Wellcom Portaal Patch Notes 1.0.10 (17-05-2024): 🎙️",
        description: `
        Functies:
        1. De grootte van de microfoonknop vergroot voor betere zichtbaarheid en toegankelijkheid.
        2. De sectie "Over Ons" verwijderd, omdat deze informatie dubbelde op wat al op de website staat.
        3. Een zoekfunctie voor talen toegevoegd met de mogelijkheid om de 10 meest gebruikte talen bovenaan de lijst weer te geven, te beginnen met Arabisch (Syrisch-Libanees).
        4. Bij het toevoegen van de web-app aan het startscherm van een telefoon, wordt de titel nu weergegeven als "Wellcom: TolkChat" in plaats van "React App".
        5. "Start Chat" hernoemd naar "Start TolkChat" op het startscherm.
        6. "Voeg Patiënt Toe" hernoemd naar "Voeg Patiënt Toe" op het startscherm.
        7. Het sitepictogram bijgewerkt om het huidige logo weer te geven.
        8. Een nieuw tabblad "Demo Pilot" toegevoegd waar een video kan worden geüpload. Ook een "Technische" pagina en een FAQ-sectie toegevoegd voor aanvullende informatie.

        Bugfixes:
        - Diverse bugfixes en optimalisaties geïmplementeerd.
        - Een nieuw logo voor TolkChat ontworpen.
        - Informatie toegevoegd aan de FAQ-pagina's.
        `
    },
    {
        version: "1.0.9",
        date: "17-05-2024",
        title: "Wellcom Portaal Patch Notes 1.0.9 (17-05-2024): 🗣",
        description: `
        Functies:
        - Spraak-naar-tekst functionaliteit ingeschakeld met ondersteuning voor 148 verschillende talen en dialecten, waardoor toegankelijkheid en bruikbaarheid worden verbeterd.
        - Compatibiliteit uitgebreid naar alle MacBooks, zodat het naadloos werkt op verschillende apparaten, inclusief oudere modellen zoals die van Victor.
        - Alle verwijzingen naar "WellCom" bijgewerkt naar "Wellcom" voor consistentie in het hele platform.
        - Extra optimalisaties geïmplementeerd voor snellere laadtijden van afbeeldingen en andere inhoud, wat de algehele gebruikerservaring verbetert.
        - De mobiele versie verbeterd voor een meer gepolijste en gebruiksvriendelijke interface.

        Verbeteringen:
        - Voortdurende backend-optimalisaties om de systeemefficiëntie en prestaties te verbeteren.

        Bugfixes:
        - Diverse bugs aangepakt en processen gestroomlijnd om een soepele en ononderbroken service voor alle gebruikers te garanderen.
        `
    },
    {
        version: "1.0.8",
        date: "16-04-2024",
        title: "Wellcom Portaal Patch Notes 1.0.8 (16-04-2024): 📩",
        description: `
        Functies:
        - Twee-staps verificatie e-mails geïmplementeerd voor verbeterde beveiliging, zodat de accounts van gebruikers beschermd blijven.
        - Laadtijden van afbeeldingen geoptimaliseerd door de afbeeldingsgrootte te verkleinen via compressietechnieken, wat resulteert in snellere paginalaadtijden.
        - Een kritieke bug verholpen als gevolg van een database-migratie, waardoor tal van problemen zijn opgelost en de volledige functionaliteit van het platform is hersteld.
        - Serverzijde opschoning geïmplementeerd voor verbeterde systeemprestaties en stabiliteit.
        - De gebruikerservaring verbeterd door de pagina direct op het inlogscherm te starten, zonder onnodige prompts.

        Verbeteringen:
        - Diverse backend-optimalisaties en verbeteringen voor een betere systeemefficiëntie.

        Bugfixes:
        - Kritieke fouten en problemen opgelost om een ononderbroken service voor alle gebruikers te garanderen.
        `
    }
];


const InfoPage: React.FC = () => {
    const [openIndex, setOpenIndex] = useState<number | null>(null);

    const toggleAnswer = (index: number) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="ontwikkeling-page">
            <h1 className="ontwikkeling-title">Ontwikkeling Updates</h1>

            {patchNotes.map((note, index) => (
                <div
                    key={note.version}
                    className={`reason ${openIndex === index ? "open" : ""}`}
                    onClick={() => toggleAnswer(index)}
                >
                    <h2 className="reason-title">{note.title}</h2>
                    {openIndex === index && (
                        <p className="reason-description">
                            {note.description.split("\n").map((line, idx) => (
                                <React.Fragment key={idx}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </p>
                    )}
                </div>
            ))}
        </div>
    );
};

export default InfoPage;

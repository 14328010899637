import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './wachtwoordNieuw.css';

import Error from '../notifications/Error';
import config from '../config';

import eyeOpen from '../images/eye_open.png';
import eyeClosed from '../images/eye_closed.png';

const reactServer = config.reactServer;

const ResetPassword: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = {
      new_password: newPassword,
    };

    try {
      const response = await fetch(`${reactServer}account/reset-password/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.message || 'Geen verbinding met de server.');
        return;
      }

      const data = await response.json();

      if (data.message === 'Password reset successful') {
        setSuccess('Uw wachtwoord is succesvol gereset.');
        setNewPassword('');
        setError('');
        setTimeout(() => navigate('/login'), 2000); // Redirect after 2 seconds
      } else {
        setError(data.message);
        setSuccess('');
      }
    } catch (error) {
      setError('Geen verbinding met de server.');
      setSuccess('');
    }
  };

  const handleCloseError = () => {
    setError('');
  };

  return (
    <div className='reset-password-container'>
      {error && <Error message={error} onClose={handleCloseError} />}
      {success && <div className="success-message">{success}</div>}
      <h1 className="page-title">Stel uw wachtwoord opnieuw in</h1>
      <form className="reset-password-form" onSubmit={handleSubmit}>
        <div className='password-field'>
          <input
            type={showPassword ? 'text' : 'password'}
            name="new_password"
            placeholder="Nieuw wachtwoord"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <button
            type="button"
            className="password-toggle-button"
            onClick={() => setShowPassword(!showPassword)}
            aria-label={showPassword ? 'Verberg wachtwoord' : 'Toon wachtwoord'}
          >
            <img
              src={showPassword ? eyeClosed : eyeOpen}
              alt={showPassword ? 'Verberg wachtwoord' : 'Toon wachtwoord'}
              className="eye-icon"
            />
          </button>
        </div>
        <button className='submit-reset-button' type="submit">Reset wachtwoord</button>
      </form>
    </div>
  );
};

export default ResetPassword;

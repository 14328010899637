import React, { useState, useEffect, useRef } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";

import LogoAnimation from "./logoAnimation";
import Error from "./notifications/Error";
import Neutral from "./notifications/Neutral";
import TolkPage from "./tolkchat";
import ScribeChat from "./scribechat";
import AboutPage from "./pages/about";
import ContactPage from "./pages/contact";
import WaaromPage from "./pages/waarom";
import LoginPage from "./pages/login";
import RegisterPage from "./pages/register";
import RegisterOrganisation from "./pages/registerorg";
import RegisterTenPlus from "./pages/registerTenPlusOrg";
import History from "./pages/history";
import Profiel from "./pages/profiel";
import Abonnementen from "./pages/abonnement";
import Contract from "./pages/contract";
import WellcomOverzicht from "./pages/wellcomoverzicht";
import ProfielOverzichtBedrijf from "./pages/bedrijfprofieloverzicht";
import FAQ from "./pages/faq";
import Ontwikkeling from "./pages/ontwikkeling";
import DemoPilot from "./pages/demopilot";
import ContactFeedbackModal from "./pages/contactfeedbackmodal";
import FAQModal from "./pages/faqmodal";

import ChatPage from "./pages/qrchat";
import WachtwoordVergeten from "./pages/wachtwoordVergeten";
import ResetPassword from "./pages/wachtwoordNieuw";
import QRCodePage from "./pages/qrcode";
import Patients from "./pages/patienten";
import MijnBedrijf from "./pages/archive/mijnbedrijf";
import MijnBedrijfOverzicht from "./pages/wellcombedrijfoverzicht";
import PaymentSuccess from "./pages/paymentsuccess";
import PatientManagement from "./pages/patientmanagement";
import TranslationPage from "./pages/translation";
import Invoices from "./pages/Invoices";

import useConnectivityStatus from "./functions/useConnectivityStatus";
import { getJwtToken } from "./auth";
import config from "./config";
import "./App.css";

const reactServer = config.reactServer;

function App() {
  const [animationFinished, setAnimationFinished] = useState(false);

  const handleAnimationEnd = () => {
    setAnimationFinished(true);
  };

  return (
    <>
      {!animationFinished && (
        <LogoAnimation onAnimationEnd={handleAnimationEnd} />
      )}
      {animationFinished && (
        <Router>
          <AppContent />
        </Router>
      )}
    </>
  );
}

function AppContent() {
  const [isHovered, setIsHovered] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const { isOnline, isServerReachable } = useConnectivityStatus();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [neutralMessage, setNeutralMessage] = useState<string | null>(null);

  const [isInTolkChat, setIsInTolkChat] = useState(false);
  const [showNavConfirmationModal, setShowNavConfirmationModal] = useState(
    false
  );
  const [pendingNavigationTarget, setPendingNavigationTarget] = useState("");
  const [navConfirmationMessage, setNavConfirmationMessage] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIsInTolkChat(location.pathname.includes("/tolkchat"));
  }, [location]);

  const [profile, setProfile] = useState<any>({
    is_admin: false,
    is_org_admin: false,
    naam: false,
    organisation: ["", 0, 0, 0],
  });

  useEffect(() => {
    const token = getJwtToken();
    if (token) {
      fetch(reactServer + "account/profile", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            console.log(`HTTP error! Status: ${response.status}`);
            setNeutralMessage("U bent momenteel niet ingelogd op het portaal.");
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          setProfile(data);
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
        });
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleNavigation = (
    path: string,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (isInTolkChat) {
      event.preventDefault();
      setNavConfirmationMessage(
        "Uw gespreksdata zal verloren gaan. Weet u zeker dat u het huidige gesprek wilt verlaten?"
      );
      setPendingNavigationTarget(path);
      setShowNavConfirmationModal(true);
    } else {
      event.preventDefault();
      if (path === location.pathname) {
        window.location.reload();
      } else {
        navigate(path);
        setMenuOpen(false);
      }
    }
  };

  const confirmNavigation = () => {
    setShowNavConfirmationModal(false);
    if (pendingNavigationTarget === location.pathname) {
      window.location.reload();
    } else {
      navigate(pendingNavigationTarget);
      setMenuOpen(false);
    }
    setPendingNavigationTarget("");
    setNavConfirmationMessage("");
  };

  const cancelNavigation = () => {
    setShowNavConfirmationModal(false);
    setPendingNavigationTarget("");
    setNavConfirmationMessage("");
  };

  useEffect(() => {
    if (!isOnline) {
      setErrorMessage("Geen internet. Check uw verbinding");
    } else if (!isServerReachable) {
      setErrorMessage(
        "Kan de server niet bereiken. Probeer het later opnieuw."
      );
    } else {
      setErrorMessage(null);
    }
  }, [isOnline, isServerReachable]);

  const handleCloseError = () => setErrorMessage(null);
  const handleCloseNeutral = () => setNeutralMessage(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }
  }, [menuOpen]);

  const menuRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (menuOpen) {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          menuRef.current &&
          !menuRef.current.contains(event.target as Node) &&
          !menuButtonRef.current?.contains(event.target as Node)
        ) {
          setMenuOpen(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [menuOpen]);

  // For the Contact Feedback Modal
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  // For the FAQ Modal
  const [showFaqModal, setShowFaqModal] = useState(false);

  return (
    <div>
      {/* NAV BAR */}
      <nav className={`navigation ${scrolled ? "scrolled" : ""}`}>
        <div className={`nav-container ${isMobile ? "ismobile" : ""}`}>
          {isMobile ? (
            <>
              <div className="nav-mobile-container">
                <div
                  className={`ham-menu ${menuOpen ? "active" : ""}`}
                  onClick={() => setMenuOpen(!menuOpen)}
                  ref={menuButtonRef}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <Link
                  to="/tolkchat"
                  onClick={(e) => handleNavigation("/tolkchat", e)}
                >
                  <img
                    src="./logo.png"
                    alt="Logo"
                    className="nav-logo-mobile"
                  />
                </Link>
              </div>
            </>
          ) : (
            <div className="nav-links">
              {profile.is_admin ? (
                <Link
                  className="nav-link"
                  to="/wellcomoverzicht"
                  onClick={(e) => handleNavigation("/wellcomoverzicht", e)}
                >
                  Wellcom overzicht
                </Link>
              ) : profile.is_org_admin ? (
                <Link
                  className="nav-link"
                  to="/mijnbedrijf"
                  onClick={(e) => handleNavigation("/mijnbedrijf", e)}
                >
                  Mijn Bedrijf
                </Link>
              ) : (
                <Link
                  className="nav-link"
                  to="https://wellcomtranslation.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Waarom Wellcom?
                </Link>
              )}

              {/* Contact / Feedback link */}
              <Link
                className="nav-link"
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  setShowFeedbackModal(true);
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {profile.naam ? "Feedback" : "Contact"}
              </Link>

              <Link className="nav-link show-on-mobile-only" to={"/tolkchat"}>
                TolkChat
              </Link>

              {profile.is_admin ? (
                <Link
                  className="nav-link"
                  to="/bedrijfoverzicht"
                  onClick={(e) => handleNavigation("/bedrijfoverzicht", e)}
                >
                  Bedrijfoverzicht
                </Link>
              ) : (
                <Link
                  className="nav-link"
                  to={profile.naam ? "/tolkchat" : "/login"}
                  onClick={
                    profile.naam
                      ? (e) => handleNavigation("/tolkchat", e)
                      : (e) => handleNavigation("/login", e)
                  }
                >
                  {profile.naam ? "TolkChat" : "Start Chat"}
                </Link>
              )}

              <Link
                to={profile.naam ? "/tolkchat" : "/login"}
                onClick={(e) =>
                  handleNavigation(profile.naam ? "/tolkchat" : "/login", e)
                }
              >
                <img src="./logo.png" alt="Logo" className="nav-logo" />
              </Link>

              <Link
                className="nav-link"
                to={profile.naam ? "/scribechat" : "/ontwikkeling"}
                onClick={(e) =>
                  handleNavigation(
                    profile.naam ? "/scribechat" : "/ontwikkeling",
                    e
                  )
                }
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {profile.naam ? "ScribeChat" : "Ontwikkeling"}
              </Link>

              {/* Instead of linking to /faq, open the FAQ modal */}
              <Link
                className="nav-link"
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  setShowFaqModal(true);
                }}
              >
                <>
                  Help <img src="/help.png" alt="" className="help-icon" />
                </>
              </Link>

              <Link
                className="nav-link show-on-mobile"
                to={profile.naam ? "/profiel" : "/login"}
                onClick={(e) =>
                  handleNavigation(profile.naam ? "/profiel" : "/login", e)
                }
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {profile.naam ? "Profiel" : "Login"}
              </Link>
            </div>
          )}
        </div>

        {/* MOBILE MENU DROPDOWN */}
        {isMobile && menuOpen && (
          <div className="nav-links mobile open" ref={menuRef}>
            {profile.is_admin || profile.is_org_admin ? (
              <Link
                className="nav-link"
                to="/wellcomoverzicht"
                onClick={(e) => handleNavigation("/wellcomoverzicht", e)}
              >
                Wellcom overzicht
              </Link>
            ) : (
              <Link
                className="nav-link"
                to="https://wellcomtranslation.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Waarom Wellcom?
              </Link>
            )}

            <Link
              className="nav-link"
              to={profile.naam ? "/tolkchat" : "/login"}
              onClick={(e) =>
                handleNavigation(profile.naam ? "/tolkchat" : "/login", e)
              }
            >
              TolkChat
            </Link>

            <Link
              className="nav-link"
              to={"/scribechat"}
              onClick={(e) => handleNavigation("/scribechat", e)}
            >
              ScribeChat
            </Link>

            {/* Open the Contact/Feedback modal */}
            <Link
              className="nav-link"
              to="#"
              onClick={(e) => {
                e.preventDefault();
                setShowFeedbackModal(true);
                setMenuOpen(false);
              }}
            >
              {profile.naam ? "Feedback" : "Contact"}
            </Link>

            {/* Instead of linking to /faq, we open FAQ modal */}
            <Link
              className="nav-link"
              to="#"
              onClick={(e) => {
                e.preventDefault();
                setShowFaqModal(true);
                setMenuOpen(false);
              }}
            >
              <>
                Help <img src="/help.png" alt="" className="help-icon" />
              </>
            </Link>

            {profile.is_admin ? (
              <Link
                className="nav-link"
                to="/bedrijfoverzicht"
                onClick={(e) => handleNavigation("/bedrijfoverzicht", e)}
              >
                Bedrijfoverzicht
              </Link>
            ) : null}

            <Link
              className="nav-link"
              to={profile.naam ? "/profiel" : "/login"}
              onClick={(e) =>
                handleNavigation(profile.naam ? "/profiel" : "/login", e)
              }
            >
              {profile.naam ? "Profiel" : "Login"}
            </Link>
          </div>
        )}
      </nav>

      {/* MAIN CONTENT */}
      <div className="main-content">
        <Routes>
          {/* Removed: <Route path="/faq" element={<FAQ />} /> */}
          <Route
            path="/"
            element={profile.naam ? <TolkPage /> : <LoginPage />}
          />
          <Route path="/contact" element={<ContactPage />} />
          {/* <Route path="/waarom" element={<WaaromPage />} /> */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          {/* <Route
            path="/register-organisation"
            element={<RegisterOrganisation />}
          />
          <Route
            path="/register-10-plus-organisation"
            element={<RegisterTenPlus />}
          /> */}
          <Route path="/tolkchat" element={<TolkPage />} />
          <Route path="/scribechat" element={<ScribeChat />} />
          {/* <Route path="/history" element={<History />} /> */}
          <Route path="/profiel" element={<Profiel />} />
          <Route path="/abonnement" element={<Abonnementen />} />
          <Route path="/factuuren" element={<Invoices />} />
          <Route path="/contract" element={<Contract />} />
          <Route path="/wellcomoverzicht" element={<WellcomOverzicht />} />
          <Route path="/mijnbedrijf" element={<ProfielOverzichtBedrijf />} />
          {/* <Route path="/translate" element={<TranslationPage />} /> */}
          <Route path="/ontwikkeling" element={<Ontwikkeling />} />
          <Route path="/demopilot" element={<DemoPilot />} />
          <Route path="/chat/:conversationId" element={<ChatPage />} />
          <Route path="/new_chat/:conversationId" element={<ChatPage />} />
          <Route path="/wachtwoord-vergeten" element={<WachtwoordVergeten />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/bedrijfoverzicht" element={<MijnBedrijfOverzicht />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/patient-management" element={<PatientManagement />} />
        </Routes>
      </div>

      {/* FOOTER */}
      <footer className="footer">
        <div className="social-links">
          <a
            href="https://www.linkedin.com/company/wellcom-translation/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="./linkedin-logo.png" alt="LinkedIn" />
          </a>
          <a
            href="https://www.wellcom-translation.nl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="./globe-logo.png" alt="Website" />
          </a>
        </div>
        <div className="additional-links">
          {/* Instead of <Link to="/faq"...> we open FAQ modal */}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setShowFaqModal(true);
            }}
          >
            FAQ
          </a>
          <Link
            to="/ontwikkeling"
            onClick={(e) => handleNavigation("/ontwikkeling", e)}
          >
            Ontwikkeling
          </Link>
          <a href="/./bestanden/Privacyverklaring.pdf" download>
            Privacy Statement
          </a>
          <a href="/./bestanden/Usage_Manual.pdf" download>
            Gebruikershandleiding
          </a>
        </div>
        <div className="copyright">
          {new Date().getFullYear()} Wellcom. all rights reserved.
        </div>
      </footer>

      {/* ERROR + NEUTRAL NOTIFICATIONS */}
      {errorMessage && (
        <Error message={errorMessage} onClose={handleCloseError} />
      )}
      {neutralMessage && (
        <Neutral message={neutralMessage} onClose={handleCloseNeutral} />
      )}

      {/* NAV-CONFIRMATION MODAL WHEN LEAVING TOLKCHAT */}
      {showNavConfirmationModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Gesprek Verlaten?</h2>
            <p>{navConfirmationMessage}</p>
            <div className="modal-buttons">
              <button className="cancel-button" onClick={cancelNavigation}>
                Annuleren
              </button>
              <button className="send-button" onClick={confirmNavigation}>
                Bevestigen
              </button>
            </div>
          </div>
        </div>
      )}

      {/* The Contact Feedback Modal */}
      <ContactFeedbackModal
        isOpen={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
      />

      {/* The FAQ Modal */}
      <FAQModal isOpen={showFaqModal} onClose={() => setShowFaqModal(false)} />
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css"; // global CSS
import "./profiel.css"; // custom CSS for profile page

import Uitnodiging from "../components/UitNodiging";
import Succes from "../notifications/Succes";
import Neutral from "../notifications/Neutral";
import ErrorNotificaton from "../notifications/Error";


import { getJwtToken, logout } from "../auth";
import config from "../config";
import { response } from "express";

const reactServer = config.reactServer;

const ProfilePage: React.FC = () => {
  const [userData, setUserData] = useState<any>(null);
  const [profile, setProfile] = useState<any>(null);
  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [neutral, setNeutral] = useState("");
  const [notifications, setNotifications] = useState<any[]>([]);
  const [showUitNodiging, setShowUitNodiging] = useState(false);

  // Modal states
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedNotificationId, setSelectedNotificationId] = useState<
    number | null
  >(null);

  // Handle opening the confirmation modal
  const handleOpenDeleteModal = (notificationId: number) => {
    // Scroll notifications list to top so user can see the entire modal
    const container = document.querySelector(
      ".notifications-list"
    ) as HTMLElement | null;
    if (container) {
      container.scrollTo({ top: 0, behavior: "smooth" });
      container.classList.add("modal-open"); // disable scrolling in CSS
    }

    setSelectedNotificationId(notificationId);
    setShowDeleteModal(true);
  };

  // If user clicks "Nee" (no) => just hide the modal
  const handleCloseDeleteModal = () => {
    const container = document.querySelector(
      ".notifications-list"
    ) as HTMLElement | null;
    if (container) {
      container.classList.remove("modal-open");
    }

    setSelectedNotificationId(null);
    setShowDeleteModal(false);
  };

  // If user clicks "Ja" => remove the notification from local state
  const handleConfirmDeleteMessage = () => {
    if (!selectedNotificationId) return;

    handleRemoveNotification(selectedNotificationId)

    setNotifications((prev) =>
      prev.filter((n) => n.id !== selectedNotificationId)
    );

    // re-enable scrolling
    const container = document.querySelector(
      ".notifications-list"
    ) as HTMLElement | null;
    if (container) {
      container.classList.remove("modal-open");
    }

    setShowDeleteModal(false);
    setSelectedNotificationId(null);
  };

  const navigate = useNavigate();

  useEffect(() => {
    // Fake user data for demonstration, can be removed if not needed
    const fakeUserData = {
      naam: "Thijs Rood",
      email: "thijs.rood@example.com",
      accounttype: "dokter",
      RegistrationConfirmed: true,
      twofactor: ["123456", "30-01-2024 15:20:10"],
    };
    setUserData(fakeUserData);
  }, []);

  useEffect(() => {
    const token = getJwtToken();

    if (token) {
      fetch(reactServer + "account/profile", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setProfile(data);
          setIsTwoFactorEnabled(Boolean(data.twofactor));

          if (data.org_id && !data.is_org_admin) {
            setShowUitNodiging(data.joined_org <= 0);
          }
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
        });
    }
  }, []);

  const fetchNotifications = async () => {
    const token = getJwtToken();

    try {
      const response = await fetch(`${reactServer}notifications/all`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setNotifications(data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setError(
        "Er is een fout opgetreden bij het ophalen van meldingen."
      );
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const markAsRead = async (notificationId: number) => {
    const token = getJwtToken();

    try {
      const response = await fetch(
        `${reactServer}notifications/${notificationId}/read`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Update local state
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === notificationId
            ? { ...notification, read: true }
            : notification
        )
      );
    } catch (error) {
      console.error("Error marking notification as read:", error);
      setError(
        "Er is een fout opgetreden bij het bijwerken van de melding."
      );
    }
  };

  const handleLeaveOrganisation = async () => {
    const token = getJwtToken(); // Get the JWT token for authentication
  
    const userConfirmation = window.confirm(
      "Weet u zeker dat u de organisatie wilt verlaten? Dit kan niet ongedaan worden gemaakt."
    );
  
    if (!userConfirmation) {
      return; // Exit if the user cancels the action
    }
  
    try {
      const response = await fetch(
        `${reactServer}organisation/leave`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: profile.id, // Send necessary data, such as the user ID
          }),
        }
      );
  
      if (!response.ok) {
        if (response.status === 403) {
          throw new Error(
            "U heeft geen toestemming om deze organisatie te verlaten."
          );
        } else if (response.status === 404) {
          throw new Error("De organisatie kon niet worden gevonden.");
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }
  
      const result = await response.json(); // Parse the response if needed
  
      // Update the UI state upon successful leave
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) =>
          notification.organisationId !== result.organisationId
        )
      );
      setSuccess(
        `U heeft met succes de organisatie ${result.organisationName} verlaten.`
      );
      window.location.reload();
    } catch (error) {
      console.error("Error leaving organisation:", error);
      setError(
        error.message ||
          "Er is een fout opgetreden bij het verlaten van de organisatie."
      );
    } finally {
      // Optional: Perform any cleanup actions here
    }
  };
  

  const handleAcceptInvite = async (notificationId: number) => {
    const token = getJwtToken();

    try {
      const response = await fetch(
        `${reactServer}notifications/${notificationId}/accept-invite`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: profile.id
          })
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === notificationId
            ? { ...notification, read: true }
            : notification
        )
      );
      setSuccess("U heeft zich met succes aangesloten bij de organisatie.")
    } catch (error) {
      console.error("Error accepting invite:", error);
      setError(
        "Er is een fout opgetreden bij het accepteren van de uitnodiging."
      );
    }
  };

  const handleRejectInvite = async (notificationId: number) => {
    const token = getJwtToken();

    try {
      const response = await fetch(
        `${reactServer}notifications/${notificationId}/reject-invite`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === notificationId
            ? { ...notification, read: true }
            : notification
        )
      );
    } catch (error) {
      console.error("Error rejecting invite:", error);
      setError(
        "Er is een fout opgetreden bij het weigeren van de uitnodiging."
      );
    }
  };

  const handleTwoFactorToggle = async () => {
    if (!profile) return;

    try {
      const token = getJwtToken();
      const newStatus = !isTwoFactorEnabled;

      const response = await fetch(reactServer + "account/settings/2fa", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: profile.email,
          twofactorEnabled: newStatus,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      setIsTwoFactorEnabled(newStatus);
    } catch (error) {
      console.error("Error updating 2FA status:", error);
    }
  };

  const handleLogout = () => {
    logout();
    navigate("/", { replace: true });
    window.location.reload();
  };

  const handleConfirmDelete = () => {
    const isConfirmed = window.confirm(
      "Weet u zeker dat u uw account wilt verwijderen? Door uw account te verwijderen, verliest u direct de toegang tot uw account en kunt u geen gebruik meer maken van de eventuele resterende tijd binnen uw betaalde abonnement. Dit proces is definitief en onomkeerbaar."
    );

    if (isConfirmed) {
      handleDeleteUser();
    }
  };

  const handleDeleteUser = async () => {
    if (!profile) return;

    try {
      const token = getJwtToken();
      const response = await fetch(reactServer + "/account/delete-account", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: profile.email }),
      });

      if (response.ok) {
        logout();
        navigate("/", { replace: true });
        window.location.reload();
      } else {
        setError(
          "Kon het account momenteel niet verwijderen. Contacteer de technische afdeling."
        );
      }
    } catch (error) {
      console.error("An error occurred while deleting the account:", error);
    }
  };



  if (!userData || !profile) {
    return <div></div>;
  }

  // Handle subscriptions as array of objects
  const subscriptions = Array.isArray(profile.subscription)
    ? profile.subscription
    : [];

  // Find the active subscription
  let activeSubscription = subscriptions.filter((sub: any) => sub.pricing_id !== 1).find((sub: any) => sub.status === "active");
  if (!activeSubscription) {
    // If no active subscription was found, include the pricing_id == 1
    activeSubscription = subscriptions.find((sub: any) => sub.status === "active");
  }
    

  const activeSubscriptionName = activeSubscription
    ? activeSubscription.pricing.name
    : "Geen actief abonnement";
  const subscriptionEndDate = profile.subscription_end_date
    ? new Date(profile.subscription_end_date)
    : null;
  const subscriptionStartDate = profile.subscription_start_date
    ? new Date(profile.subscription_start_date)
    : null;

  const isExpired = subscriptionEndDate && subscriptionEndDate < new Date();
  const isDemo = activeSubscriptionName === "Demo";


  // TO-DO: I think this is obsolete
  const handleOrgInviteAction = async (action: string): Promise<void> => {
    // console.log(action);
    let resp: Number = 0;
    if (action === "accept") {
      resp = 1;
    }

    if (action === "cancel") {
      setShowUitNodiging(false);
      return;
    }

    try {
      // Get JWT token
      const token = getJwtToken();

      // Make the API call to delete the account
      const response = await fetch(`${reactServer}/organisation/join-org`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: profile.id,
          resp: resp,
        }),
      });

      // Handle the response
      if (response.ok) {
        alert("User joined Successfully");
      } else {
        alert("User rejected to join the org");
        // console.error("User did not join the org", await response.text());
        // setErrorMessage(
        //   "Kon het account momenteel niet verwijderen. Contacteer de technische afdeling."
        // );
      }
      setShowUitNodiging(false);
    } catch (error) {
      // Handle unexpected errors
      console.error("An error occurred while deleting the account:", error);
      setError(
        "Er is een onverwachte fout opgetreden. Probeer het later opnieuw."
      );
    }
  };

  // Dismiss the notification (removes it from screen + sets dismissed in the DB)
  const handleRemoveNotification = async (notificationId: number) => {
    const token = getJwtToken();

    try {
      const response = await fetch(
        `${reactServer}notifications/${notificationId}/dismiss`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // If the backend call is successful, remove it locally so we see immediate change
      setNotifications((prevNotifications) =>
        prevNotifications.filter((n) => n.id !== notificationId)
      );
    } catch (error) {
      console.error("Error dismissing notification:", error);
      setError(
        "Er is een fout opgetreden bij het verwijderen van de melding."
      );
    }
  };

  const handleCloseSucces = () => {
    setSuccess("");
  };

  const handleCloseNeutral = () => {
    setNeutral("");
  };

  const handleCloseError = () => {
    setError("");
  };

  return (
    <div className="profile-page-container">
      {success && <Succes message={success} onClose={handleCloseSucces} />}
      {neutral && <Neutral message={neutral} onClose={handleCloseNeutral} />}
      {error && <ErrorNotificaton message={error} onClose={handleCloseError} />}
      <>
        {/* Left Block: User Details */}
        <div className="left-column">
          <h2 className="title">{profile.naam}'s Profiel</h2>

          {/* First white block: Profiel Details */}
          <div className="profile-details-block">
            <h3 className="profile-header-margin">Profiel Details</h3>
            <p className="profile-text-margin">E-mailadres: {profile.email}</p>
            <p className="profile-text-margin">
              Aantal patiënten: {profile.patienten?.length ?? 0}
            </p>
            <p className="profile-text-margin">
              Gebruik aantal: {Math.ceil(profile.usage) || 0} /{" "}
              {profile.is_unlimited
                ? "Onbekend"
                : profile.limit !== undefined
                  ? `${profile.limit} TolkChat's `
                  : ""}
            </p>
            {profile?.is_org_admin && (
              <p className="profile-text-margin">
                Gebruikers: {profile.org_users_count ?? " n.v.t."}/
                {profile.org_max_deelnemers ?? " n.v.t."}
              </p>
            )}
            {profile.org_naam && (
              <p className="profile-text-margin">
                U verlaat de organisatie op: {profile.org_leave_date}
              </p>
            )}
            <p className="profile-text-margin">
              Registratie bevestigd: {userData.RegistrationConfirmed ? "Ja" : "Nee"}
            </p>
          </div>

          {/* Second white block: Abonnement Details */}
          <div className="subscription-block">
            <h3 className="profile-header-margin">Abonnement Details</h3>
            <p className="profile-text-margin">
              Abonnementstype: {activeSubscriptionName}
            </p>
            {activeSubscription && isExpired ? (
              <p className="profile-text-margin subscription-expired">
                Uw abonnement is verlopen!
              </p>
            ) : activeSubscription && subscriptionEndDate ? (
              <p className="profile-text-margin subscription-valid">
                Abonnement is actief tot {subscriptionEndDate.toLocaleDateString()}
              </p>
            ) : (
              <p className="profile-text-margin subscription-expired">
                Geen actief abonnement
              </p>
            )}
            {subscriptionStartDate && (
              <p className="profile-text-margin">
                Abonnement aangeschaft op {subscriptionStartDate.toLocaleDateString()}
              </p>
            )}
            {!isDemo &&
              activeSubscription &&
              subscriptionEndDate &&
              subscriptionEndDate > new Date() && (
                <p className="profile-text-margin">
                  Abonnement wordt verlengd op {subscriptionEndDate.toLocaleDateString()}
                </p>
              )}
            <a href="/#/abonnement" className="abbonement-button">
              Abonnement wijzigen
            </a>
          </div>
        </div>

        {/* Right Block: Notifications */}

        <div className="notifications-block">
          <h2 className="notifications-title">Meldingen</h2>
          <div className="notifications-list">
            {notifications.length === 0 ? (
              <div className="notification-item read">
                <p>Geen nieuwe meldingen.</p>
              </div>
            ) : (
              notifications.map((notification) => (
                <div
                  key={notification.id}
                  className={`notification-item ${notification.read ? "read" : "unread"
                    }`}
                >
                  {/* Left side: message + date + (optional) invite buttons */}
                  <div className="notification-left">
                    <p className="notification-message">
                      {notification.message}
                    </p>
                    <p className="notification-date">
                      {new Date(notification.created_at).toLocaleString()}
                    </p>

                    {notification.type === "organization_invite" &&
                      !notification.read && (
                        <div className="notification-invite-actions">
                          <button
                            className="accept-button"
                            onClick={() => handleAcceptInvite(notification.id)}
                          >
                            Accepteren
                          </button>
                          <button
                            className="reject-button"
                            onClick={() => handleRejectInvite(notification.id)}
                          >
                            Weigeren
                          </button>
                        </div>
                      )}
                  </div>

                  {/* Right side: Mark as Read + Bin */}
                  <div className="notification-right">
                    {/* Show Mark-as-Read button if NOT read */}
                    {!notification.read && (
                      <button
                        className="mark-read-button"
                        onClick={() => markAsRead(notification.id)}
                      >
                        Markeer als gelezen
                      </button>
                    )}

                    {/* Show Bin only if notification is read */}
                    {notification.read && (
                      // <img
                      //   alt="bin"
                      //   className="bin"
                      //   src="/bin_black.png"
                      //   onMouseOver={(e) => (e.currentTarget.src = "/bin_red.png")}
                      //   onMouseOut={(e) => (e.currentTarget.src = "/bin_black.png")}
                      //   onClick={() => handleRemoveNotification(notification.id)}
                      // />
                      <button
                        className="mark-read-button delete"
                        onClick={() => handleOpenDeleteModal(notification.id)}
                      >
                        Bericht verwijderen
                      </button>
                    )}
                  </div>
                </div>
              ))
            )}
            {/* Delete Confirmation Modal (inline within notifications-list) */}
            {showDeleteModal && (
              <div className="delete-modal-backdrop">
                <div className="delete-modal">
                  <p>Weet u zeker dat u dit bericht wilt verwijderen?</p>
                  <div className="delete-modal-buttons">
                    <button onClick={handleCloseDeleteModal}>Nee</button>
                    <button onClick={handleConfirmDeleteMessage}>Ja</button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* {showUitNodiging ? (
            <Uitnodiging
              joined={profile.joined_org}
              orgInviteAction={handleOrgInviteAction}
              orgNaam={profile.org_naam}
            />
          ) : (
            <></>
          )} */}
          <hr className="logout-line" />
          {/* Two-Factor Authentication */}
          <h3 className="profile-header-margin">Instellingen</h3>
          <div className="two-factor-auth">
            <label>
              <strong>Tweestapsverificatie</strong>
              <input
                type="checkbox"
                checked={isTwoFactorEnabled}
                onChange={handleTwoFactorToggle}
              />
            </label>
          </div>

          <div className="centerme">
            <button className="logout-button" onClick={handleLogout}>
              Uitloggen
            </button>
            <button className="delete-button" onClick={handleConfirmDelete}>
              Verwijder Account
            </button>
          </div>
        </div>
      </>
    </div>
  );
};

export default ProfilePage;

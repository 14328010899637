import config from '../config';
const reactServer = config.reactServer;

// Import necessary modules for making the HTTP request
export const getSpeechToken = async (): Promise<string | null> => {
  try {
      // Make a request to your Flask backend to retrieve the token
      const response = await fetch(reactServer + '/speech/get-speech-token', {
          method: 'GET',
      });

      // Check if the response is successful
      if (!response.ok) {
          console.error('Failed to retrieve token:', response.statusText);
          return null;
      }

      // Parse the JSON response
      const data = await response.json();

      // Check if the token is in the response
      if (data.token) {
          return data.token;
      } else {
          console.error('No token found in response');
          return null;
      }
  } catch (error) {
      console.error('Error fetching the token:', error);
      return null;
  }
};

// src/views/ContactPage.tsx
import React, { useState } from 'react';

import checkingGif from '../images/check.gif';

// Access the server variable
import { getJwtToken } from '../auth';
import config from '../config';
const reactServer = config.reactServer;

const ContactPage: React.FC = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [returnMessage, setReturnMessage] = useState('');

    const handleContactFormulier = async () => {
        if (name && email && message) {
            const requestData = {
                name: name,
                email: email,
                title: 'Contactformulier', // You can customize the title
                message: message,
                message_type: 'contact', // You can adjust this based on your server logic
                details: [''], // You can customize the details
            };

            const token = getJwtToken();

            try {
                const response = await fetch(reactServer + '/mail/send', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestData),
                });

                if (!response.ok) {
                    throw new Error('Failed to send mail');
                }

                // Mail sent successfully, handle the response as needed
                console.log('Mail sent successfully');
                setReturnMessage('Uw contactformulier is verzonden, wij nemen zo snel mogelijk contact met u op.')
            } catch (error) {
                console.error('Error sending mail:', error);
                setReturnMessage('Fout bij het verzenden van het contactformulier.')
            }
        } else {
            // Handle case when form fields are not filled
            console.log('Please fill all form fields');
            setReturnMessage('Vul graag alle velden in.')
        }
    };

    return (
        <div>
            {!(returnMessage === 'Uw contactformulier is verzonden, wij nemen zo snel mogelijk contact met u op.') &&
                <div className='contact-page'>
                    <h1 className="title">Neem Contact Op</h1>
                    <p className="description">Heeft u vragen of feedback? Neem gerust contact met ons op.</p>
                    <form className="contact-form" onSubmit={(e) => {
                        e.preventDefault(); // Prevent default form submission behavior
                        handleContactFormulier(); // Call handleContactFormulier function when form is submitted
                    }}>
                        <input
                            type="text"
                            placeholder="Uw Naam"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)} // Update name state
                            required
                        />
                        <input
                            type="email"
                            placeholder="Uw E-mail"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} // Update email state
                            required
                        />
                        <textarea
                            className='textarea'
                            placeholder="Uw Bericht"
                            name="message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)} // Update message state
                            required
                        ></textarea>
                        <button type="submit">Verstuur</button>
                    </form>
                    <div className='contact-message'>{returnMessage}</div>
                </div>
            }
            {returnMessage === 'Uw contactformulier is verzonden, wij nemen zo snel mogelijk contact met u op.' &&
                <div className='contact-page'>
                    <div className='contact-message'>{returnMessage}</div>
                    <img className='check_gif' src={checkingGif} alt="Submitting..." />
                </div>
            }

        </div>
    );
};

export default ContactPage;

// tolkchat.tsx

import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./tolkchat.css";

import Eindscherm from "./pages/eindegesprek";
import SettingsMenu from "./pages/SettingsMenu";
import LanguageSelector from "./pages/languageselector";
import Succes from "./notifications/Succes";
import Neutral from "./notifications/Neutral";
import ErrorNotificaton from "./notifications/Error";

// Language mappings
import {
  allLanguages,
  TranslateMapping,
  SpeechToTextMapping,
  TextToSpeechMapping,
} from "./mappings/microsoft";

// Import the Microsoft token
import { getSpeechToken } from "./functions/MicrosoftToken";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";

// Access the server variable
import { getJwtToken } from "./auth";
import config from "./config";
const reactServer = config.reactServer;

interface Message {
  id: number;
  linkedId: number;
  text: string;
  className: string;
  mobileClass: string;
  isEditing: boolean;
}

const ChatPage: React.FC = () => {
  const [originalMessages, setOriginalMessages] = useState<Message[]>([]);
  const [leftMessages, setLeftMessages] = useState<Message[]>([]);
  const [rightMessages, setRightMessages] = useState<Message[]>([]);
  const [allMessages, setAllMessages] = useState<Message[]>([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [neutral, setNeutral] = useState("");
  const [conversationId, setConversationId] = useState(1);
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentMessageLeft, setCurrentMessageLeft] = useState("");
  const [currentMessageRight, setCurrentMessageRight] = useState("");
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [doctor, setDoctor] = useState("Dokter");
  const [patient, setPatient] = useState("Patiënt");
  const [fromLanguage, setFromLanguage] = useState("English (United Kingdom)");
  const [toLanguage, setToLanguage] = useState("Dutch (Netherlands)");
  const [isChatStarted, setIsChatStarted] = useState(false);
  const [showNavbarAndFooter, setShowNavbarAndFooter] = useState(true);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [confirmationType, setConfirmationType] = useState("");
  const [patientInputPlaceholder, setPatientInputPlaceholder] = useState(
    "Write your message here..."
  );
  const [eindeGesprek, setEindeGesprek] = useState(false);
  const [isRecording, setIsRecording] = useState<null | "patient" | "doctor">(
    null
  );
  const [transcription, setTranscription] = useState<string>("");
  const recognition = useRef<MediaRecorder | null>(null);
  const [isClickable, setIsClickable] = useState(true); // Prevent multiple clicks
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(
    null
  );
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [currentAudioMessageId, setCurrentAudioMessageId] = useState<
    number | null
  >(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve the passed patient from location.state (if any)
  const selectedPatient = location.state?.patient || null;

  useEffect(() => {
    if (selectedPatient) {
      // Set the TolkChat to use this patient's ID and language
      setPatient(selectedPatient.patient_tag);
      setFromLanguage(selectedPatient.language);
    }
  }, [selectedPatient]);

  // For technical problem modal
  const [showTechnicalProblemModal, setShowTechnicalProblemModal] = useState(
    false
  );
  const [
    technicalProblemDescription,
    setTechnicalProblemDescription,
  ] = useState("");
  const [technicalProblemFile, setTechnicalProblemFile] = useState<File | null>(
    null
  );

  const [showEndConversationModal, setShowEndConversationModal] = useState(
    false
  );

  const [showNewConversationModal, setShowNewConversationModal] = useState(
    false
  );

  const [shouldStopRecognition, setShouldStopRecognition] = useState(false);
  useEffect(() => {
    if (shouldStopRecognition) {
      stopSpeechRecognition();
      setShouldStopRecognition(false); // Reset the flag
      setIsRecording(null); // Update recording state if necessary
      console.log("Recognition stopped due to silence timeout.");
    }
  }, [shouldStopRecognition]);

  // Settings
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isAutoPlayEnabled, setAutoPlay] = useState("uit");
  const [language, setLanguage] = useState("Nederlands");
  const [timerVisibility, setTimerVisibility] = useState(true);
  const availableLanguages = ["Nederlands"]; // English later

  const currentDate = new Date().toISOString().split("T")[0]; // Adjust as needed

  useEffect(() => {
    setShowNavbarAndFooter(false);
  }, []);

  // Get all the data from the user
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const token = getJwtToken();

    if (token) {
      fetch(reactServer + "account/profile", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setProfile(data);
          setDoctor(data.naam);
          setAutoPlay(data.autoplay)
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
        });
    }
  }, []);

  const allPatients =
    profile?.patienten?.map(
      (patient) => `${patient.patient_tag}`
    ) || [];

  /////////////////////
  // Timer recording //
  /////////////////////
  const [elapsedSeconds, setElapsedSeconds] = useState<number>(() => {
    // Retrieve elapsed time from localStorage if available
    const savedElapsedTime = localStorage.getItem("elapsed_time");
    return savedElapsedTime ? Number(savedElapsedTime) : 0;
  });

  const intervalRef = useRef<number | null>(null);
  const logIntervalRef = useRef<number | null>(null);
  const updatedTimeRef = useRef<number>(0);
  const [isSessionActive, setIsSessionActive] = useState(false);

  // every 5 seconds the time is incremented on the db
  let incrementInterval = 5 // seconds

  const startSession = () => {
    // Guard to check if the session is already running
    if (isSessionActive) {
      console.log("Session timer is already active. Skipping...");
      return;
    }

    console.log("Session started");

    setIsSessionActive(true); // Set the session as active

    // Start a new interval
    intervalRef.current = window.setInterval(() => {
      setElapsedSeconds((prev) => {
        const updatedTime = prev + 1;
        console.log(updatedTime);

        // Update the ref with the latest updated time
        updatedTimeRef.current = updatedTime;

        // 15 minuten verstreken
        if (updatedTime === 900) {
          const token = getJwtToken();
          if (token) {
            incrementUserUsage(profile.id, token); // Call the new function here
            setNeutral("Er zijn 15 minuten verstreken, u gebruikt nu een tweede Chat.")
          }
        }

        // 30 minuten verstreken
        if (updatedTime === 1800) {
          const token = getJwtToken();
          if (token) {
            incrementUserUsage(profile.id, token); // Call the new function here
            setNeutral("Er zijn 30 minuten verstreken, u gebruikt nu een derde chat.")
          }
        }

        // 45 minuten verstreken
        if (updatedTime === 2700) {
          const token = getJwtToken();
          if (token) {
            incrementUserUsage(profile.id, token); // Call the new function here
            setNeutral("Er zijn 30 minuten verstreken, u gebruikt nu een vierde chat.")
          }
        }
        // 3 minute warning
        if (updatedTime === 3420) {
          setNeutral("Er zijn nog 3 minuten over voordat de maximale tijd is verstreken.")
        }

        // If 60 minutes (3600 seconds) have passed, stop the session
        if (updatedTime === 3600) {
          setNeutral("Er zijn 60 minuten verstreken, wij sluiten hierbij de TolkChat.")
          console.log("60 minutes reached. Ending session...");
          endSession(); // Stop the session
          handleConfirmation("Einde gesprek");
          confirmAction();
        }

        // Update elapsed time in localStorage every 5 seconds
        if (updatedTime % incrementInterval === 0) {
          incrementUsageTimeBackend();
          console.log(`Elapsed time: ${updatedTime.toString()} seconds`);
        }

        return updatedTime;
      });
    }, 1000);
  };

  // End session timer
  const endSession = () => {
    console.log("Session ended");

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    if (logIntervalRef.current) {
      clearInterval(logIntervalRef.current);
      logIntervalRef.current = null;
    }

    console.log(`Total time elapsed: ${elapsedSeconds} seconds`);
    setIsSessionActive(false); // Mark the session as inactive
    setElapsedSeconds(0); // Reset state
    localStorage.removeItem("elapsed_time");
  };

  // Function to format time as MM:SS
  const formatTime = (totalSeconds: number): string => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };


  // Add event listeners
  useEffect(() => {
    const handleBeforeUnload = () => {
      console.log("Session stopped due to unload");
      endSession();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      if (intervalRef.current) clearInterval(intervalRef.current);
      if (logIntervalRef.current) clearInterval(logIntervalRef.current);
    };
  }, []);

  // Function to increment user's usage
  const incrementUserUsage = (profileId: string, token: string) => {
    fetch(reactServer + "usage/update_usage", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id: profileId }), // Include the user ID in the request body
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Usage count updated:", data);
      })
      .catch((error) => {
        console.error("Error updating usage count:", error);
      });
  };

  // Determine subscription info
  const subscriptions = Array.isArray(profile?.subscription) ? profile.subscription : [];
  const activeSubscription = subscriptions.find((sub: any) => sub.status === "active");
  const activeSubscriptionName = activeSubscription ? activeSubscription.pricing.name : null;

  // The function that will start the Tolkchat's procedure
  const startChat = () => {
    // Should start a session timer here
    try {
      console.log("Started the startSession function");
      startSession(); // Call the session timer start
    } catch (error) {
      console.error("Failed to start session timer:", error);
    }

    if (!profile) {
      setError("Profielinformatie niet beschikbaar.");
      return;
    }

    // Check subscription status
    // No active subscription
    if (!activeSubscription) {
      setError("U heeft momenteel geen lopend abonnement.");
      return;
    }

    // Check if subscription is inactive
    if (activeSubscription.status === "inactive") {
      setError("Uw abonnement is niet langer actief. Gelieve een nieuw abonnement af te sluiten.");
      return;
    }

    // Check if subscription is cancelled
    if (activeSubscription.status === "cancelled") {
      setError("Uw abonnement is stopgezet. Gelieve uw abonnement te heractiveren of te upgraden.");
      return;
    }

    // Check if subscription is expired (if subscription_end_date available)
    if (profile.subscription_end_date && currentDate > profile.subscription_end_date) {
      setError("Uw abonnement is verlopen. Gelieve uw abonnement te vernieuwen.");
      return;
    }

    // Check Demo subscription usage limit: 5 free TolkChats
    if (activeSubscriptionName === "Demo" && profile.usage >= 5) {
      setError("U heeft alle 5 gratis TolkChats van de Demo gebruikt. Wilt u gebruik blijven maken van TolkChat, upgrade dan uw account.");
      return;
    }

    // Check Zorgverlener Go limit: 30 TolkChats per month
    if (activeSubscriptionName === "Zorgverlener Go" && profile.usage >= 30) {
      setError("U heeft deze maand al 30 TolkChats gebruikt. Wacht tot de volgende betaalperiode of upgrade uw account.");
      return;
    }

    // if a user does more then 999 tolkchats in a month block them as well
    // ...
    // ...


    setIsChatStarted(true);

      // Increment user's usage count
    const token = getJwtToken();
    if (token) {
      incrementUserUsage(profile.id, token); // Call the new function here
    }
  };

  // called whenever someone presses 'Einde gesprek'
  const handleEindeGesprek = async () => {
    setShowEndConversationModal(true);
  }

  // called whenever someone presses 'Nieuw gesprek'
  const handleNieuwGesprek = async () => {
    setShowNewConversationModal(true);
  }

  // Function to send usage time to backend
  const incrementUsageTimeBackend = async () => {

    const token = getJwtToken(); // Function to retrieve the JWT token
    if (!token) {
      console.log('No authorization token.');
      return;
    }

    const response = await fetch(reactServer + "usage/update_usage_seconds", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        duration: incrementInterval,
        user_id: profile.id, // Replace with the correct user ID
      }),
    });

    if (!response.ok) {
      console.log(`Failed to update usage: ${response.statusText}`);
    }
  };

  const setPatientAndLanguage = (selectedPatient: string) => {
    const foundPatient = profile?.patienten?.find(
      (patient: any) => `${patient.patient_tag}` === selectedPatient
    );

    if (foundPatient) {
      setPatient(selectedPatient);
      setFromLanguage(foundPatient.language);
    }
  };


  // Function to handle buttons
  // TO-DO: Check whether this is still used properly or can be shortened
  const handleConfirmation = (type: string) => {
    const confirmationMessages: Record<string, string> = {
      "Einde gesprek": "Weet je zeker dat je dit gesprek wilt afsluiten?",
      // 'Technisch probleem': 'Deze functie is nog niet geimplementeerd.',
      "Twee schermen": "Deze functie is nog niet geimplementeerd.",
      "Start Nieuw Gesprek":"Uw huidige gespreksdata zal verloren gaan. Weet u zeker dat u een nieuw gesprek wilt starten?",
    };

    setConfirmationType(type);
    setConfirmationMessage(confirmationMessages[type] || "");

    if (type === "Einde gesprek") {
      setConfirmationModal(true);
    } else if (type === "Technisch probleem") {
      setShowTechnicalProblemModal(true);
    } else if (type === "Start Nieuw Gesprek") {
      setConfirmationModal(true);
    }
  };

  const confirmAction = () => {

    // Stop any currently playing audio
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
      setIsAudioPlaying(false);
      setCurrentAudioMessageId(null);
    }

    setEindeGesprek(true);
    setConfirmationModal(false);
    setConfirmationType("");
  };


  // Use useRef to persist the counter across renders
  const messageIdCounter = useRef(0);

  const generateUniqueId = () => {
    return messageIdCounter.current++;
  };

  const sendMessage = async (language: string) => {
    setIsSendingMessage(true);

    if (currentMessage.trim() === "") return;

    const messageId = generateUniqueId();
    const translatedMessageId = generateUniqueId();

    const message: Message = {
      id: messageId,
      linkedId: translatedMessageId,
      text: currentMessage,
      className: language === fromLanguage ? "message-left" : "message-right",
      mobileClass: language === fromLanguage ? "mobile-left" : "mobile-right",
      isEditing: false,
    };

    const translatedText = await translateMessage(
      message.text,
      language === fromLanguage ? fromLanguage : toLanguage,
      language === fromLanguage ? toLanguage : fromLanguage
    );

    const translatedMessage: Message = {
      id: translatedMessageId,
      linkedId: messageId,
      text: translatedText,
      className: language === fromLanguage ? "message-left" : "message-right",
      mobileClass: language === fromLanguage ? "mobile-right" : "mobile-left",
      isEditing: false,
    };

    setCurrentMessage("");
    setCurrentMessageLeft("");
    setCurrentMessageRight("");

    if (language === fromLanguage) {
      setLeftMessages([...leftMessages, message]);
      setRightMessages([...rightMessages, translatedMessage]);
      setOriginalMessages([...originalMessages, message]);
      setAllMessages([...allMessages, message, translatedMessage]);
    } else {
      setLeftMessages([...leftMessages, translatedMessage]);
      setRightMessages([...rightMessages, message]);
      setOriginalMessages([...originalMessages, message]);
      setAllMessages([...allMessages, message, translatedMessage]);
    }

    setIsSendingMessage(false);

    // Autoplay enabled play the fragment in the other language
    if (profile) {
      console.log(isAutoPlayEnabled)
      if (isAutoPlayEnabled === 'uit') {
        console.log('Autoplay staat uit')
      }
      if (isAutoPlayEnabled === 'patiënt' && language === toLanguage) {
        console.log('Autoplay voor patiënt')
        handleTextToSpeech(translatedMessage, messageId)
      }
      if (isAutoPlayEnabled === 'arts' && language === fromLanguage) {
        console.log('Autoplay voor arts')
        handleTextToSpeech(translatedMessage, messageId)
      }
      if (isAutoPlayEnabled === 'beide') {
        console.log('Autoplay voor beide')
        handleTextToSpeech(translatedMessage, messageId)
      }
    }
    return;
  };

  const translateMessage = async (
    text: string,
    source_language: string,
    target_language: string
  ) => {
    const token = getJwtToken();
    try {
      const response = await fetch(reactServer + "translation/translate", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: text,
          source_language: TranslateMapping[source_language],
          target_language: TranslateMapping[target_language],
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.translated_text;
    } catch (error) {
      console.error("There was an error translating the text: ", error);
      return "";
    }
  };

  const handleVoiceInput = async (speaker: "patient" | "doctor") => {
    if (isRecording === null) {
      setIsSendingMessage(true);
      setIsRecording(speaker);
      startSpeechRecognition(speaker);
    } else if (isRecording === speaker) {
      setIsSendingMessage(false);
      stopRecording();
    }
  };

  const recognizerRef = useRef<sdk.SpeechRecognizer | null>(null);
  const [isRecognizing, setIsRecognizing] = useState(false);

  const stopRecording = () => {
    stopSpeechRecognition();
    setIsRecording(null);
    console.log("Pressed stop recording.");
  };

  const lastTranslatedMessage = useRef(null);
  const lastTranslatedMessageId = useRef(null);
  let messageReady = false;
  let translatedRecognizingText = "";
  let lastTranslationTime = 0; // Tracks the last translation call time in milliseconds
  const translationCooldown = 5000;
  let apiCallsCounter = 0;

  const startSpeechRecognition = async (speaker: string) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      if (stream) {
        const token = await getSpeechToken(); // Get your speech token
        const speechConfig = sdk.SpeechConfig.fromAuthorizationToken(
          token,
          "northeurope"
        );

        // Set silence timeouts
        speechConfig.setProperty(
          sdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs,
          "90000"
        );
        speechConfig.setProperty(
          sdk.PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs,
          "90000"
        );

        speechConfig.setProperty(sdk.PropertyId.SpeechServiceResponse_ProfanityOption, "2");

        const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();

        let autoDetectSourceLanguageConfig;
        if (speaker === "doctor") {
          console.log(
            "Microfoon van de dokter geactiveerd, text zal nu in het Nederlands worden opgenomen."
          );
          autoDetectSourceLanguageConfig = sdk.AutoDetectSourceLanguageConfig.fromLanguages(
            ["nl-NL"]
          );
        } else {
          const detectedLanguage = SpeechToTextMapping[fromLanguage];
          console.log(
            "Microfoon van de patient geactiveerd, text zal nu worden opgenomen in het",
            detectedLanguage
          );
          autoDetectSourceLanguageConfig = sdk.AutoDetectSourceLanguageConfig.fromLanguages(
            [detectedLanguage]
          );
        }

        const recognizer = sdk.SpeechRecognizer.FromConfig(
          speechConfig,
          autoDetectSourceLanguageConfig,
          audioConfig
        );
        recognizerRef.current = recognizer; // Store the recognizer in the ref

        recognizer.speechEndDetected = (s, e) => {
          console.log("Speech end detected.");
          setShouldStopRecognition(true); // Set the flag to true
        };

        let currentMessageText = "";
        let currentMessageEnding = "";

        recognizer.recognizing = async (s, e) => {
          console.log("RECOGNIZING:", e.result.text);
          currentMessageEnding = e.result.text;

          const messageId = generateUniqueId();
          const translatedMessageId = generateUniqueId();

          const message: Message = {
            id: messageId,
            linkedId: translatedMessageId,
            text: currentMessageText + " " + currentMessageEnding,
            className: speaker === "doctor" ? "message-right" : "message-left",
            mobileClass: speaker === "doctor" ? "mobile-right" : "mobile-left",
            isEditing: false,
          };

          const now = Date.now();

          // Only call translateMessage if enough time has passed
          if (now - lastTranslationTime >= translationCooldown) {
            translatedRecognizingText = await translateMessage(
              message.text,
              speaker === "doctor" ? toLanguage : fromLanguage,
              speaker === "doctor" ? fromLanguage : toLanguage
            );
            apiCallsCounter += 1;
            lastTranslationTime = now; // Update the last call time
          } else {
            console.log("Skipping translation to save costs.");
          }

          // const translatedText = await translateMessage(
          //   message.text,
          //   speaker === "doctor" ? toLanguage : fromLanguage,
          //   speaker === "doctor" ? fromLanguage : toLanguage
          // );

          const translatedMessage: Message = {
            id: translatedMessageId,
            linkedId: messageId,
            text: translatedRecognizingText,
            className: speaker === "doctor" ? "message-right" : "message-left",
            mobileClass: speaker === "doctor" ? "mobile-left" : "mobile-right",
            isEditing: false,
          };

          setIsSendingMessage(false);
          if (speaker === "doctor") {
            setRightMessages([...rightMessages, message]);
            setLeftMessages([...leftMessages, translatedMessage]);
          } else {
            setRightMessages([...rightMessages, translatedMessage]);
            setLeftMessages([...leftMessages, message]);
          }
          setOriginalMessages([...originalMessages, message]);
          setAllMessages([...allMessages, message, translatedMessage]);
        };

        // TO-DO: Everytime a message is recognized (just like ScribeChat) make a new message in the chat
        // Only translate this new message not the whole text
        recognizer.recognized = async (s, e) => {
          if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
            currentMessageText += " " + e.result.text;
            console.log("RECOGNIZED:", currentMessageText);

            const messageId = generateUniqueId();
            const translatedMessageId = generateUniqueId();

            const message: Message = {
              id: messageId,
              linkedId: translatedMessageId,
              text: currentMessageText,
              className:
                speaker === "doctor" ? "message-right" : "message-left",
              mobileClass:
                speaker === "doctor" ? "mobile-right" : "mobile-left",
              isEditing: false,
            };

            const translatedText = await translateMessage(
              message.text,
              speaker === "doctor" ? toLanguage : fromLanguage,
              speaker === "doctor" ? fromLanguage : toLanguage
            );

            apiCallsCounter += 1;
            console.log("Made a total of ", apiCallsCounter, " calls to the translation API.")
            apiCallsCounter = 0;

            const translatedMessage: Message = {
              id: translatedMessageId,
              linkedId: messageId,
              text: translatedText,
              className:
                speaker === "doctor" ? "message-right" : "message-left",
              mobileClass:
                speaker === "doctor" ? "mobile-left" : "mobile-right",
              isEditing: false,
            };

            lastTranslatedMessage.current = translatedMessage;
            lastTranslatedMessageId.current = translatedMessageId;
            messageReady = true;
            console.log("Updated lastTranslatedMessage:", lastTranslatedMessage.current);

            setIsSendingMessage(false);
            if (speaker === "doctor") {
              setRightMessages([...rightMessages, message]);
              setLeftMessages([...leftMessages, translatedMessage]);
            } else {
              setRightMessages([...rightMessages, translatedMessage]);
              setLeftMessages([...leftMessages, message]);
            }
            setOriginalMessages([...originalMessages, message]);
            setAllMessages([...allMessages, message, translatedMessage]);
          } else if (e.result.reason === sdk.ResultReason.NoMatch) {
            console.log("No speech recognized.");
          }
        };

        recognizer.canceled = (s, e) => {
          console.error("Recognition canceled:", e.reason);
        };

        recognizer.sessionStopped = (s, e) => {

          console.log("Recognition stopped.");
          console.log('Autoplay: ', isAutoPlayEnabled)
          console.log('Bericht ', lastTranslatedMessage.current)

          setTimeout(() => {
            if (messageReady) {


              // Check which autoplay version we are using
              if (isAutoPlayEnabled === 'uit') {
                console.log('Autoplay staat uit')
                messageReady = false;
              }
              if (isAutoPlayEnabled === 'patiënt' && speaker === 'doctor') {
                console.log('Autoplay voor patiënt')
                handleTextToSpeech(lastTranslatedMessage.current, lastTranslatedMessageId.current)
                messageReady = false;
              }
              if (isAutoPlayEnabled === 'arts' && speaker === 'patient') {
                console.log('Autoplay voor arts')
                handleTextToSpeech(lastTranslatedMessage.current, lastTranslatedMessageId.current)
                messageReady = false;
              }
              if (isAutoPlayEnabled === 'beide') {
                console.log('Autoplay voor beide')
                handleTextToSpeech(lastTranslatedMessage.current, lastTranslatedMessageId.current)
                messageReady = false;
              }

            } else {
              console.error("Translated message not yet available for autoplay.");
            }
          }, 1000);
        }

        recognizer.startContinuousRecognitionAsync();
        setIsRecognizing(true); // Update recognition state

      }
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopSpeechRecognition = () => {
    if (recognizerRef.current) {
      recognizerRef.current.stopContinuousRecognitionAsync(
        () => {
          console.log("Recognition stopped.");
          setIsRecognizing(false); // Update recognition state
        },
        (err) => {
          console.error("Error stopping recognition:", err);
        }
      );
    }
  };

  const handleTextToSpeech = async (message: Message, messageId: number) => {
    try {
      // Stop existing audio if playing
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
      }

      console.log("Starting text to speech.");
      const token = getJwtToken();
      const formData = new FormData();
      console.log(message);
      formData.append("input_text", message.text);
      formData.append(
        "voice",
        message.mobileClass === "mobile-right"
          ? TextToSpeechMapping[toLanguage]
          : TextToSpeechMapping[fromLanguage]
      );
      formData.append("speed", "1");

      const response = await fetch(reactServer + "/speech/text_to_speech", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const blob = await response.blob();
      const audioUrl = URL.createObjectURL(blob);
      const audio = new Audio(audioUrl);
      audio.play();

      // Update state
      setCurrentAudio(audio);
      setIsAudioPlaying(true);
      setCurrentAudioMessageId(messageId); // Set the ID

      // Cleanup after audio ends
      audio.addEventListener("ended", () => {
        setCurrentAudio(null);
        setIsAudioPlaying(false);
        setCurrentAudioMessageId(null);
      });
    } catch (error) {
      console.error("Error converting text to speech:", error);
    }
  };

  const handlePauseResume = () => {
    if (currentAudio) {
      if (currentAudio.paused) {
        currentAudio.play();
        setIsAudioPlaying(true);
      } else {
        currentAudio.pause();
        setIsAudioPlaying(false);
      }
    }
  };

  const rightMessagesRef = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const leftMessagesRef = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const allMessagesRef = useRef<{ [key: number]: HTMLDivElement | null }>({});

  const handleEditMessage = async (
    messageId: number,
    newText: string,
    side: string | Message
  ) => {
    let originalMessage, linkedMessageId, translatedText;

    if (side === "right") {
      const updatedRightMessages = rightMessages.map((msg) =>
        msg.id === messageId ? { ...msg, text: newText, isEditing: false } : msg
      );
      setRightMessages(updatedRightMessages);

      originalMessage = rightMessages.find((msg) => msg.id === messageId);
      linkedMessageId = originalMessage?.linkedId;

      if (linkedMessageId !== undefined) {
        translatedText = await translateMessage(newText, toLanguage, fromLanguage);

        const updatedLeftMessages = leftMessages.map((msg) =>
          msg.id === linkedMessageId ? { ...msg, text: translatedText } : msg
        );
        setLeftMessages(updatedLeftMessages);

        const updatedAllMessages = allMessages.map((msg) => {
          if (msg.id === messageId) {
            return { ...msg, text: newText, isEditing: false };
          } else if (msg.id === linkedMessageId) {
            return { ...msg, text: translatedText };
          } else {
            return msg;
          }
        });
        setAllMessages(updatedAllMessages);
      }
    } else if (side === "left") {
      const updatedLeftMessages = leftMessages.map((msg) =>
        msg.id === messageId ? { ...msg, text: newText, isEditing: false } : msg
      );
      setLeftMessages(updatedLeftMessages);

      originalMessage = leftMessages.find((msg) => msg.id === messageId);
      linkedMessageId = originalMessage?.linkedId;

      if (linkedMessageId !== undefined) {
        translatedText = await translateMessage(newText, fromLanguage, toLanguage);

        const updatedRightMessages = rightMessages.map((msg) =>
          msg.id === linkedMessageId ? { ...msg, text: translatedText } : msg
        );
        setRightMessages(updatedRightMessages);

        const updatedAllMessages = allMessages.map((msg) => {
          if (msg.id === messageId) {
            return { ...msg, text: newText, isEditing: false };
          } else if (msg.id === linkedMessageId) {
            return { ...msg, text: translatedText };
          } else {
            return msg;
          }
        });
        setAllMessages(updatedAllMessages);
      }
    } else if (typeof side !== "string") {
      const updatedAllMessages = allMessages.map((msg) =>
        msg.id === messageId ? { ...msg, text: newText, isEditing: false } : msg
      );

      originalMessage = allMessages.find((msg) => msg.id === messageId);
      linkedMessageId = originalMessage?.linkedId;

      if (linkedMessageId !== undefined) {
        // Determine if we are editing the original or the translated message
        const isEditingOriginal = originalMessages.some((msg) => msg.id === messageId);

        // Find the original message in the pair
        const originalInPair =
          originalMessages.find((msg) => msg.id === messageId) ||
          originalMessages.find((msg) => msg.id === linkedMessageId);

        // Determine translation direction based on the original message
        const originalIsLeft = originalInPair?.className === "message-left";

        if (isEditingOriginal) {
          // Editing the original message:
          // original --> translated direction
          translatedText = await translateMessage(
            newText,
            originalIsLeft ? fromLanguage : toLanguage,
            originalIsLeft ? toLanguage : fromLanguage
          );

          // Update the linked translated message with the translated text
          const updatedAllMessagesWithTranslation = updatedAllMessages.map((msg) =>
            msg.id === linkedMessageId ? { ...msg, text: translatedText } : msg
          );
          setAllMessages(updatedAllMessagesWithTranslation);

          // Update left and right arrays accordingly
          if (originalIsLeft) {
            const updatedLeftMessages = leftMessages.map((msg) =>
              msg.id === messageId ? { ...msg, text: newText, isEditing: false } : msg
            );
            setLeftMessages(updatedLeftMessages);

            const updatedRightMessages = rightMessages.map((msg) =>
              msg.id === linkedMessageId ? { ...msg, text: translatedText } : msg
            );
            setRightMessages(updatedRightMessages);
          } else {
            const updatedRightMessages = rightMessages.map((msg) =>
              msg.id === messageId ? { ...msg, text: newText, isEditing: false } : msg
            );
            setRightMessages(updatedRightMessages);

            const updatedLeftMessages = leftMessages.map((msg) =>
              msg.id === linkedMessageId ? { ...msg, text: translatedText } : msg
            );
            setLeftMessages(updatedLeftMessages);
          }
        } else {
          // Editing the translated message:
          // translated --> original direction (we translate back)
          // In this case, newText is the translated text, we must re-derive the original text
          translatedText = await translateMessage(
            newText,
            originalIsLeft ? toLanguage : fromLanguage,
            originalIsLeft ? fromLanguage : toLanguage
          );

          // Now translatedText is actually the original language text
          // Since we edited the translated message, the original message should get translatedText
          // and the currently edited message remains newText (the translated one)
          const updatedAllMessagesWithTranslation = updatedAllMessages.map((msg) => {
            if (msg.id === linkedMessageId) {
              // linkedMessageId is the original message
              return { ...msg, text: translatedText };
            } else {
              return msg;
            }
          });
          setAllMessages(updatedAllMessagesWithTranslation);

          // Update left and right arrays accordingly
          if (originalIsLeft) {
            // If the original message was on the left (fromLanguage),
            // linkedMessageId refers to the original (left), and messageId is the translated (right).
            const updatedLeftMessages = leftMessages.map((msg) =>
              msg.id === linkedMessageId ? { ...msg, text: translatedText } : msg
            );
            setLeftMessages(updatedLeftMessages);

            const updatedRightMessages = rightMessages.map((msg) =>
              msg.id === messageId ? { ...msg, text: newText, isEditing: false } : msg
            );
            setRightMessages(updatedRightMessages);
          } else {
            // If the original message was on the right (toLanguage),
            // linkedMessageId refers to the original (right), and messageId is the translated (left).
            const updatedRightMessages = rightMessages.map((msg) =>
              msg.id === linkedMessageId ? { ...msg, text: translatedText } : msg
            );
            setRightMessages(updatedRightMessages);

            const updatedLeftMessages = leftMessages.map((msg) =>
              msg.id === messageId ? { ...msg, text: newText, isEditing: false } : msg
            );
            setLeftMessages(updatedLeftMessages);
          }
        }
      } else {
        setAllMessages(updatedAllMessages);
      }
    }
  };

  const toggleEditing = (messageId: number, side: string | Message) => {
    if (side === "right") {
      const updatedMessages = rightMessages.map(
        (msg) =>
          msg.id === messageId
            ? { ...msg, isEditing: !msg.isEditing }
            : { ...msg, isEditing: false } // Set isEditing to false for other messages
      );
      setRightMessages(updatedMessages);
    } else if (side === "left") {
      const updatedMessages = leftMessages.map(
        (msg) =>
          msg.id === messageId
            ? { ...msg, isEditing: !msg.isEditing }
            : { ...msg, isEditing: false } // Set isEditing to false for other messages
      );
      setLeftMessages(updatedMessages);
    } else {
      const updatedMessages = allMessages.map(
        (msg) =>
          msg.id === messageId
            ? { ...msg, isEditing: !msg.isEditing }
            : { ...msg, isEditing: false } // Set isEditing to false for other messages
      );
      setAllMessages(updatedMessages);
    }
  };

  const handleClick = (
    messageId: number,
    event: React.MouseEvent,
    side: string | Message
  ) => {
    const isInput = (event.target as HTMLElement).tagName === "TEXTAREA";
    if (!isInput) {
      toggleEditing(messageId, side);
    }
  };

  const handleKeyDown = (
    messageId: number,
    e: React.KeyboardEvent<HTMLTextAreaElement>,
    side: string | Message
  ) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default behavior (like adding a new line)
      if (side === "right") {
        handleEditMessage(
          messageId,
          rightMessages.find((msg) => msg.id === messageId)?.text || "",
          side
        );
      } else if (side === "left") {
        handleEditMessage(
          messageId,
          leftMessages.find((msg) => msg.id === messageId)?.text || "",
          side
        );
      } else {
        handleEditMessage(
          messageId,
          allMessages.find((msg) => msg.id === messageId)?.text || "",
          side
        );
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = async (event: MouseEvent) => {
      if (!(event.target instanceof Node)) {
        return;
      }

      // Check if the click is inside any message editor textarea or delete button
      if (
        (event.target as HTMLElement).closest(
          ".message-editor, .delete-button, .message-editing"
        )
      ) {
        // Click is inside a message editor textarea or delete button
        return;
      }

      // Handle leftMessages
      if (leftMessages.some((msg) => msg.isEditing)) {
        const editingMessage = leftMessages.find((msg) => msg.isEditing);

        if (editingMessage) {
          await handleEditMessage(editingMessage.id, editingMessage.text, "left");
        }

        // Reset editing state for all messages
        setLeftMessages((msgs) => msgs.map((msg) => ({ ...msg, isEditing: false })));
      }

      // Handle rightMessages
      if (rightMessages.some((msg) => msg.isEditing)) {
        const editingMessage = rightMessages.find((msg) => msg.isEditing);

        if (editingMessage) {
          await handleEditMessage(editingMessage.id, editingMessage.text, "right");
        }

        setRightMessages((msgs) =>
          msgs.map((msg) => ({ ...msg, isEditing: false }))
        );
      }

      // Handle allMessages (mobile view)
      if (allMessages.some((msg) => msg.isEditing)) {

        const editingMessage = allMessages.find((msg) => msg.isEditing);

        if (editingMessage) {
          await handleEditMessage(
            editingMessage.id,
            editingMessage.text,
            editingMessage
          );
        }

        setAllMessages((msgs) => msgs.map((msg) => ({ ...msg, isEditing: false })));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [leftMessages, rightMessages, allMessages]);

  // Handle the technical problem submission
  const submitTechnicalProblem = () => {
    if (!profile) {
      console.error('User profile not available.');
      return;
    }

    const formData = new FormData();
    formData.append('name', profile.naam);
    formData.append('email', profile.email);
    formData.append('title', 'Technisch probleem gemeld via Tolkchat');
    formData.append('message', technicalProblemDescription);
    formData.append('message_type', 'Technisch probleem');
    formData.append('details', 'Geen extra details');

    if (technicalProblemFile) {
      formData.append('file', technicalProblemFile, technicalProblemFile.name);
    }

    const token = getJwtToken();

    fetch(reactServer + 'mail/send', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        // Do not set 'Content-Type'; the browser will set it automatically
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Technical problem submitted successfully:', data);
        // Reset the form
        setTechnicalProblemDescription('');
        setTechnicalProblemFile(null);
        // Close the modal
        setShowTechnicalProblemModal(false);
      })
      .catch((error) => {
        console.error('Error submitting technical problem:', error);
        // Optionally, show an error message to the user
      });
  };

  // Add state to control visibility of text forms
  const [showTextForm, setShowTextForm] = useState(false);

  const handleKeyboardClick = () => {
    setShowTextForm((prev) => !prev);
  };

  // Handle message deletion
  const handleDeleteMessage = (messageId: number, side: string | Message) => {
    if (side === "left") {
      setLeftMessages((prevLeftMessages) => {
        const message = prevLeftMessages.find((msg) => msg.id === messageId);
        const linkedMessageId = message?.linkedId;

        setRightMessages((prevRightMessages) =>
          prevRightMessages.filter((msg) => msg.id !== linkedMessageId)
        );
        setOriginalMessages((prevOriginalMessages) =>
          prevOriginalMessages.filter(
            (msg) => msg.id !== messageId && msg.id !== linkedMessageId
          )
        );
        setAllMessages((prevAllMessages) =>
          prevAllMessages.filter(
            (msg) => msg.id !== messageId && msg.id !== linkedMessageId
          )
        );

        return prevLeftMessages.filter((msg) => msg.id !== messageId);
      });
    } else if (side === "right") {
      setRightMessages((prevRightMessages) => {
        const message = prevRightMessages.find((msg) => msg.id === messageId);
        const linkedMessageId = message?.linkedId;

        setLeftMessages((prevLeftMessages) =>
          prevLeftMessages.filter((msg) => msg.id !== linkedMessageId)
        );
        setOriginalMessages((prevOriginalMessages) =>
          prevOriginalMessages.filter(
            (msg) => msg.id !== messageId && msg.id !== linkedMessageId
          )
        );
        setAllMessages((prevAllMessages) =>
          prevAllMessages.filter(
            (msg) => msg.id !== messageId && msg.id !== linkedMessageId
          )
        );

        return prevRightMessages.filter((msg) => msg.id !== messageId);
      });
    } else {
      setAllMessages((prevAllMessages) => {
        const message = prevAllMessages.find((msg) => msg.id === messageId);
        const linkedMessageId = message?.linkedId;

        setLeftMessages((prevLeftMessages) =>
          prevLeftMessages.filter(
            (msg) => msg.id !== messageId && msg.id !== linkedMessageId
          )
        );
        setRightMessages((prevRightMessages) =>
          prevRightMessages.filter(
            (msg) => msg.id !== messageId && msg.id !== linkedMessageId
          )
        );
        setOriginalMessages((prevOriginalMessages) =>
          prevOriginalMessages.filter(
            (msg) => msg.id !== messageId && msg.id !== linkedMessageId
          )
        );

        return prevAllMessages.filter(
          (msg) => msg.id !== messageId && msg.id !== linkedMessageId
        );
      });
    }
  };

  // Function to save the last transcription
  const handleSaveRecentTranscrypt = async () => {
    const token = getJwtToken();
    try {
      const transcriptionData = rightMessages.join(' '); // Combine all right messages into a single string

      const response = await fetch(reactServer + '/conversation/save-transcription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          user_id: profile.id, 
          transcription: transcriptionData,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to save transcription');
      }
      console.log('Transcription saved successfully');
    } catch (error) {
      console.error('Error saving transcription:', error);
    }
  };

  const leftChatRef = useRef<HTMLDivElement | null>(null);
  const rightChatRef = useRef<HTMLDivElement | null>(null);
  const allChatRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (leftChatRef.current) {
      leftChatRef.current.scrollTop = leftChatRef.current.scrollHeight;
    }
  }, [leftMessages]);

  useEffect(() => {
    if (rightChatRef.current) {
      rightChatRef.current.scrollTop = rightChatRef.current.scrollHeight;
    }
  }, [rightMessages]);

  useEffect(() => {
    if (allChatRef.current) {
      allChatRef.current.scrollTop = allChatRef.current.scrollHeight;
    }
  }, [allMessages]);

  // Function to handle page reload with confirmation
  const handleReloadPage = () => {
    window.location.reload();
  };

  const handleCloseSucces = () => {
    setSuccess("");
  };

  const handleCloseNeutral = () => {
    setNeutral("");
  };

  const handleCloseError = () => {
    setError("");
  };

  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="chat-page">
      {success && <Succes message={success} onClose={handleCloseSucces} />}
      {neutral && <Neutral message={neutral} onClose={handleCloseNeutral} />}
      {error && <ErrorNotificaton message={error} onClose={handleCloseError} />}
      {/* Settings */}
      <div
        className="settings-icon"
        onClick={() => setIsSettingsOpen(!isSettingsOpen)}
      >
        <img
          className="settings-icon-img"
          src="/settings-icon.png"
          alt="Settings"
        />
      </div>

      {/* Conditionally render the SettingsMenu */}
      {isSettingsOpen && (
        <SettingsMenu
          autoPlaySetting={isAutoPlayEnabled}
          setAutoPlay={setAutoPlay}
          language={language}
          setLanguage={setLanguage}
          availableLanguages={availableLanguages}
          onClose={() => setIsSettingsOpen(false)}
        />
      )}

      {profile && !eindeGesprek && (
        <div>
          {!isChatStarted ? (
            <div className="chat-setup">
              <img
                src="./TolkChatLogo.png"
                alt="Logo"
                className="tolkchat-logo tolkchat-logo-mobile"
              />
              <h1 className="title">Welkom, {profile.naam}</h1>
              <div>
                <p>Selecteer een Patiënt om mee te chatten:</p>
                <div className="dropdown-container">
                  <select
                    className="selector"
                    value={patient}
                    onChange={(e) => setPatientAndLanguage(e.target.value)}
                  >
                    <option value="">Selecteer Patiënt</option>
                    {allPatients.map((patient) => (
                      <option key={patient} value={patient}>
                        {patient}
                      </option>
                    ))}
                  </select>
                  <button
                    className="start-button patient-button"
                    onClick={() => navigate("../patient-management")}
                  >
                    Patiënt Toevoegen
                  </button>
                </div>
              </div>

              {/* <div className="checkbox-container">
                <label htmlFor="agreement-checkbox">
                  Patiënt gaat akkoord met het gebruik van de Tolkchat
                </label>
                <input  
                  type="checkbox"
                  id="agreement-checkbox"
                  checked={isPatientConsentGiven}
                  onChange={handleCheckboxChange}
                />
              </div> */}

              {/* <div
                className="checkbox-container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ margin: "0.5% 0" }}>
                  <input
                    type="checkbox"
                    id="agreement-checkbox"
                    checked={isPatientConsentGiven}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="agreement-checkbox">
                    Patiënt gaat akkoord met het gebruik van de Tolkchat
                  </label>
                </div>

                <div style={{ margin: "0.5% 0" }}>
                  <input
                    type="checkbox"
                    id="agreement-checkbox"
                    checked={isAIConsentGiven}
                    onChange={handleAICheckboxChange}
                  />
                  <label htmlFor="agreement-checkbox">
                    Deze chat maakt gebruik van AI. Door door te gaan, stemt u
                    in met AI-assistentie tijdens het gesprek
                  </label>
                </div>
              </div> */}

              <button
                className="start-button start"
                onClick={startChat}
              // disabled={!(isPatientConsentGiven && isAIConsentGiven)} // Disable button if checkbox is not checked
              >
                Start TolkChat
              </button>

              <div className="disclaimer">
                <span
                  className="info-icon"
                  onClick={() => setShowTooltip(!showTooltip)}          // Toggle on click (mobile & desktop)
                  onMouseEnter={() => setShowTooltip(true)}            // Show on hover (desktop)
                  onMouseLeave={() => setShowTooltip(false)}           // Hide on mouse out (desktop)
                >
                  i
                  {showTooltip && (
                    <div className="tooltip">
                      Deze chat maakt gebruik van AI-assistentie voor ondersteuning tijdens het gesprek.
                      Door verder te gaan stemmen u en uw patiënt in met het gebruik van AI
                      voor vertaling en communicatie.
                    </div>
                  )}
                </span>
                <span className="disclaimer-text">AI ondersteuning</span>
              </div>


              <div className="tolkchat-space"></div>
            </div>
          ) : (
            <div>
              {/* Patient info and doctor info */}
              <div className="user-info">
                <div className="mobile-tolkchat-header">
                  <img
                    src="./TolkChatLogo.png"
                    alt="Logo"
                    className="tolkchat-header-image"
                  />
                </div>
                <div className="patient-info">
                  <p className="nametext">{patient}</p>
                  <div className="patient-language">
                    <p className="languagetext"></p>
                    <LanguageSelector
                      allLanguages={allLanguages}
                      fromLanguage={fromLanguage}
                      setFromLanguage={setFromLanguage}
                    />
                  </div>
                </div>
                <div className="doctor-info mobile-hidden">
                  <p className="nametext-mobile nametext">{profile.naam}&nbsp;&nbsp;&nbsp;</p>
                  <div>
                    {timerVisibility ? (<p className="languagetext">{formatTime(updatedTimeRef.current)}</p>) :
                      (<p className="languagetext">Nederlands</p>)}
                    <p className="languagetext-mobile nametext">Nederlands</p>
                  </div>
                </div>
              </div>

              {/* Chat container */}
              <div className="chat-container">
                {/* Chat interface */}
                <div className="chat-interface chat-desktop">
                  <div className="chat-screen left-chat" ref={leftChatRef}>
                    {leftMessages.map((message) => (
                      <div
                        key={message.id}
                        className={`${message.className} ${message.isEditing ? 'editing' : ''}`}
                        ref={(el) => (leftMessagesRef.current[message.id] = el)}
                        onClick={(event) => handleClick(message.id, event, "left")}
                      >
                        {message.isEditing ? (
                          <div className="message-editing">
                            <textarea
                              className={`message-editor ${message.className === "message-left"
                                ? "editor-left"
                                : "editor-right"
                                }`}
                              value={message.text}
                              spellCheck={false}
                              onChange={(e) => {
                                const updatedMessages = leftMessages.map((msg) =>
                                  msg.id === message.id ? { ...msg, text: e.target.value } : msg
                                );
                                setLeftMessages(updatedMessages);
                              }}
                              onBlur={() =>
                                handleEditMessage(message.id, message.text, "left")
                              }
                              onKeyDown={(e) => handleKeyDown(message.id, e, "left")}
                              style={{
                                width:
                                  leftMessagesRef.current[message.id]?.offsetWidth - 42,
                                height:
                                  leftMessagesRef.current[message.id]?.offsetHeight - 14,
                                overflow: "hidden",
                                wordWrap: "break-word",
                                resize: "none"
                              }}
                            />
                            {/* Delete Icon */}
                            <img
                              className="delete-icon"
                              src="/bin_black.png"
                              alt="Delete"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleDeleteMessage(message.id, "left");
                              }}
                              onMouseOver={(e) => (e.currentTarget.src = "/bin_red.png")}
                              onMouseOut={(e) => (e.currentTarget.src = "/bin_black.png")}
                            />
                          </div>
                        ) : (
                          <>
                            <span>{message.text}</span>
                            <img
                              className={`speaker ${isClickable ? "" : "disabled"}`}
                              src="/speaker.png"
                              alt="speaker"
                              onClick={(event) => {
                                event.stopPropagation();
                                if (!isClickable) return;
                                setIsClickable(false);
                                handleTextToSpeech(message, message.id);
                                setTimeout(() => setIsClickable(true), 2000);
                              }}
                            />
                          </>
                        )}


                        {currentAudioMessageId === message.id && (
                          <button
                            className="icon-button"
                            onClick={(event) => {
                              event.stopPropagation();
                              handlePauseResume();
                            }}
                          >
                            <img
                              className="icon"
                              src={
                                isAudioPlaying
                                  ? "/icon-pause-512.png"
                                  : "/play-332.png"
                              }
                              alt={isAudioPlaying ? "Pause Icon" : "Play Icon"}
                            />
                          </button>
                        )}
                      </div>
                    ))}
                    {isSendingMessage && (
                      <div className="message-left">
                        <span className="message-placeholder">. . .</span>
                      </div>
                    )}
                  </div>
                  <div className="chat-screen right-chat" ref={rightChatRef}>
                    {rightMessages.map((message) => (
                      <div
                        key={message.id}
                        className={`${message.className} ${message.isEditing ? 'editing' : ''}`}
                        ref={(el) => (rightMessagesRef.current[message.id] = el)}
                        onClick={(event) => handleClick(message.id, event, "right")}
                      >
                        {message.isEditing ? (
                          <div className="message-editing">
                            <textarea
                              className={`message-editor ${message.className === "message-right"
                                ? "editor-right"
                                : "editor-left"
                                }`}
                              value={message.text}
                              spellCheck={false}
                              onChange={(e) => {
                                const updatedMessages = rightMessages.map((msg) =>
                                  msg.id === message.id ? { ...msg, text: e.target.value } : msg
                                );
                                setRightMessages(updatedMessages);
                              }}
                              onBlur={() =>
                                handleEditMessage(message.id, message.text, "right")
                              }
                              onKeyDown={(e) => handleKeyDown(message.id, e, "right")}
                              
                              style={{
                                width:
                                  rightMessagesRef.current[message.id]?.offsetWidth - 41,
                                height:
                                  rightMessagesRef.current[message.id]?.offsetHeight - 14,
                              }}
                            />
                            {/* Delete Icon */}
                            <img
                              className="delete-icon"
                              src="/bin_black.png"
                              alt="Delete"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleDeleteMessage(message.id, "right");
                              }}
                              onMouseOver={(e) => (e.currentTarget.src = "/bin_red.png")}
                              onMouseOut={(e) => (e.currentTarget.src = "/bin_black.png")}
                            />
                          </div>
                        ) : (
                          <>
                            <span>{message.text}</span>
                            <img
                              className={`speaker ${isClickable ? "" : "disabled"}`}
                              src="/speaker.png"
                              alt="speaker"
                              onClick={(event) => {
                                event.stopPropagation();
                                if (!isClickable) return;
                                setIsClickable(false);
                                handleTextToSpeech(message, message.id);
                                setTimeout(() => setIsClickable(true), 2000);
                              }}
                            />
                          </>
                        )}
                        {currentAudioMessageId === message.id && (
                          <button
                            className="icon-button"
                            onClick={(event) => {
                              event.stopPropagation();
                              handlePauseResume();
                            }}
                          >
                            <img
                              className="icon"
                              src={
                                isAudioPlaying
                                  ? "/icon-pause-512.png"
                                  : "/play-332.png"
                              }
                              alt={isAudioPlaying ? "Pause Icon" : "Play Icon"}
                            />
                          </button>
                        )}
                      </div>
                    ))}
                    {isSendingMessage && (
                      <div className="message-right">
                        <span className="message-placeholder">. . .</span>
                      </div>
                    )}
                  </div>
                </div>

                {/* Mobile version */}
                <div className="chat-interface chat-mobile">
                  <div className="chat-screen left-chat" ref={allChatRef}>
                    {allMessages.map((message) => (
                      <div
                        key={message.id}
                        className={`${message.className} ${message.mobileClass} ${message.isEditing ? 'editing' : ''}`}
                        ref={(el) => (allMessagesRef.current[message.id] = el)}
                        onClick={(event) => handleClick(message.id, event, message)}
                      >
                        {message.isEditing ? (
                          <div className="message-editing-mobile">
                            <textarea
                              className="message-editor editor-mobile"
                              value={message.text}
                              spellCheck={false}
                              onChange={(e) => {
                                const updatedMessages = allMessages.map((msg) =>
                                  msg.id === message.id ? { ...msg, text: e.target.value } : msg
                                );
                                setAllMessages(updatedMessages);
                              }}
                              onBlur={() =>
                                handleEditMessage(message.id, message.text, message)
                              }
                              onKeyDown={(e) => handleKeyDown(message.id, e, message)}
                              style={{
                                width:
                                  allMessagesRef.current[message.id]?.offsetWidth - 40,
                                height:
                                  allMessagesRef.current[message.id]?.offsetHeight - 14.5,
                              }}
                            />
                            {/* Delete Icon */}
                            <img
                              className="delete-icon"
                              src="/bin_black.png"
                              alt="Delete"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleDeleteMessage(message.id, message);
                              }}
                              onMouseOver={(e) => (e.currentTarget.src = "/bin_red.png")}
                              onMouseOut={(e) => (e.currentTarget.src = "/bin_black.png")}
                            />
                          </div>
                        ) : (
                          <>
                            <span>{message.text}</span>
                            {Object.keys(TextToSpeechMapping).includes(fromLanguage) ? (
                              <>
                                <img
                                  className={`speaker ${isClickable ? "" : "disabled"
                                    }`}
                                  src="/speaker.png"
                                  alt="speaker"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    if (!isClickable) return;
                                    setIsClickable(false);
                                    handleTextToSpeech(message, message.id);
                                    setTimeout(() => setIsClickable(true), 2000);
                                  }}
                                />
                                {currentAudioMessageId === message.id && (
                                  <button
                                    className="icon-button"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handlePauseResume();
                                    }}
                                  >
                                    <img
                                      className="icon"
                                      src={
                                        isAudioPlaying
                                          ? "/icon-pause-512.png"
                                          : "/play-332.png"
                                      }
                                      alt={isAudioPlaying ? "Pause Icon" : "Play Icon"}
                                    />
                                  </button>
                                )}
                              </>
                            ) : (
                              <span>&nbsp;</span>
                            )}
                          </>
                        )}

                      </div>
                    ))}
                  </div>
                </div>

                {/* Sending buttons */}
                <div className="alignment-buttons mobile-hidden">
                  {transcription}
                  <div className="alignment-buttons-container">
                    <div className="user-input-section">
                      {/* Left (Patient) */}
                      {!showTextForm && (
                        <img
                          className={`microphone ${isRecording === "patient" ? "recording" : ""
                            }`}
                          src={
                            isRecording === "patient"
                              ? "/mic_patient.png"
                              : "/mic_patient.png"
                          }
                          alt="microphone"
                          onClick={() => handleVoiceInput("patient")}
                        />
                      )}
                      {showTextForm && (
                        <div className="text-form">
                          <div className="input-container">
                            <img
                              className={`microphone-text ${isRecording === "patient" ? "recording" : ""
                                }`}
                              src={
                                isRecording === "patient"
                                  ? "/mic_patient.png"
                                  : "/mic_patient.png"
                              }
                              alt="microphone"
                              onClick={() => handleVoiceInput("patient")}
                            />
                            <textarea
                              className="input-message"
                              placeholder={patientInputPlaceholder}
                              value={currentMessageLeft}
                              onChange={(e) => {
                                setCurrentMessage(e.target.value);
                                setCurrentMessageLeft(e.target.value);
                              }}
                            />
                          </div>
                          <div className="buttons-row">
                            <button
                              className="send-button left-button"
                              onClick={() => {
                                sendMessage(fromLanguage);
                                setCurrentMessage("");
                                setCurrentMessageLeft("");
                              }}
                            >
                              {patient}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="keyboard-section">
                      <img
                        className="keyboard-icon"
                        src={showTextForm ? "/keyboard-icon-up.png" : "/keyboard-icon.png"}
                        alt={showTextForm ? "keyboard" : "alternative"}
                        onClick={handleKeyboardClick}
                      />
                    </div>
                    <div className="user-input-section">
                      {/* Right (Doctor) */}
                      {!showTextForm && (
                        <img
                          className={`microphone ${isRecording === "doctor" ? "recording" : ""
                            }`}
                          src={
                            isRecording === "doctor"
                              ? "/mic_doctor.png"
                              : "/mic_doctor.png"
                          }
                          alt="microphone"
                          onClick={() => handleVoiceInput("doctor")}
                        />
                      )}

                      {showTextForm && (
                        <div className="text-form">
                          <div className="input-container">
                            <textarea
                              className="input-message"
                              placeholder="Start het gesprek met uw patiënt hier..."
                              value={currentMessageRight}
                              onChange={(e) => {
                                setCurrentMessage(e.target.value);
                                setCurrentMessageRight(e.target.value);
                              }}
                            />
                            <img
                              className={`microphone-text ${isRecording === "doctor" ? "recording" : ""
                                }`}
                              src={
                                isRecording === "doctor"
                                  ? "/mic_doctor.png"
                                  : "/mic_doctor.png"
                              }
                              alt="microphone"
                              onClick={() => handleVoiceInput("doctor")}
                            />
                          </div>
                          <div className="buttons-row">
                            <button
                              className="send-button right-button"
                              onClick={() => {
                                sendMessage(toLanguage);
                                setCurrentMessage("");
                                setCurrentMessageRight("");
                              }}
                            >
                              {doctor}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Sending buttons mobile */}
                <div className="alignment-buttons desktop-hidden">
                  {transcription}
                  <div className="icon-row">
                    <img
                      className={`microphone ${isRecording === "patient" ? "recording" : ""
                        }`}
                      src="/mic_patient.png"
                      alt="patient microphone"
                      onClick={() => handleVoiceInput("patient")}
                    />
                    <img
                      className="keyboard-icon"
                      src={showTextForm ? "/keyboard-icon-up.png" : "/keyboard-icon.png"}
                      alt={showTextForm ? "keyboard" : "alternative"}
                      onClick={handleKeyboardClick}
                    />
                    <img
                      className={`microphone ${isRecording === "doctor" ? "recording" : ""
                        }`}
                      src="/mic_doctor.png"
                      alt="doctor microphone"
                      onClick={() => handleVoiceInput("doctor")}
                    />
                  </div>
                  {showTextForm && (
                    <div className="text-form-container">
                      <textarea
                        className="input-message"
                        placeholder="Type your message..."
                        value={currentMessage}
                        onChange={(e) => setCurrentMessage(e.target.value)}
                      />

                      <div className="send-buttons">
                        <button
                          className="send-button left-button"
                          onClick={() => {
                            sendMessage(fromLanguage);
                            setCurrentMessage("");
                          }}
                        >
                          {patient}
                        </button>
                        <button
                          className="send-button right-button"
                          onClick={() => {
                            sendMessage(toLanguage);
                            setCurrentMessage("");
                          }}
                        >
                          {doctor}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* <button
                className="samenvatten-button"
                onClick={() => handleConfirmation("Twee schermen")}
              >
                Twee schermen
              </button> */}

              {/* <button className="samenvatten-button" onClick={() => handleConfirmation('Einde gesprek')}>
                Einde gesprek
              </button> */}

              <div>
                <button
                  className="samenvatten-button einde"
                  onClick={handleEindeGesprek}
                >
                  Einde Gesprek
                </button>
              </div>

              <div>
                <button
                  className="samenvatten-button technisch"
                  onClick={handleNieuwGesprek}
                >
                  Start Nieuw Gesprek
                </button>
                <button
                  className="samenvatten-button technisch"
                  onClick={() => handleConfirmation("Technisch probleem")}
                >
                  Technisch Probleem
                </button>
              </div>

              <div className="informed-mistakes">
                Tolkchat kan fouten maken. Controleer belangrijke informatie.
              </div>

              <p>{confirmationMessage}</p>

              {/* Confirmation modal */}
              {showEndConversationModal && (
                <div className="modal-overlay">
                  <div className="modal">
                    <h2>Einde gesprek</h2>
                    <p>Weet u zeker dat u het gesprek wilt beëindigen? U zal worden doorverwezen naar de samenvattingsmodule.</p>
                    <div className="modal-buttons">
                      <button
                        className="cancel-button"
                        onClick={() => setShowEndConversationModal(false)}
                      >
                        Annuleren
                      </button>
                      <button
                        className="send-button"
                        onClick={() => {
                          handleSaveRecentTranscrypt();
                          endSession();
                          setShowEndConversationModal(false);
                          handleConfirmation("Einde gesprek");
                          confirmAction();
                        }}
                      >
                        Beëindigen
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {/* Confirmation modal */}
              {showNewConversationModal && (
                <div className="modal-overlay">
                  <div className="modal">
                    <h2>Start Nieuw Gesprek</h2>
                    <p>Uw huidige gespreksdata zal verloren gaan. Weet u zeker dat u een nieuw gesprek wilt starten?</p>
                    <div className="modal-buttons">
                      <button
                        className="cancel-button"
                        onClick={() => setShowNewConversationModal(false)}
                      >
                        Annuleren
                      </button>
                      <button
                        className="send-button"
                        onClick={() => {
                          endSession();
                          setShowNewConversationModal(false);
                          handleConfirmation("Start Nieuw Gesprek");
                          handleReloadPage();
                        }}
                      >
                        Nieuw Gesprek
                      </button>
                    </div>
                  </div>
                </div>
              )}

            </div>
          )}
        </div>
      )}
      {profile && eindeGesprek && (
        <div>
          <Eindscherm
            conversationId={conversationId.toString()}
            transcript={originalMessages}
            transcript_dutch={rightMessages}
            transcript_foreign={leftMessages}
          />
        </div>
      )}
      {showTechnicalProblemModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Meld uw technisch probleem</h2>
            <p>Geef een korte beschrijving van het probleem</p>
            <textarea
              className="technisch-probleem-textarea"
              value={technicalProblemDescription}
              onChange={(e) => setTechnicalProblemDescription(e.target.value)}
              placeholder="Beschrijving van het probleem"
              rows={5}
              cols={50}
            />
            <div className="file-upload">
              <label htmlFor="file-input" className="file-input-label">
                <img src="/upload.png" alt="Upload" className="upload-icon" />
                Voeg bestand toe (optioneel)
              </label>
              <input
                id="file-input"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    setTechnicalProblemFile(e.target.files[0]);
                  }
                }}
                style={{ display: "none" }}
              />
              {technicalProblemFile && (
                <p>Bestand gekozen: {technicalProblemFile.name}</p>
              )}
            </div>
            <div className="modal-buttons">
              <button
                className="cancel-button"
                onClick={() => setShowTechnicalProblemModal(false)}
              >
                Annuleren
              </button>
              <button className="send-button" onClick={submitTechnicalProblem}>
                Versturen
              </button>
            </div>
            <p>
              Zie ondertussen hier de{" "}
              <a
                href="/#faq"
                style={{ color: "#70c1a1" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ
              </a>{" "}
              en{" "}
              <a
                href="/./bestanden/demopilot-handleiding.pdf"
                download
                style={{ color: "#70c1a1" }}
              >
                handleiding
              </a>
              .
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatPage;
// src/components/ContactFeedbackModal.tsx
import React, { useState } from "react";
import checkingGif from "../images/check.gif";
import { getJwtToken } from "../auth";
import config from "../config";
import "./contact.css";

const reactServer = config.reactServer;

interface ContactFeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ContactFeedbackModal: React.FC<ContactFeedbackModalProps> = ({
  isOpen,
  onClose
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [returnMessage, setReturnMessage] = useState("");

  const handleContactFormulier = async () => {
    if (name && email && message) {
      const requestData = {
        name,
        email,
        title: "Feedbackformulier",
        message,
        message_type: "contact",
        details: [""],
      };

      const token = getJwtToken();

      try {
        const response = await fetch(reactServer + "/mail/send", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestData),
        });

        if (!response.ok) {
          throw new Error("Failed to send mail");
        }

        console.log("Mail sent successfully");
        setReturnMessage(
          "Uw feedbackformulier is verzonden, wij nemen zo snel mogelijk contact met u op."
        );
      } catch (error) {
        console.error("Error sending mail:", error);
        setReturnMessage("Fout bij het verzenden van het contactformulier.");
      }
    } else {
      console.log("Please fill all form fields");
      setReturnMessage("Vul graag alle velden in.");
    }
  };

  // Don't render anything if the modal isn't open
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      {/* Prevent closing by clicking inside the modal */}
      <div
        className="modal custom-feedback-modal"
        onClick={(e) => e.stopPropagation()}
      >
        {returnMessage !==
        "Uw feedbackformulier is verzonden, wij nemen zo snel mogelijk contact met u op." ? (
          <div className="contact-page" style={{ padding: "0" }}>
            <h1 className="title" style={{ marginTop: 0 }}>
              Feedback Formulier
            </h1>
            <p className="contactfeedback-description">
              Om ons platform te verbeteren hebben wij jouw feedback nodig.
              Vul graag het volgende formulier in zodat wij ons kunnen blijven
              ontwikkelen.
            </p>

            <form
              className="contact-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleContactFormulier();
              }}
            >
              <input
                type="text"
                placeholder="Uw Naam"
                name="name"
                className="form-input"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <input
                type="email"
                placeholder="Uw E-mail"
                name="email"
                className="form-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <textarea
                placeholder="Uw Bericht"
                name="message"
                className="form-textarea"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>

              <div className="modal-buttons" style={{ marginTop: "15px" }}>
                <button
                  type="button"
                  className="cancel-button"
                  onClick={onClose}
                >
                  Annuleren
                </button>
                <button type="submit" className="send-button">
                  Verstuur
                </button>
              </div>
            </form>
            <div className="contact-message">{returnMessage}</div>
          </div>
        ) : (
          <div className="contact-page" style={{ padding: "0" }}>
            <div className="contact-message">{returnMessage}</div>
            <img className="check_gif" src={checkingGif} alt="Submitting..." />
            <div className="modal-buttons" style={{ marginTop: "15px" }}>
              <button className="send-button" onClick={onClose}>
                Sluiten
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactFeedbackModal;
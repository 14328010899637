// src/components/Neutral.tsx
import React, { useEffect, useState } from 'react';
import './Neutral.css';

interface NeutralProps {
  message: string;
  onClose: () => void;
}

const Neutral: React.FC<NeutralProps> = ({ message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="Neutral-container">
      <div className="Neutral-content">
        <span>{message}</span>
        <button className="Neutral-close" onClick={onClose}>✖</button>
      </div>
    </div>
  );
};

export default Neutral;

import React, { useState } from "react";
import "./infopages.css";  // your existing styles
import faqs, { FAQ } from "./faqs";

const FAQItem: React.FC<{ faq: FAQ }> = ({ faq }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => setIsOpen(!isOpen);

  return (
    <div className={`reason ${isOpen ? "open" : ""}`} onClick={toggleAnswer}>
      <h2 className="reason-title">{faq.question}</h2>
      {isOpen && (
        <div
          className="reason-description"
          // If answer is HTML, we use dangerouslySetInnerHTML
          dangerouslySetInnerHTML={{ __html: faq.answer }}
        />
      )}
    </div>
  );
};

// Props for the modal's open/close logic
interface FAQModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const FAQModal: React.FC<FAQModalProps> = ({ isOpen, onClose }) => {
  // If modal is not open, don’t render anything
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      {/* Stop clicks within the modal from closing it */}
      <div className="modal faq-modal" onClick={(e) => e.stopPropagation()}>
        {/* A top-right or top-left close button */}
        <button
          className="faq-close-button"
          onClick={onClose}
          style={{ float: "right", margin: "0px", cursor: "pointer" }}
        >
          Sluiten
        </button>
        
        {/* Title */}
        <h1 className="faq-title">
          Help{" "}
          <img
            src="/help.png"
            alt=""
            style={{ height: "1em", verticalAlign: "middle" }}
          />
        </h1>

        {/* FAQ items */}
        {faqs.map((faq: FAQ, index: number) => (
          <FAQItem key={index} faq={faq} />
        ))}
      </div>
    </div>
  );
};

export default FAQModal;
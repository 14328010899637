import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./Registration.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import config from "../config";
import { getJwtToken } from "../auth";

const reactServer = config.reactServer;

const RegistrationForm: React.FC = () => {
  const navigate = useNavigate();
  const token = getJwtToken();

  const [profiel, setProfile] = useState<any>([]);
  const [invoices, setInvoices] = useState<any>([]);

  // const handleVerification = async (email: string, code: string) => {
  //   const data = {
  //     email: email.toLowerCase(),
  //     code: code,
  //   };

  //   try {
  //     const response = await fetch(reactServer + "account/verify", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(data),
  //     });

  //     if (response.ok) {
  //       navigate("/login", { replace: true });
  //     } else {
  //       const responseData = await response.json();
  //       setError(responseData.message);
  //     }
  //   } catch (error) {
  //     setError("Geen verbinding met de server.");
  //   }
  // };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getMonthName = (monthNumber) => monthNames[monthNumber - 1];

  useEffect(() => {
    const token = getJwtToken();

    if (token) {
      fetch(reactServer + "account/profile", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setProfile(data);

          let url = `payments/invoices/all`;
          let org_id: null;
          if (data.org_id) {
            org_id = data.org_id;
            url += `?org_id=${org_id}`;
          }

          fetch(reactServer + url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              return response.json();
            })
            .then((data) => {
              // console.log(data.data);
              setInvoices(data.data);
            })
            .catch((error) => {
              console.error("Error fetching profile:", error);
            });
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
        });
    }
  }, []);

  // HTML Template for Invoice
  // const InvoiceTemplate = ({ invoice }) => (
  //   <div
  //     id="invoice-template"
  //     style={{ padding: "20px", background: "#fff", width: "600px" }}
  //   >
  //     <h1>Invoice #{invoice.invoice_number}</h1>
  //     <p>
  //       <strong>Date:</strong> {invoice.invoice_date}
  //     </p>
  //     <p>
  //       <strong>Customer:</strong> {invoice.user_name}
  //     </p>
  //     <p>
  //       <strong>Company:</strong> {invoice.org_name || "N/A"}
  //     </p>
  //     <p>
  //       <strong>Amount:</strong> ${invoice.price.toFixed(2)}
  //     </p>

  //     <table
  //       border={1}
  //       width="100%"
  //       style={{ marginTop: "10px", borderCollapse: "collapse" }}
  //     >
  //       <thead>
  //         <tr style={{ background: "#f4f4f4" }}>
  //           <th>#</th>
  //           <th>Item</th>
  //           <th>Price</th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {invoice.items.map((item, index) => (
  //           <tr key={index}>
  //             <td>{index + 1}</td>
  //             <td>{item.name}</td>
  //             <td>${item.price.toFixed(2)}</td>
  //           </tr>
  //         ))}
  //       </tbody>
  //     </table>
  //   </div>
  // );

  const generatePDF = async (item: any) => {
    const pdf = new jsPDF();
    const pageWidth = pdf.internal.pageSize.getWidth();
    let y = 20; // Starting position for text

    // 1️⃣ Add Company Logo at Top Right
    const logoURL = "/logo.png"; // Must be inside public folder
    const image = new Image();
    image.src = logoURL;
    await new Promise<void>((resolve) => {
      image.onload = () => {
        pdf.addImage(image, "PNG", pageWidth - 40, 10, 30, 15);
        resolve();
      };
    });

    // 2️⃣ Add Company Title at Top Right
    pdf.setFontSize(18);
    const textWidth = pdf.getTextWidth("Wellcom Technologies");
    pdf.text("Wellcom Technologies", pageWidth - textWidth - 10, y);
    y += 20;

    // 3️⃣ Invoice Title
    pdf.setFontSize(18);
    pdf.text(
      `${profiel.org_id ? "Organisation" : "User"} Invoice Details`,
      10,
      y
    );
    y += 10;

    // 4️⃣ Invoice Information
    pdf.setFontSize(14);
    const formattedDate = new Date(item.invoice_date).toLocaleDateString(
      "en-US",
      {
        year: "numeric",
        month: "long",
        day: "2-digit",
      }
    );

    const name = profiel.org_id ? item.org_name : item.user_name;

    pdf.text(`Invoice Number: ${item.invoice_number}`, 10, y);
    y += 10;
    pdf.text(`Name: ${name}`, 10, y);
    y += 10;
    pdf.text(`Amount: € ${item.price.toFixed(2)}`, 10, y);
    y += 10;
    pdf.text(`Invoice Date: ${formattedDate}`, 10, y);
    y += 10;
    pdf.text(`Month: ${getMonthName(item.month)}`, 10, y);
    y += 10;
    pdf.text(`Year: ${item.year}`, 10, y);
    y += 10;

    // 5️⃣ Convert HTML Invoice Table into Image
    const invoiceElement = document.getElementById("invoice-template"); // The HTML template div
    const canvas = await html2canvas(invoiceElement, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");
    pdf.addImage(imgData, "PNG", 10, y, 190, 0); // Adjust position

    // 6️⃣ Open PDF in a New Tab
    const pdfBlob = pdf.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, "_blank");
  };

  return (
    <div className="registration-container" id="invoice-template">
      <h1 className="page-title">Invoices</h1>
      {profiel && (
        <ul>
          {invoices.map((item: any, index: any) => (
            <li
              key={index}
              onClick={() => generatePDF(item)}
              style={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }}
            >
              {item.invoice_number}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default RegistrationForm;

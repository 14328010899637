export interface FAQ {
  question: string;
  answer: string;
}

const faqs: FAQ[] = [
  {
    question: "Aan de slag met Wellcom",
    answer:
      `<h4>Registratie</h4>
    <ol>
        <li>Ga naar de registratiepagina via de website: <a href="https://wellcom-translation.nl" target=_blank>wellcom-translation.nl</a></li>
        <li>Vul uw naam, e-mailadres en wachtwoord in.</li>
        <li>Klik op "Registreren" en bevestig uw e-mailadres via de ontvangen verificatielink in uw mailbox.</li>
    </ol>
    <h4>Inloggen</h4>
    <ol>
        <li>Bezoek de inlogpagina.</li>
        <li>Vul uw geregistreerd e-mailadres en wachtwoord in.</li>
        <li>Klik op "Inloggen".</li>
    </ol>
    <h4>Lukt het niet?</h4>
    <ol>
        <li>Klik op wachtwoord vergeten om uw wachtwoordgegevens te herstellen.</li>
        <li>Lukt het nog steeds niet? Neem dan contact op met <a href="mailto:support@wellcom-translation.com">support@wellcom-translation.com</a>. We behandelen uw verzoek zo snel mogelijk.</li>
    </ol>`,
  },
  {
    question: "Gebruiksaanwijzing",
    answer:
    `
    <h4>TolkChat starten</h4>
    <ol>
        <li>Selecteer een taal in de taalkeuzemodule of koppel een taal aan een patientID in uw profiel (klik op uw naam rechtsboven).</li>
        <li>Klik op het Wellcom Logo om naar de homepagina te gaan.</li>
        <li>Klik op "Start gesprek" om de real-time vertaling te activeren.</li>
        <li>Klik op de patientkeuzemodule om een specifieke patiënt te selecteren.</li>
    </ol>
    <h4>Real-time vertalingen</h4>
    <ol>
        <li>Klik op de blauwe microfoon.</li>
        <li>Spreek in de microfoon; TolkChat vertaalt uw woorden direct naar de gekozen taal.</li>
        <li>De gesproken tekst wordt live vertaald en weergegeven op het scherm.</li>
        <li>Klik op de groene microfoon.</li>
        <li>Uw gesprekspartner kan in zijn/haar taal antwoorden, die eveneens vertaald wordt.</li>
    </ol>
    <h4>(Automatische) spraak</h4>
    <ol>
        <li>Klik op de volumeknop van een tekstbalk om tekst om te zetten naar spraak.</li>
        <li>Kies of u spraak automatisch wilt laten uitspreken door op het instellingenwiel te klikken:
            <ul>
                <li>Kies voor “Uit” om spraak niet automatisch uit te spreken voor zowel patiënt als zorgverlener.</li>
                <li>Kies voor "Patiënt" om spraak automatisch uit te spreken voor patiënt, maar niet voor zorgverlener.</li>
                <li>Kies voor “Arts” om spraak niet automatisch uit te spreken voor patiënt, maar wel voor de zorgverlener.</li>
                <li>Kies voor “Beide” om spraak automatisch uit te spreken voor zowel patiënt als zorgverlener.</li>
            </ul>
        </li>
    </ol>
    <h4>Samenvattingen maken</h4>
    <ol>
        <li>Kies na het afronden van het gesprek voor: Einde gesprek.</li>
        <li>Klik na een gesprek op "Samenvatting maken".</li>
        <li>Kies het type samenvatting (bijv. SOEP, vrije tekst).</li>
        <li>Controleer met medische deskundigheid de samenvatting in het tekstveld en kopieer deze indien nodig.</li>
    </ol>
    `
  },
  {
    question: "Microfooninstellingen",
    answer:
      `
      Voor optimaal gebruik van TolkChat is het belangrijk dat uw microfoon goed werkt:
      <ol>
        <li>Controleer of uw microfoon is ingeschakeld en correct is aangesloten.</li>
        <li>Geef toestemming aan uw browser om toegang te krijgen tot de microfoon.</li>
        <li>Test de microfoon via de instellingen van uw apparaat of browser.</li>
        <li>Zorg voor een rustige omgeving zonder achtergrondgeluiden voor betere transcriptieresultaten.</li>
      </ol>
      `
  },
  {
    question: "Technische Ondersteuning",
    answer:
    `
    Heeft u technische problemen? Volg de onderstaande stappen:
    <ol>
        <li><b>Controleer uw internetverbinding.</b></li>
        <li><b>Herstart uw browser.</b> Soms lost een herstart problemen op.</li>
        <li><b>Sluit uw microfoon opnieuw aan.</b> Bij problemen met de opname.</li>
        <li><b>Komt u er niet uit?</b> Raadpleeg onze gebruikershandleiding via: <a href="#">Gebruikershandleiding</a>.</li>
        <li><b>Neem contact op met support.</b> Stuur een e-mail naar <a href="mailto:support@wellcom-translation.com">support@wellcom-translation.com</a> met een beschrijving van het probleem en, indien mogelijk, een screenshot.</li>
    </ol>
    `
  },
  {
    question: "Veelgestelde vragen",
    answer:
    `
    <ol>
        <li><b>Kan ik meerdere gesprekken tegelijk voeren?</b><br> Nee, TolkChat ondersteunt op dit moment één gesprek per gebruiker.</li>
        <li><b>Welke talen worden ondersteund?</b><br> TolkChat ondersteunt een breed scala aan talen, waaronder Nederlands, Engels, Arabisch en meer. Voor een volledige lijst, raadpleeg <a href="https://wellcom-translation.nl/" target="_blank">wellcom-translation.nl</a>.</li>
        <li><b>Hoe veilig is TolkChat?</b><br> TolkChat voldoet aan strenge beveiligingsnormen, waaronder AVG-compliance, en maakt gebruik van versleutelde communicatie.</li>
    </ol>
    `
  },
  {
    question: "Taalmodellen",
    answer:
    `
    TolkChat maakt gebruik van geavanceerde taalmodellen die door Wellcom zelf zijn geoptimaliseerd voor medische communicatie. Deze modellen:
    <ol>
        <li><b>Herkennen en vertalen medische termen nauwkeurig.</b> Hoewel een medisch deskundig oog hier nog steeds bij nodig is.</li>
        <li><b>Bieden ondersteuning voor meerdere talen, inclusief dialecten.</b> Sommige talen kunnen betere resultaten leveren dan andere. Wij blijven hier verder aan werken.</li>
        <li><b>Worden regelmatig geüpdatet om te blijven voldoen aan de hoogste kwaliteitsnormen.</b> Wellcom is wendbaar en op de hoogte van nieuwe normen, wij passen onze modellen aan indien dat vereist is.</li>
    </ol>
    `
  }
];

export default faqs;

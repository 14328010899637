// PaymentSuccess.tsx
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getJwtToken } from "../auth";
import config from "../config";

const PaymentSuccess: React.FC = () => {
  const navigate = useNavigate();
  // const token = getJwtToken();
  // const reactServer = config.reactServer;

  // useEffect(() => {
  //   const verifyPayment = async () => {
  //     try {
  //       const response = await fetch(`${reactServer}account/verify-payment`, {
  //         method: "GET",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });

  //       console.log(response);

  //       if (response.ok) {
  //         console.log("YESYYYYY");
  //         // Navigate to the desired route if the payment is verified
  //         // navigate("/profiel");
  //         window.location.href = "/profiel";
  //       } else {
  //         console.error("Payment verification failed");
  //         // Handle non-200 responses as needed
  //       }
  //     } catch (error) {
  //       console.error("Error during payment verification:", error);
  //     }
  //   };

  //   verifyPayment(); // Call the function
  // }, [reactServer, token]);

  // const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate("/"); // Redirect to the homepage
    }, 5000); // 5 seconds timeout

    return () => clearTimeout(timeout); // Cleanup timeout on unmount
  }, [navigate]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "25vh",
        textAlign: "center",
        position: "relative",
      }}
    >
      <div style={{ marginBottom: "20px" }}>
        Betaling succes! Je wordt doorgestuurd naar TolkChat.
      </div>
      <h2>Bedankt voor uw betaling!</h2>

      {/* Loader */}
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          width: "40px",
          height: "40px",
          border: "4px solid #f3f3f3", // Light border
          borderTop: "4px solid #3498db", // Dark border for spinning effect
          borderRadius: "50%",
          animation: "spin 1s linear infinite", // Spin animation
        }}
      />

      {/* Add inline @keyframes using CSS-in-JS */}
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};

export default PaymentSuccess;

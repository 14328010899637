import React, { useState, useEffect } from "react";
import { getJwtToken } from "../auth";
import config from "../config";
import AddUserPopUp from "../components/PopUpModal";
import "./wellcomoverzicht.css";

const reactServer = config.reactServer;

const UserOverview: React.FC = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [subscriptionType, setSubscriptionType] = useState<string>("no");
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState<string>("");

  // Get all the data from the user
  const [profile, setProfile] = useState<any>({
    is_admin: false,
    is_org_admin: false,
  });

  useEffect(() => {
    const token = getJwtToken();

    if (token) {
      fetch(reactServer + "account/profile", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log("Profile set");
          console.log(data);
          setProfile(data);
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
        });
    }
  }, []);

  // Get the data from all the users
  useEffect(() => {
    const token = getJwtToken();

    fetch(reactServer + "account/users", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("deze");
        console.log(data);
        setUsers(data);
      })
      .catch((error) => console.error("Error fetching users:", error));
  }, []);



  return (
    <div className="profile-container">
      {!profile.is_admin && !profile.is_org_admin && (
        <div className="profile-page">
          Pagina alleen beschikbaar voor administratoren.
        </div>
      )}
      {profile.is_admin && (
        <div className="users-container">
          {/* <div className="subscription-form">
            <h2>Abonnement Bijwerken</h2>
            <div className="form-group">
              <label htmlFor="user-select">Selecteer Gebruiker:</label>
              <select
                id="user-select"
                value={selectedUserId || ""}
                onChange={(e) => setSelectedUserId(parseInt(e.target.value))}
              >
                <option value="">Selecteer een gebruiker</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.naam}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="subscription-type">Abonnementstype:</label>
              <select
                id="subscription-type"
                value={subscriptionType}
                onChange={(e) => setSubscriptionType(e.target.value)}
              >
                <option value="no">no</option>
                <option value="lite">lite</option>
                <option value="pro">pro</option>
                <option value="organisation">
                  organisatie (alleen voor testen)
                </option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="subscription-end-date">
                Abonnement Einddatum:
              </label>
              <input
                id="subscription-end-date"
                type="date"
                value={subscriptionEndDate}
                onChange={(e) => setSubscriptionEndDate(e.target.value)}
              />
            </div>
            <button onClick={handleSubscriptionUpdate}>
              Abonnement Bijwerken
            </button>
          </div> */}

          <h2 className="title">Superadminnn Gebruikersoverzicht</h2>
          <div className="table-container">
            <table className="users-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Naam</th>
                  <th>Email</th>
                  <th>SuperAdmin</th>
                  <th>Geregistreerd</th>
                  {/* <th>Gebruik chats</th>
                  <th>Gebruik secondes</th>
                  <th>Abonnement</th>
                  <th>Status</th>
                  <th>Startdatum</th>
                  <th>Einddatum</th> */}
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td data-label="ID">{user.id}</td>
                    <td data-label="Naam">{user.naam}</td>
                    <td data-label="Email">{user.email}</td>
                    <td data-label="SuperAdmin">{user.admin ? 1 : 0}</td>
                    <td data-label="Geregistreerd">
                      {user.registratie ? 1 : 0}
                    </td>
                    {/* <td data-label="Gebruik chats">{user.usage[0]}</td>
                    <td data-label="Gebruik secondes">{user.usage[1]}</td>
                    <td data-label="Abbonement">
                      {user.subscription[0].pricing.name}
                    </td>
                    <td data-label="Status">{user.subscription[0].status}</td>
                    <td data-label="Startdatum">
                      {user.subscription[0].subscription_start_date}
                    </td>
                    <td data-label="Einddatum">
                      {user.subscription[0].subscription_end_date}
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="user-overview">
          </div>
        </div>
      )}
    </div>
  );
};

export default UserOverview;

import React, { useState, useEffect } from "react";
import { getJwtToken } from "../auth";
import config from "../config";
import AddUserPopUp from "../components/PopUpModal";
import "./bedrijfprofieloverzicht.css";

import Succes from "../notifications/Succes";
import Neutral from "../notifications/Neutral";
import ErrorNotificaton from "../notifications/Error";



const reactServer = config.reactServer;

const UserOverview: React.FC = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [neutral, setNeutral] = useState("");
  const [profile, setProfile] = useState<any>({
    organisationName: "",
    maxDeelnemers: 0,
    organisationCode: "",
    is_org_admin: false,
  });
  const [isFormVisible, setIsFormVisible] = useState(false);

  // Fetch profile details
  useEffect(() => {
    const token = getJwtToken();

    if (token) {
      fetch(`${reactServer}account/profile`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setProfile({
            ...data,
            organisationName: data.org_naam || "",
            deelnemers: data.deelnemers || 0,
            maxDeelnemers: data.max_deelnemers || 0,
            organisationCode: data.org_code || "",
            is_org_admin: data.is_org_admin,
          });
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
        });
    }
  }, []);

  // Fetch users in the organisation
  useEffect(() => {
    const token = getJwtToken();

    fetch(`${reactServer}account/users_org`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
         setUsers(data);})
      .catch((error) => console.error("Error fetching users:", error));
  }, []);

  const handleCancel = () => {
    setIsFormVisible(false);
  };

  const handleConfirm = async (data: { userName: string; email: string }): Promise<void> => {
    try {
      const token = getJwtToken();
      const userData = {
        naam: data.userName,
        email: data.email,
        organisation: profile.organisationName,
        org_id: profile.org_id,
      };

      const response = await fetch(`${reactServer}organisation/add-user-to-org`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Unable to connect to the server.");
      }

      console.log("User successfully added to the organization.");
      setSuccess("Gebruiker succesvol toegevoegd aan de organisatie.")
      window.location.reload();
    } catch (error: any) {
      setError((error.message))
    } finally {
      setIsFormVisible(false);
    }
  };

  function formatSecondsToTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
  
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");
  
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const handleDeleteUserFromOrganisation = async (user_id) => {
    const token = getJwtToken(); // Get the JWT token for authentication
  
    const userConfirmation = window.confirm(
      "Weet u zeker dat u deze gebruiker van de organisatie wilt verwijderen?"
    );
  
    if (!userConfirmation) {
      return; // Exit if the user cancels the action
    }
  
    try {
      const response = await fetch(
        `${reactServer}organisation/leave`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user_id, // Send necessary data, such as the user ID
          }),
        }
      );
  
      if (!response.ok) {
        const errorData = await response.json(); // Parse the error response
        const errorMessage = errorData.message || `HTTP error! Status: ${response.status}`;
        throw new Error(errorMessage);
      }
  
      const result = await response.json(); // Parse the success response
  
      setSuccess(`U heeft met succes de gebruiker van de organisatie ${result.organisationName} verlaten.`);
      window.location.reload();
    } catch (error) {
      console.error("Error deleting from organisation:", error);
      setError(error.message || "Er is een fout opgetreden bij het verwijderen van de organisatie.");
    }
  };
  

  const handleCloseSucces = () => {
    setSuccess("");
  };

  const handleCloseNeutral = () => {
    setNeutral("");
  };

  const handleCloseError = () => {
    setError("");
  };


  return (
    <div className="bedrijf-profile-container">
      {success && <Succes message={success} onClose={handleCloseSucces} />}
      {neutral && <Neutral message={neutral} onClose={handleCloseNeutral} />}
      {error && <ErrorNotificaton message={error} onClose={handleCloseError} />}
      {!profile.is_org_admin ? (
        <ErrorNotificaton message="Pagina alleen beschikbaar voor administratoren" onClose={handleCloseError} />
      ) : (
        <div className="bedrijf-overview">
          <h2 className="company-title">{profile.organisationName}</h2>
          <div className="company-details">
            <p>
              <strong>Deelnemers:</strong> {profile.deelnemers + '/' + profile.maxDeelnemers}
            </p>
            <p>
              <strong>Organisatiecode:</strong> {profile.organisationCode}
            </p>
          </div>

          <div className="add-user-container">
            <button
              className="add-user-button"
              onClick={() => setIsFormVisible((prev) => !prev)}
            >
              Gebruiker Toevoegen
            </button>
            {isFormVisible && (
              <AddUserPopUp
                title="Voeg nieuwe gebruiker toe"
                message="Geef de gebruikersgegevens op."
                onCancel={handleCancel}
                onConfirm={handleConfirm}
              />
            )}
          </div>

          <div className="users-table-container">
            <table className="users-table">
              <thead>
                <tr>
                  <th>Naam</th>
                  <th>E-mailadres</th>
                  <th>Deelnemer</th>
                  <th>Chats gebruikt</th>
                  <th>Tijd gebruikt</th>
                  <th>Toegetreden</th>
                  <th>Verlating</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>{user.naam}</td>
                    <td>{user.email}</td>
                    <td>
                      {user.org_status === "JOINED" && "Aangesloten"}
                      {user.org_status === "INVITED" && "Uitgenodigd"}
                      {user.org_status === "LEAVING" && "Vertrekkende"}
                      {user.org_status === "LEFT" && "Verlaten"}
                    </td>
                    <td>{user.usage}</td>
                    <td>{formatSecondsToTime(user.usage_seconds)}</td>
                    <td>{new Date(user.joined_date).toLocaleDateString()}</td>
                    <td>
                      {(user.org_admin !== 1) && (user.org_status === "JOINED") &&
                      <button className="delete-user-button" onClick={() => handleDeleteUserFromOrganisation(user.id)}>Verwijder</button>}
                      {(user.org_admin !== 1) && (user.org_status === "INVITED") &&
                      <button className="delete-user-button" onClick={() => handleDeleteUserFromOrganisation(user.id)}>Cancel</button>}
                      {(user.org_status === "LEAVING") && <p>{new Date(user.org_leave_date).toLocaleDateString()}</p>}
                      {(user.org_status === "LEFT") && <p>Verlaten</p>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserOverview;
